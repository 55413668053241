import React from 'react';
import LogoPC from '../../assets/images/design/logoPC.png';

const Modal = ({
  children,
  isOpen,
  closeModal,
  payment,
  text
}) => {
  const handleModalContainerClick = (e) => e.stopPropagation();


  return (
    <article className={`modal ${isOpen && 'is-open'}`}>
      <div className={`modal-container ${payment && 'payment'}`} onClick={handleModalContainerClick}>
        <div className={`modal-top ${payment && 'payment'}`}>
          <span>{text ? text : ''}</span>
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
        {
          !payment &&
          <div className="modal-img">
            {children}
          </div>
        }
        {
          payment &&
          <div className="modal-text">
            <img src={LogoPC} alt="" />
            {children}
          </div>
        }
      </div>
    </article>
  );
};

export default Modal;