import React from 'react';
import AvatarDefault from '../../assets/images/design/avatar.png';
import mujer from '../../assets/images/design/mujer.jpeg';


const ContentComment = ({img, name, comment, idUser, id, soy}) => {
  return (
    <div className="ContentComment">
      <div className="ContentComment-img">
        <img src={img === null 
          ? soy === 'Hombre'
            ? AvatarDefault
            : mujer 
          : img} alt="Foto de perfil" />
      </div>
      <div className={`ContentComment-box ${idUser === id ? 'active' : ''}`}>
        <p>
          <strong>{name}</strong>
        </p>
        <span>
          {comment}
        </span>
      </div>
    </div>
  )
}

export default ContentComment;