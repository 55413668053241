import React, { useState } from 'react'
import { useEffect } from 'react';

const SearchProject = ({ data, setProject , staticInformation}) => {
  const [dataSearch, setDataSearch] = useState({ name: '' });
  const dataProject = data;

//   useEffect(() => {
//     if(dataSearch.name === ''){
//     }
//     console.log(data)
//   }, [dataSearch.name])

  const handleChange = (e) => {
    setDataSearch({
      ...dataSearch,
      [e.target.name]: e.target.value
    })
  }

  const handleSearch = () => {
    let result;
    result = data?.filter((data) => {
      return `${data.name.toLowerCase()}`.includes(dataSearch.name.toLowerCase())
    })

    console.log(result)
    setProject(result)
    setDataSearch({name:''})
  }

  const handleReset = () => {
     setProject(staticInformation)
  }

  return (
    <div className='search-project'>
      <input
        // className="SearchBar-box"
        placeholder="Buscar proyectos"
        autoComplete="off"
        type="text"
        name='name'
        value={dataSearch.name}
        onChange={handleChange}
        maxLength="50"

      />
      <span onClick={handleReset}>X</span>
      <input
        type="button"
        value="BUSCAR"
        onClick={handleSearch} />
    </div>
  )
}

export default SearchProject
