import React, { useState, useEffect } from 'react'
import { fetchPost } from '../../helpers/helpCore'
import photoUser from '../../assets/images/design/avatar.png';
// import AvatarDefault from '../../assets/images/design/avatar.png';
import '../styles/Mensajes.css'

const Conversation = ({ handleClick }) => {
  const [conversation, setConversation] = useState([])
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    // const res = await axios.post(`${API}/premier/findConversation`, { token })
    fetchPost({token, url:'/premier/findConversation'})
    .then(data => {
      setConversation(data ? data : [])
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {conversation !== null
        ? (
          conversation?.map((data, key) => {
            return (
              <div
                className="user-box__card"
                key={`conversation_${key}`}
                onClick={() => handleClick(data)}>
                <img
                  src={data.img ? data.img : photoUser}
                  alt="Foto de usurario" />
                <p>
                  {data.name ? data.name : 'Sin'}
                  {' '}
                  {data.lastname ? data.lastname : ' Nombre'}</p>
              </div>
            )
          })
        )
        : (<p>Sin datos</p>)
      }
    </div>
  )
}

export default Conversation;