import React, { useEffect, useState, useRef } from 'react'
import { fetchPost } from '../../helpers/helpCore'
import useAuth from '../../hooks/useAuth'
import MensajeContent from '../components/MensajeContent'
import SearchBar from '../components/SearchBar'
import photoUser from '../../assets/images/design/avatar.png';

import '../styles/Mensajes.css'
import Conversations from '../components/Conversations'
import Wink from '../components/Wink'
import MessageResponse from '../components/MessageResponse'
import BoxMessageSend from '../components/BoxMessageSend'

const initalMessage = {
  msg: ''
}

const Mensajes = () => {
  const { logout } = useAuth()
  const [data, setData] = useState(null);
  const [viewChat, setViewChat] = useState(null)
  const [input, setInput] = useState(initalMessage)
  const [currentChat, setCurrentChat] = useState(null)
  const [imgCurrentChat, setImgCurrentChat] = useState(null)
  const [conversation, setConversation] = useState(null)
  const [message, setMessage] = useState(null)
  const [uidMessage, setUidMessage] = useState(null)
  const [result, setResult] = useState('')
  const [messageSocket, setMessageSocket] = useState(null);
  const [viewWink, setViewWink] = useState(false)
  const [viewConversation, setViewConversation] = useState(false)
  // const [ socketId , setSocketId] = useState(false);
  const scrollRef = useRef();
  const rol = localStorage.getItem('rol')
  const uid = localStorage.getItem('uid')
  const token = localStorage.getItem('jwt');

  

  
  //BUSQUEDA DE USUARIOS PARA CHATEAR
  useEffect(() => {
    //BUSCA USUARIOS DISPONIBLES PARA CHATEAR
    // findUser
    const url = '/buscar/usuario'
    fetchPost({ rol, token, url, findSex: null })
      .then(response => {
        if (response === false) {
          //Si en el back e token ha expirado cierra session
          logout()
        } else {
          //MUESTRA TODOS LOS USUARIOS CON 
          //LOS QUE SE PUEDE CHATEAR
          const dataUser = response.filter((item) => item.uid !== uid);
          setData(dataUser ? dataUser : null)
        }
      })
      .catch(err => {
        console.error(err)
      })
    // eslint-disable-next-line
  }, [])
  // eslint-disable-next-line


  //deslizar hacia abajo la seccion de mensajes
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ block: 'start', behavior: "smooth" });
  }, [message, viewChat, currentChat]);

  //VISUALIZACION DE MENSAJES
  useEffect(() => {
    if (viewChat !== null) {
      fetchPost({ token, id: uidMessage, url: '/premier/viewMessage' })
        .then(response => {
          setCurrentChat(response ? response : [])
          setMessageSocket(null)
        })
    }
    // eslint-disable-next-line
  }, [message, viewChat, messageSocket])


  //Seleccion de chat, para visualizar mensajes de la conversacion
  const handleClick = data => {
    setViewChat([])
    setCurrentChat(null)
    const { uid, img } = data;
    setConversation(data);
    //destru. del id con quien se quiere chatear
    setUidMessage(uid)
    setImgCurrentChat(img ? img : null)

    //CHAT ELEGIDO
    setViewChat(data)

    setInput(initalMessage)
    setViewConversation(false)
  }



  // Enviar mensajes
  const handleSubmit = (e) => {
    e.preventDefault()

    if (input.msg !== '') {

      fetchPost({
        token: token,
        sender: uid,
        otherUser: viewChat.uid,
        text: input.msg,
        url: '/premier/sendMessage'
      })
        .then(response => {
          setInput(initalMessage)
          setMessage(response)
          // console.log(response)
          if (response === 'TokenExpiredError') {
            logout()
          } else {
            // console.log(response)
            if (response?.msg) {
              setResult(response?.msg)
            }
          }
        })

    }
  }

  const handleWink = () => {
    setViewWink(!viewWink)
  }

  const handleConversation = () => {
    setViewConversation(!viewConversation)
  }

  return (
    <div className="Mensajes" id="boxMessage">
      <i
        className="viewWink"
        onClick={handleWink}>
        •••
      </i>
      <i
        onClick={handleConversation}
        className="conversaciones_view">
        Conversaciones
      </i>
      <div className="Mensajes-container">
        {/* Conversaciones y barra de busqueda */}
        <div className={`Mensajes-container__User ${viewConversation === true ? 'active' : ''}`}>

          <div className="user-search">
            <SearchBar
              valueSearch={`name`}
              handleClick={handleClick}
              data={data ? data : []}
            />
          </div>
          <div className="user-box Wink-title">
            <h4>Conversaciones iniciadas</h4>
            <Conversations
              handleClick={handleClick}
            />
          </div>
        </div>
        {/* Bandeja de mensajes */}
        <div className={`Mensajes-container__message `}>
          {/* {console.log(currentChat)} */}


          {
            currentChat !== null
              ? (
                <>
                  <div className="mensajes-content__name">
                    <img src={conversation?.img
                      ? conversation?.img
                      : photoUser} alt="Foto de usuario" />
                    <h4>

                      {`${conversation?.name} ${conversation?.lastname}`}
                    </h4>
                  </div>
                  {
                    currentChat?.length === 0
                      ? (<div className="mensajes-noseleted">
                        <h3>Aún no tienes mensajes
                          <br /> Salúdalo!</h3>
                      </div>)
                      : (
                        <div className="mensajes-content">

                          {
                            currentChat?.map((data, key) => {
                              let uidString = uid.toString()
                              return (
                                <div ref={scrollRef} id="message" key={`content_message_${key}`}>
                                  <MensajeContent

                                    img={imgCurrentChat}
                                    msg={data.text}
                                    owner={uidString === data.sender}
                                    createdAt={data.createdAt}
                                  />
                                </div>

                              )
                            })
                          }

                        </div>

                      )
                  }
                  <MessageResponse
                    result={result}
                    setResult={setResult} />
                  <div className="mensajes-send">


                  </div>
                  <BoxMessageSend
                    handleSubmit={handleSubmit}
                    setInput={setInput}
                    input={input}
                    classN={"mensajes-send"}
                  />
                </>
              )
              : (
                <>
                  <MessageResponse
                    result={result}
                    setResult={setResult} />
                  <div className="mensajes-noseleted">

                    <h3>
                      <b>Escoge una persona para comenzar la conversación</b>
                    </h3>
                  </div>
                </>

              )
          }

        </div>
        {/* Guiño */}
        <div className={`Mensajes-container__online ${viewWink === true ? 'active' : ''}`}>
          <Wink result={result} setResult={setResult} />
        </div>
      </div>
    </div>
  )
}

export default Mensajes;