import React, { useEffect, useState } from 'react'
import '../styles/Admi.css'
import { fetchPost } from '../../helpers/helpCore'
import IconLoading from './IconLoading';
import MessageResponse from './MessageResponse';
import useAuth from '../../hooks/useAuth';
import { convertBase64 } from '../../helpers/convertBase64';
import equals from 'esequal'

const initialInput = {
  text1: '',
  text2: '',
  text3: '',
  day1: '',
  day2: '',
  day3: '',
  month1: '',
  month2: '',
  month3: '',
  year1: '',
  year2: '',
  year3: '',
}
const AdminCreateStory = () => {
  const [input, setInput] = useState(initialInput)
  const [image1, setImage1] = useState(false)
  const [image2, setImage2] = useState(false)
  const [image3, setImage3] = useState(false)
  const [viewImage1, setviewImage1] = useState('')
  const [viewImage2, setviewImage2] = useState('')
  const [viewImage3, setviewImage3] = useState('')
  const [imageBase64_1, setImageBase64_1] = useState('')
  const [imageBase64_2, setImageBase64_2] = useState('')
  const [imageBase64_3, setImageBase64_3] = useState('')
  const [status, setStatus] = useState(false)
  const [result, setResult] = useState('');
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    setLoading(true)
    fetchPost({ token, url: '/buscar/admin' })
      .then(() => {
        setLoading(false)
        if (status) {
          setStatus(false)
        }
      })
    // eslint-disable-next-line
  }, [status])

  useEffect(() => {
    const url = '/admin/validateAdmin'
    fetchPost({ token, url })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
        }
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (image1 !== false) {
      try {
        setviewImage1(URL.createObjectURL(image1))
      } catch (err) {
        console.log(err)
        setviewImage1('')
        setImage1(false)
      }
    }
    if (image2 !== false) {
      try {
        setviewImage2(URL.createObjectURL(image2))
      } catch (err) {
        setviewImage2('')
        setImage2(false)
      }
    }
    if (image3 !== false) {
      try {
        setviewImage3(URL.createObjectURL(image3))
      } catch (err) {
        setviewImage3('')
        setImage3(false)
      }
    }
  }, [image1, image2, image3])

  const handleChangeImage1 = (e) => {
    setImage1(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_1)
  }
  const handleChangeImage2 = (e) => {
    setImage2(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_2)

  }
  const handleChangeImage3 = (e) => {
    setImage3(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_3)
  }

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    // eslint-disable-next-line react/jsx-no-duplicate-props
    if (!equals(initialInput, input)) {
      // eslint-disable-next-line react/jsx-no-duplicate-props

      const { text1, text2, text3, day1, day2, day3, month1, month2, month3, year1, year2, year3 } = input;
      let info = [
        {
          image: imageBase64_1,
          text: text1,
          day: day1,
          month: month1,
          year: year1,

        },
        {
          image: imageBase64_2,
          text: text2,
          day: day2,
          month: month2,
          year: year2,
        },
        {
          image: imageBase64_3,
          text: text3,
          day: day3,
          month: month3,
          year: year3,
        },
        // eslint-disable-next-line
      ]

      fetchPost({ url: '/admin/createHelpStory', info, token })
        .then(res => {
          setResult(res?.msg)
          setLoading(false)
          setInput(initialInput)
          image1(false)
          image2(false)
          image3(false)
        })
    } else {
      setLoading(false)
      setResult('Error - Debe llenar todos los campos')
    }
  }


  return (

    <div className="Admin-container">
      <div>
        <h3>
          Crea el slider de historias de éxito
        </h3>
      </div>
      {
        load &&
        <div className="Admin-Profile">
          <div className="Admin-container__ProjectPost">

            <div className="Admin-CreateHistory__container">
              <form onSubmit={handleSubmit}>
                <div className="Admin-CreateHistory__box">
                  <div className="Admin-CreateHistory__image">
                    <div className='Admin-CreateHistory__content'>
                      {
                        image1
                          ? (
                            <div className='Admin-CreateHistory__viewImage'>
                              <img src={viewImage1} alt="Imagen 1" />
                            </div>
                          )
                          : (
                            <p>Añadir imagen</p>
                          )
                      }
                    </div>
                    <input
                      type="file"
                      name="img"
                      accept="image/*"
                      size="5120"

                      onChange={(e) => handleChangeImage1(e)}
                    />
                  </div>
                  <div className="Admin-CreateHistory__text">
                    <label htmlFor="Ingrese el Contenido" className='PostText-title'>
                      <strong><p>Ingrese el contenido</p></strong>
                      <textarea
                        title="max 300 caracteres"
                        type="text"
                        name="text1"
                        maxLength="300"
                        placeholder="Ingrese el Contenido"
                        id='Ingrese el Contenido'
                        value={input.text1}
                        required
                        onChange={handleChange}
                        title='Ingrese todo el contenido correspondiente la historia de ayuda'
                      />
                    </label>
                    <div>
                      <input
                        type="number"
                        name="day1"
                        placeholder='Día'
                        min={1}
                        max={31}
                        size={2}
                        value={input.day1}
                        required
                        onChange={handleChange} />
                      <select
                        value={input.month1}
                        onChange={handleChange}
                        name="month1"
                        required
                        className="form-control form-control-border">
                        <option defaultValue value="">Mes</option>
                        <option value="Enero">Enero</option>
                        <option value="Febrero">Febrero</option>
                        <option value="Marzo">Marzo</option>
                        <option value="Abril">Abril</option>
                        <option value="Mayo">Mayo</option>
                        <option value="Junio">Junio</option>
                        <option value="Julio">Julio</option>
                        <option value="Agosto">Agosto</option>
                        <option value="Septiembre">Septiembre</option>
                        <option value="Octubre">Octubre</option>
                        <option value="Noviembre">Noviembre</option>
                        <option value="Diciembre">Diciembre</option>

                      </select>
                      <input
                        type="number"
                        name="year1"
                        placeholder='Año'
                        min={2020}
                        max={2040}
                        value={input.year1}
                        required
                        onChange={handleChange} />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="Admin-CreateHistory__box">
                  <div className="Admin-CreateHistory__image">
                    <div className='Admin-CreateHistory__content'>
                      {
                        image2 ? (
                          <div className='Admin-CreateHistory__viewImage'>
                            <img src={viewImage2} alt="Imagen 1" />
                          </div>
                        )
                          : (
                            <p>Añadir imagen</p>
                          )
                      }
                    </div>
                    <input
                      type="file"
                      name="img"
                      accept="image/*"
                      size="5120"
                      onChange={(e) => handleChangeImage2(e)}
                    />
                  </div>
                  <div className="Admin-CreateHistory__text">
                    <label htmlFor="Ingrese el Contenido" className='PostText-title'>
                      <strong><p>Ingrese el contenido</p></strong>
                      <textarea
                        title="max 300 caracteres"
                        type="text"
                        name="text2"
                        maxLength="300"
                        placeholder="Ingrese el Contenido"
                        id='Ingrese el Contenido'
                        value={input.text2}
                        required
                        onChange={handleChange}
                        title='Ingrese todo el contenido correspondiente la historia de ayuda'
                      />
                    </label>
                    <div>
                      <input
                        type="number"
                        name="day3"
                        placeholder='Día'
                        min={1}
                        max={31}
                        size={2}
                        value={input.day3}
                        required
                        onChange={handleChange} />
                      <select
                        value={input.month3}
                        onChange={handleChange}
                        name="month3"
                        required
                        className="form-control form-control-border">
                        <option defaultValue value="">Mes</option>
                        <option value="Enero">Enero</option>
                        <option value="Febrero">Febrero</option>
                        <option value="Marzo">Marzo</option>
                        <option value="Abril">Abril</option>
                        <option value="Mayo">Mayo</option>
                        <option value="Junio">Junio</option>
                        <option value="Julio">Julio</option>
                        <option value="Agosto">Agosto</option>
                        <option value="Septiembre">Septiembre</option>
                        <option value="Octubre">Octubre</option>
                        <option value="Noviembre">Noviembre</option>
                        <option value="Diciembre">Diciembre</option>

                      </select>
                      <input
                        type="number"
                        name="year3"
                        placeholder='Año'
                        min={2020}
                        max={2040}
                        value={input.year3}
                        required
                        onChange={handleChange} />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="Admin-CreateHistory__box">
                  <div className="Admin-CreateHistory__image">
                    <div className='Admin-CreateHistory__content'>
                      {
                        image3
                          ? (
                            <div className='Admin-CreateHistory__viewImage'>
                              <img src={viewImage3} alt="Imagen 1" />
                            </div>
                          )
                          : (
                            <p>Añadir imagen</p>
                          )
                      }
                    </div>
                    <input
                      type="file"
                      name="img"
                      accept="image/*"
                      size="5120"
                      onChange={(e) => handleChangeImage3(e)}
                    />
                  </div>
                  <div className="Admin-CreateHistory__text">
                    <label htmlFor="Ingrese el Contenido" className='PostText-title'>
                      <strong><p>Ingrese el contenido</p></strong>
                      <textarea
                        title="max 300 caracteres"
                        type="text"
                        name="text3"
                        maxLength="300"
                        placeholder="Ingrese el Contenido"
                        id='Ingrese el Contenido'
                        value={input.text3}
                        required
                        onChange={handleChange}
                        title='Ingrese todo el contenido correspondiente la historia de ayuda'
                      />
                    </label>


                    <div>
                      <input
                        type="number"
                        name="day2"
                        placeholder='Día'
                        min={1}
                        max={31}
                        size={2}
                        value={input.day2}
                        required
                        onChange={handleChange} />
                      <select
                        value={input.month2}
                        onChange={handleChange}
                        name="month2"
                        required
                        className="form-control form-control-border">
                        <option defaultValue value="">Mes</option>
                        <option value="Enero">Enero</option>
                        <option value="Febrero">Febrero</option>
                        <option value="Marzo">Marzo</option>
                        <option value="Abril">Abril</option>
                        <option value="Mayo">Mayo</option>
                        <option value="Junio">Junio</option>
                        <option value="Julio">Julio</option>
                        <option value="Agosto">Agosto</option>
                        <option value="Septiembre">Septiembre</option>
                        <option value="Octubre">Octubre</option>
                        <option value="Noviembre">Noviembre</option>
                        <option value="Diciembre">Diciembre</option>

                      </select>
                      <input
                        type="number"
                        name="year2"
                        placeholder='Año'
                        min={2020}
                        max={2040}
                        value={input.year2}
                        required
                        onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <MessageResponse result={result} setResult={setResult} />
                {loading && <IconLoading />}

                <input
                  type="submit"
                  value="Subir"
                  className="btn btn-primary2" />
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default AdminCreateStory;