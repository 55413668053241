import React from 'react'

const Monoseccion3 = (props) => {
  return (
    <section className={'Monoseccion-3 position-relative text-white'}>
      {/*eslint-disable-next-line*/}
      <img src={props.img} className="img-fluid" />
      <div className="p-5 item position-absolute-monoseccion3-image ">
        <img
          src={props.img2}
          className="img-fluid d-none d-md-block"
          alt="pgrw"
          loading="lazy"
        />
      </div>
      
    </section>
  );
};
export default Monoseccion3;