import React, { useState } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/SearchBox.css'

const intialSearch = {
  estadoCivil: '',
  edadMin: undefined,
  edadMax: undefined,
  alturaMin: undefined,
  alturaMax: undefined,
  religion: '',
  fumas: '',
  hijos: '',
  peso:'',

}

const SearchBox = ({ data, setDataSearch }) => {
  const filterData = [];

  const [inputs, setInputs] = useState(intialSearch)
  const [searchIcon, setSearchIcon] = useState(false)
  // eslint-disable-next-line
  const [datos, setDatos] = useState(false)

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }
  const validateFilter = () => {
    const conjunt = new Set(filterData);
    const uniqued = [...conjunt];
    setDataSearch(uniqued);
    setDatos(uniqued);
  }
  const handleFilter = () => {
    const { estadoCivil, religion, edadMax, edadMin, alturaMax, alturaMin,
      hijos, fumas, peso } = inputs;

    if (estadoCivil !== '') {
      var result;

      result = data?.filter((data) => {
        return `${data.estadoCivil}`.includes(estadoCivil)
      })

      for (var i in result) {
        filterData.push(result[i])
      }
    }

    if (peso !== '') {
      result = data?.filter((data) => {
        return `${data.peso}`.includes(peso)
      })
      // eslint-disable-next-line
      for (var i in result) {
        filterData.push(result[i])
      }
    }

    if (religion !== '') {
      result = data?.filter((data) => {
        return `${data.religion}`.includes(religion)
      })
      // eslint-disable-next-line
      for (var i in result) {
        filterData.push(result[i])
      }
    }

    if (fumas !== '') {
      result = data?.filter((data) => {
        return `${data.fumas}`.includes(fumas)
      })
      // eslint-disable-next-line
      for (var i in result) {
        filterData.push(result[i])
      }
    }

    if (hijos !== '') {
      result = data?.filter((data) => {
        return `${data.hijos}`.includes(hijos)
      })
      // eslint-disable-next-line
      for (var i in result) {
        filterData.push(result[i])
      }
    }

    if (edadMin !== undefined || edadMax !== undefined) {
      let min, max;
      if (edadMin > edadMax) {
        min = edadMax ? edadMax : 18
        max = edadMin ? edadMin : 80
      } else {
        max = edadMax ? edadMax : 80
        min = edadMin ? edadMin : 18
      }
      result = data?.filter((data) => {
        return data.yearAgo >= min && data.yearAgo <= max
      })
      // eslint-disable-next-line
      for (var i in result) {
        filterData.push(result[i])
      }
    }

    if (alturaMin !== undefined || alturaMax !== undefined) {
      let min, max;
      if (alturaMin > alturaMax) {
        min = alturaMax ? alturaMax : 150
        max = alturaMin ? alturaMin : 220
      } else {
        max = alturaMax ? alturaMax : 220
        min = alturaMin ? alturaMin : 150
      }
      result = data?.filter((data) => {
        return parseInt(data.altura) >= min && parseInt(data.altura) <= max
      })
      // eslint-disable-next-line
      for (var i in result) {
        filterData.push(result[i])
      }
    }

    validateFilter(result)
  }

  const handleReset = () => {
    setDatos(data)
    setDataSearch(data);
    setInputs(intialSearch);

  }

  const handleIcon = () => {
    setSearchIcon(!searchIcon)
    setDatos(false)
  }



  return (
    <div className="SearchBox-container">
      <i className="SearchBox-icons">
        <FontAwesomeIcon
          size="2x"
          className="SearchBox-icons__menu"
          icon={faBars}
          onClick={handleIcon}
        />
      </i>
      <nav className={`SearchBox-container__nav ${searchIcon === true ? 'active' : ''}`}>
        <ul className="search-option__ul">
          <label htmlFor="EstadoCivil">
            {/* Estado civil {' '} */}
            <select
              value={inputs.estadoCivil}
              onChange={handleChange}
              name="estadoCivil"
            >
              <option defaultValue value="">Estado civil</option>
              <option value="Soltero">Soltero</option>
              <option value="Casado">Casado</option>
              <option value="Divorciado">Divorciado</option>
              <option value="Viudo">Viudo</option>
            </select>
          </label>
        </ul>
        <ul className="search-option__ul">
          <label htmlFor="Peso">
            {/* Estado civil {' '} */}
            <select
              value={inputs.peso}
              onChange={handleChange}
              name="peso"
            >
              <option defaultValue value="">Peso</option>
              <option value="35kg - 45kg">35kg - 45kg</option>
              <option value="46kg - 55kg">46kg - 55kg</option>
              <option value="56kg - 65kg">56kg - 65kg</option>
              <option value="66kg - 75kg">66kg - 75kg</option>
              <option value="76kg - 85kg">76kg - 85kg</option>
              <option value="86kg - 95kg">86kg - 95kg</option>
              <option value="96kg - 105kg">96kg - 105kg</option>
              <option value="106kg - 115kg">106kg - 115kg</option>
              <option value="+ 116kg">+ 116kg</option>
            </select>
          </label>
        </ul>
        <ul className="search-option__ul">
          Edad {' '}
          <label htmlFor="edadMin">
            <input
              type="number"
              name="edadMin"
              value={inputs.edadMin}
              id="edadMin"
              min="18"
              max="80"
              placeholder="Min"
              size="2"
              // className="form-control "
              // pattern="[0-9]/[^.,]/"
              // step="1"
              onChange={handleChange} />
          </label>
          {' '}a
          <label htmlFor="">
            <input
              type="number"
              name="edadMax"
              value={inputs.edadMax}
              id="edadMax"
              min="18"
              max="80"
              placeholder="Max"
              size="2"
              onChange={handleChange}
            // className="form-control "
            // onKeyPress={}
            />
          </label>
        </ul>
        <ul className="search-option__ul">
          <label htmlFor="Religion">
            {/* Religión{' '} */}
            <select
              name="religion"
              value={inputs.religion}
              onChange={handleChange}
            // required
            // defaultValue={context.Store.get('user').religion}
            >
              <option defaultValue value="">Religión</option>
              <option value="Catolicismo">Catolicismo</option>
              <option value="Scientology">Scientology</option>
              <option value="Judaísmo">Judaísmo</option>
              <option value="Hinduismo">Hinduismo</option>
              <option value="Mormones">Mormones</option>
              <option value="Protestante">Protestante</option>
              <option value="Budismo">Budismo</option>
              <option value="Iglesia Nativa Polaca">
                Iglesia Nativa Polaca
              </option>
              <option value="Espiritual">Espiritual</option>
              <option value="Ateo">Ateo</option>
              <option value="Hinduismo">Otra</option>
            </select>
          </label>
        </ul>
        <ul className="search-option__ul">Altura{' '}
          <label htmlFor="alturaMin">
            {/* desde {' '} */}
            <input
              type="number"
              name="alturaMin"
              value={inputs.alturaMin}
              id="alturaMin"
              min="18"
              max="80"
              placeholder="150"
              maxLength="3"
              onChange={handleChange}
            />

          </label>
          a
          <label htmlFor="alturaMax">
            {/* hasta{' '} */}
            <input
              type="number"
              name="alturaMax"
              value={inputs.alturaMax}
              id="alturaMax"
              min="150"
              max="200"
              placeholder="220"
              maxLength="3"
              onChange={handleChange}
            />
          </label>
        </ul>
        <ul className="search-option__ul">
          {/* Fumador */}
          <label htmlFor="Fumador">
            {/* Fumador{' '} */}
            <select
              name="fumas"
              id="Fumador"
              value={inputs.fumas}
              onChange={handleChange} 
            >
              <option defaultValue value="">Fumador</option>
              <option value="NO">NO</option>
              <option value="Si, todos los días">Si, todos los días</option>
              <option value="De vez en cuando">De vez en cuando</option>
              <option value="Tratando de dejarlo">Tratando de dejarlo</option>

            </select>
          </label>
        </ul>
        <ul className="search-option__ul">
          {/* Tiene hijos */}
          <label htmlFor="tieneHijos">
            {/* Tiene hijos{' '} */}
            <select
              name="hijos"
              id="tieneHijos"
              value={inputs.hijos}
              onChange={handleChange}
            // required
            // defaultValue={context.Store.get('user').religion}
            >
              <option defaultValue value="">Tiene hijos</option>
              <option value="SI">Si</option>
              <option value="NO">No</option>

            </select>
          </label>
        </ul>
        <ul className="search-option__ul">
          <input
            type="button"
            value="Buscar"
            onClick={() => handleFilter()}
            className="btn btn-primary2" />
          <input
            type="button"
            className="btn btn-primary2"
            value="Borrar"
            onClick={() => handleReset()} />
        </ul>
      </nav>
      {/* <div className="SearchBox-container__option">
        {
          datos !== false
            ? (datos?.map((data, key) => {
              return (
                <Link to={{
                  pathname: `/web/perfil/${data.uid}`,
                  state: { data },
                }}
                  key={`${key}_option`}>
                  <div
                    className="SearchBox-container__optionNormal"

                  >
                    <p>{data.name}{' '} {data.lastname}</p>
                  </div>
                </Link>
              )
            }))
            : (false)
        }

      </div> */}
    </div>
  )
}

export default SearchBox