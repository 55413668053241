import React from 'react'

import photoUser from '../../assets/images/design/avatar.png';
import { format } from 'timeago.js'

const MensajeContent = ({ owner, msg, img, createdAt }) => {
  return (
    <>
    <div className=
      {owner
        ? 'MensajeContent Owner'
        : 'MensajeContent '}>
      <div className="MensajeContent-img">
        <img src={img ? img : photoUser} alt="" />
      </div>
      <span
        className={
          owner
            ? 'MensajeContent-messageOwner'
            : 'MensajeContent-message'}
      >
        {msg}
      </span>
      
    </div>
    <p className={
        owner
          ? ('TimeAgo owner')
          : ('TimeAgo')
      }>{format(createdAt)}</p>
    </>
  )
}

export default MensajeContent;