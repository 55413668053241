import React, { useState } from 'react';
import { fetchPost } from '../../helpers/helpCore';
import IconLoading from './IconLoading';
import MessageResponse from './MessageResponse';

const initialText = {
  text: '',
}

const CreateComment = ({idPost, setPostComment, commentValue, setCommentValue}) => {
  const [inputs, setInputs] = useState(initialText)
  const token = localStorage.getItem('jwt');
  const [result, setResult] = useState('');
  const [ loading, setLoading] = useState(false)


  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = '/post/createComment'
    if(inputs?.text.length !== 0){
      setLoading(true)
      fetchPost({
        url,
        comment:inputs?.text,
        idPost,
        token
      })
        .then(res => {
          setResult(res?.msg)
          setPostComment(res?.msg); 
          setInputs(initialText);
          setCommentValue(commentValue + res?.value)
          setLoading(false)

        })
    } else {
      setLoading(false)
      let res = 'El comentario no tiene contenido'
      // resultPost(res)
      setResult(res)


    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit} >
        <textarea
          type="text"
          name="text"
          maxLength="500"
          placeholder="Añade un comentario"
          value={inputs.text}
          onChange={(e) => handleChange(e)}
        />
        <input 
          type="submit" 
          value="Comentar"
          className="btn btn-primary2" />
      </form>
      <p>
        <MessageResponse 
          result={result} 
          setResult={setResult}/>
        {loading && <IconLoading/>}
      </p>
    </div>
  )
}

export default CreateComment;
