import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import '../styles/Modals.css'

import parejas1 from '../../assets/images/design/parejas1.jpg'
import parejas2 from '../../assets/images/design/parejas2.jpg'
import parejas3 from '../../assets/images/design/parejas3.jpg'

const useModalPremiere = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line
      setShow(!show)
      // eslint-disable-next-line
    }, 3000);
    // eslint-disable-next-line
  }, [])


  return (
    <>
      {/* <button variant="primary" onClick={() => setShow(true)}>
        Custom Width Modal
      </button> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w "
        centered={true}
      >
        <Modal.Body closeButton>
          <div className="modalBody">
            <div className="modalBody-images">
              <img src={parejas1} alt="Images the modal" loading="lazy" />
              <img src={parejas2} alt="Images the modal" loading="lazy" />
              <img src={parejas3} alt="Images the modal" loading="lazy" />
            </div>
            <div className="modalBody-content">
              <h4>
                ¡HAZTE PREMIERE!
              </h4>
              <h6>
                Y TEN MÁS POSIBILIDADES DE ÉXITO EN TU RELACIÓN FUTURA.
              </h6>
              <h6>
                ADQUIERE EL PLAN PREMIERE Y CONOCE LOS PROYECTOS, <br />
                SUEÑOS Y PROPÓSITOS DE TUS PROSPECTOS,
              </h6>
              <h6>
                <strong>¡UNA PAREJA QUE SE APOYA ES IDEAL!</strong>
              </h6>
              <br />

              <p>Conoce nuevas personas con tus mismos gustos e intereses </p>
              <p>Haz amistades y participa en proyectos interesantes con ellos</p>
            </div>
          </div>
          <div className="modalFooter">
            <h7>
              ¡ADQUIÉRELO HOY Y RECIBE UN 50% DE DESCUENTO!
            </h7>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default useModalPremiere;