import React, { useEffect, useState } from 'react';
import '../styles/Admi.css'
import { fetchPost } from '../../helpers/helpCore'
import IconLoading from '../components/IconLoading';
import MessageResponse from '../components/MessageResponse';
import useAuth from '../../hooks/useAuth';
import { convertBase64 } from '../../helpers/convertBase64';

const AdminEditSlider = () => {
  const [imageBase64_1, setImageBase64_1] = useState('')
  const [imageBase64_2, setImageBase64_2] = useState('')
  const [imageBase64_3, setImageBase64_3] = useState('')
  const [image1, setImage1] = useState(false);
  const [image2, setImage2] = useState(false);
  const [image3, setImage3] = useState(false);
  const [viewImage1, setviewImage1] = useState('')
  const [viewImage2, setviewImage2] = useState('')
  const [viewImage3, setviewImage3] = useState('')
  const [load, setLoad] = useState(false)
  const { logout } = useAuth()
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    const url = '/admin/validateAdmin'
    fetchPost({ token, url })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
        }
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (image1 !== false) {
      try {
        setviewImage1(URL.createObjectURL(image1))
      } catch (err) {
        console.log(err)
        setviewImage1('')
        setImage1(false)
      }
    }
    if (image2 !== false) {
      try {
        setviewImage2(URL.createObjectURL(image2))
      } catch (err) {
        setviewImage2('')
        setImage2(false)
      }
    }
    if (image3 !== false) {
      try {
        setviewImage3(URL.createObjectURL(image3))
      } catch (err) {
        setviewImage3('')
        setImage3(false)
      }
    }
  }, [image1, image2, image3])

  const handleChangeImage1 = (e) => {
    setImage1(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_1)
  }
  const handleChangeImage2 = (e) => {
    setImage2(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_2)

  }
  const handleChangeImage3 = (e) => {
    setImage3(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_3)
  }



  const handleSubmit = (e) => {
    e.preventDefault();

    if (image1 !== false || image2 !== false || image3 !== false) {

      setLoading(true)
      fetchPost({
        token,
        url: '/admin/editSlider',
        project: imageBase64_2,
        help: imageBase64_3,
        principal: imageBase64_1,
      })
        .then(res => {
          setLoading(false);
          setImageBase64_1('')
          setImageBase64_2('')
          setImageBase64_3('')
          setImage1(false)
          setImage2(false)
          setImage3(false)
          setviewImage1('')
          setviewImage2('')
          setviewImage3('')
          setResult(res?.msg);

        })
    } else {
      setResult('Error - debe tener al menos una imagen')
    }
  }

  return (
    <div className="Admin-container">
      <div>
        <h3>Modificar Slider principal</h3>
      </div>

      {
        load &&
        <div className='Admin-container__Project'>
          <div className="Admin-container__ProjectPost">
            <div className="Admin-ProjectPost__box">
              {/* <h4>Añade 3 imágenes para el proyecto (*)</h4> */}


              <div className='Admin-container__PostText'>
                <form onSubmit={handleSubmit}>
                  <div className="multi-image__InputSlider">
                    <div className="multi-image__boxSlider">
                      <h4>SLIDER PRINCIPAL</h4>
                    {/* Seccion para añadir imagenes*/}

                      <div className='multi-image__InputFileSlider'>


                        <div className='multi-image__InputText'>
                          {
                            image1
                              ? (
                                <div className='InputFile-image__addSlider'>
                                  <img src={viewImage1} alt="Imagen 1" />
                                </div>
                              )
                              : (
                                <p>Añadir imagen</p>
                              )
                          }
                        </div>
                        <input
                          type="file"
                          name="img"
                          accept="image/*"
                          size="5120"
                          onChange={(e) => handleChangeImage1(e)} />
                      </div>
                    </div>

                    <div className="multi-image__boxSlider">
                      {/* Seccion para añadir imagenes*/}

                      <h4>SLIDER PROYECTOS</h4>

                      <div className='multi-image__InputFileSlider'>
                        <div className='multi-image__InputText'>
                          {
                            image2
                              ? (
                                <div className='InputFile-image__addSlider'>
                                  <img src={viewImage2} alt="Imagen 1" />
                                </div>
                              )
                              : (
                                <p>Añadir imagen</p>
                              )
                          }
                        </div>
                        <input
                          type="file"
                          name="img"
                          accept="image/*"
                          size="5120"
                          onChange={(e) => handleChangeImage2(e)} />
                      </div>
                    </div>

                    <div className="multi-image__boxSlider">
                      {/* Seccion para añadir imagenes*/}
                      <h4>SLIDER AYUDAS</h4>

                      <div className='multi-image__InputFileSlider'>
                        <div className='multi-image__InputText'>
                          {
                            image3
                              ? (
                                <div className='InputFile-image__addSlider'>
                                  <img src={viewImage3} alt="Imagen 1" />
                                </div>
                              )
                              : (
                                <p>Añadir imagen</p>
                              )
                          }
                        </div>
                        <input
                          type="file"
                          name="img"
                          accept="image/*"
                          size="5120"
                          onChange={(e) => handleChangeImage3(e)} />
                      </div>
                    </div>

                  </div>
                  {/* <strong><p>Los que tengan (*) son obligatorios</p></strong> */}
                  <input
                    type="submit"
                    value="Cambiar imagenes del slider"
                    className="btn btn-primary2" />
                </form>
                <MessageResponse result={result} setResult={setResult} />
                {loading && <IconLoading />}

              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}

export default AdminEditSlider
