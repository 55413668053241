import React, { useState, useEffect } from 'react'

import { fetchPost } from '../../helpers/helpCore';
import useAuth from '../../hooks/useAuth';
import SearchBox from '../components/SearchBox';
import IconLoading from '../components/IconLoading';
import SearchBoxPremiere from '../components/SearchBoxPremiere';
import ReactPaginate from 'react-paginate';
import ElementSearch from '../components/ElementSearch';
import { Redirect } from 'react-router-dom';

import '../styles/WebBuscar.css'

const WebBuscar = () => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [firstForm, setFirstForm] = useState(undefined)
  const [dataSearch, setDataSearch] = useState(null)
  const [findSex, setFindSex] = useState(null);
  const [loading, setLoading] = useState(false)
  const { logout } = useAuth();
  const [data, setData] = useState(null);
  //Elementos visibles en la pagina
  const uid = localStorage.getItem('uid')

  const itemsPerPage = 8;

  useEffect(() => {
    setLoading(true)
    const url = '/buscar/usuario'
    let token = localStorage.getItem('jwt')

    fetchPost({ token, url, findSex }) 
      .then(response => {
        if (response === false) {
          setLoading(false);
          logout();
        } else {
          setLoading(false);
          setData(response ? response : null);
        }
      })
      .catch(err => {
        alert('Hubo un error')
      })
      // eslint-disable-next-line
  }, [findSex]) 


  useEffect(() => {
    fetchPost({ uid, url: '/buscar/validateFirstForm' })
      .then(res => {
        if (res === false) {
          alert('Llene los formularios para entrar en esta sección')
        }
        setFirstForm(res)
      })
      .catch(err => {
        console.log(err)
      })
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setDataSearch(dataSearch !== null ? dataSearch : data)
    // eslint-disable-next-line
  }, [dataSearch])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };


  return (
    <div className={'main'}>
      {firstForm === false ? <Redirect to="/web/profile" /> : (false)}
      <SearchBox
        data={data ? data : []}
        setDataSearch={setDataSearch} />
      <SearchBoxPremiere />
      <div className="container mt-5">
        <div className="row mb-3 text-left header-find">
          <div className="header-find__sex">
            <h4>¿Qué deseas buscar?</h4>
            <div className="header-find__sexButton">
              <input 
                type='button' 
                value="Hombre"
                className={findSex === 'Hombre' ? 'btn-primary2' : ''}
                disabled={loading === true ? true : false} 
                onClick={() => setFindSex('Hombre')}/>
              <input 
                type='button' 
                value="Mujer" 
                className={findSex === 'Mujer' ? 'btn-primary2' : ''}
                disabled={loading === true ? true : false} 
                onClick={() => setFindSex('Mujer')}/>
              <input 
                type='button' 
                value="Ambos" 
                className={findSex === null ? 'btn-primary2' : ''}
                disabled={loading === true ? true : false} 
                onClick={() => setFindSex(null)}/>

            </div>
          </div>
          
        </div>
      </div>
      <div className="container">
        <div className="row img-buscar">
          {loading && <IconLoading />}
          {
            data !== null
              ? (
                <>

                  <ElementSearch
                    data={dataSearch !== null ? dataSearch : data}
                    currentItems={currentItems}
                    setCurrentItems={setCurrentItems}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    itemOffset={itemOffset}
                    setItemOffset={setItemOffset}
                    itemsPerPage={itemsPerPage}
                    handlePageClick={handlePageClick}
                  />
                </>
              )
              : (false)
          }



        </div>
        <div className="ReactPaginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Siguiente >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< Anterior"
            renderOnZeroPageCount={null}
          />
        </div>


      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6 mt-3 ">
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebBuscar;