import React from 'react'
import { Link } from 'react-router-dom';

import Banner from '../../assets/images/design/banner-superior-perfil-usuario.jpg';
import AvatarDefault from '../../assets/images/design/avatar.png';
import mujer from '../../assets/images/design/mujer.jpeg';
import { singleFileUpload, coverFileUpload } from '../../helpers/helpCore'
import { faUpload, faImage } from '@fortawesome/free-solid-svg-icons';
import {
  faCompactDisc,
  faBookOpen,
  faHandsHelping,
  faFutbol,
  faMicrophone,
  faMusic,
  faCocktail
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import recortName from '../../helpers/recortName';
import '../styles/WebMiPerfil.css'
import { useState, useEffect } from 'react';
import { fetchPost } from '../../helpers/helpCore'
import ButtonPremiere from '../components/ButtonPremiere';
import useAuth from '../../hooks/useAuth';
import Galery from '../components/Galery';
import MessageResponse from '../components/MessageResponse';



const ProfileUser = () => {
  const id = localStorage.getItem('uid')
  // const token = localStorage.getItem('jwt')
  const [change , setChange] = useState(0);
  const [data, setData] = useState({})
  const [image, setImage] = useState(false);
  const [images, setImages] = useState(false);
  const [result, setResult] = useState('');
  const [messageBadge, setMessageBadge] = useState(false)

  const [viewImage, setviewImage] = useState('')
  const { logout } = useAuth()

  useEffect(() => {
    setData({})
    if (images !== false) {
      try {
        setviewImage(URL.createObjectURL(images))
      } catch (err) {
        setImages(false);
        setviewImage('');
      }
    }
  }, [images])


  useEffect(() => {
    setData({})

    fetchPost({ idUrl: id, url: '/user/info', uid: id })
      .then(response => {
        // console.log(response)
        if (response === 'JsonWebTokenError') {
          logout()

        } else {
          if (response === false) {
            setData({})
            alert('¡Completa todos los formularios! y conoce más personas')
          } else {
            try {
              setData(response)

            } catch (err) {
              console.log(err)

            }
          }
        }

      })
    //userNormal(uid ? uid : localStorage.getItem('uid'))
    // eslint-disable-next-line 
  }, [change])

  //deteccion de cambios de la imagen de perfil
  const handleChange = (e) => {
    setImage(e.target.files[0])
    // console.log(e.target.files[0])
  }

  //deteccion de cambios de la imagen de portada
  const handleChangeCover = (e) => {
    setImages(e.target.files[0])
  }

  const uploadSingleFile = async () => {
    const formData = new FormData();
    formData.append('archivo', image)
    const res = await singleFileUpload(formData, id);
    setImage(false)
    setResult(res)
    setChange( change + 1);
  }

  const uploadCoverFile = async () => {
    const formData = new FormData();
    formData.append('archivo', images)
    const res = await coverFileUpload(formData, id);
    setImages({})
    setResult(res);
    setChange(change + 1)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    uploadSingleFile();
    // setRedirect(!redirect)
  }


  const handleSubmitCover = (e) => {
    e.preventDefault();

    uploadCoverFile();
  }
  const handleClickBadge = () => {
    setMessageBadge(!messageBadge)
  }

  return (
    <div className={'main'}>
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">{/* Bienvenido */}</h3>
          <div className="text-center">
          </div>
        </div>
      </section>
      <div className="container-fluid bg-white">
        <div className="row portada-perfil__box">
          <div className="col p-0 portada-perfil__usuario">
            <img
            loading="lazy"
              src={data?.cover ? data?.cover : Banner}
              alt="programandoweb"
              className="img-fluid" />
          </div>
          <div className="changePortada" >
            <span>
              <strong>Cambiar fondo</strong>
              <i title="Trata que la imagen sea 1200px de ancho mínimo">i</i>
            </span>
            <div className="changePortada__image">
              {
                images !== false
                  ? (
                    <div>
                      <img src={viewImage} alt="Foto por subir" loading="lazy" />
                      <span>{recortName(images?.name, 15)}</span>
                    </div>
                  )
                  : (
                    <div>
                      <FontAwesomeIcon size="5x" icon={faImage} />
                    </div>
                  )
              }
              <input
                type="file"
                name="img"
                accept="image/*"
                size="1"
                onChange={(e) => handleChangeCover(e)} />
            </div>
            <form onSubmit={handleSubmitCover}>
              {
                images !== false
                  ? (
                    <div>
                      <input
                        type="submit"
                        value="Cambiar Portada"
                        className="btn btn-primary2" />
                    </div>
                  )
                  : (false)
              }
            </form>

          </div>
        </div>
      </div>
      <div className="container-fluid bg-white pb-5">
        <div className="container bg-white">
          <div className="row">
            <div className="col-12 col-sm-6 p-0 border-primary-left">
              <div className="container-dataHeader">
                <div className="container-dataHeader__photo">
                  <div className="dataHeader__photo">
                    <img
                      src={data?.img
                        ? data?.img
                        : data?.soy === 'Hombre'
                          ? AvatarDefault
                          : mujer}
                      alt="programandoweb"
                      className="float-left border-radius"
                      loading="lazy"
                    />
                  </div>

                </div>
                <div className="container-dataHeader__fullname">
                  <b>
                    {' '}
                    {data?.name != null
                      ? data?.name
                      : 'Nombres sin definir'}{' '}
                    {data?.lastname != null
                      ? data?.lastname
                      : 'Apellidos sin definir'}
                  </b>
                </div>
                <div className="container-dataHeader__file">
                  <div className="container-dataHeader__fileUpload" title="Recomendación: Usa imágenes cuadradas para la foto de perfil">
                    <form onSubmit={handleSubmit}>

                      <FontAwesomeIcon size="1x" icon={faUpload} />
                      <div>Arrastra Aquí</div>
                      <input
                        type="file"
                        name="userfile"
                        accept="image/*"
                        size="1030"
                        onChange={(e) => handleChange(e)}
                        className=" fileUpload"
                      />
                      {image === false
                        ? (false)
                        : (
                          <div>
                            <input
                              type="submit"
                              className="btn btn-primary2 button-change__photo"
                              value="Cambiar foto"
                            />
                          </div>
                        )}
                    </form>

                  </div>
                </div>
              </div>

              <hr></hr>
              <div className="pl-5 pt-2">
                <MessageResponse result={result} setResult={setResult} />
                {
                  data?.premiere &&
                  <div className="row">
                    <div className="col">
                     
                    <h5>Actualmente tu perfil es <b>PREMIERE</b></h5>
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col">
                    Nacionalidad :{' '}
                    {data?.nacionalidad != null
                      ? data?.nacionalidad
                      : 'Nacionalidad sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Estado Civil :{' '}
                    {data?.estadoCivil != null
                      ? data?.estadoCivil
                      : 'Estado Civil sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Complexion :{' '}
                    {data.complexion != null
                      ? data.complexion
                      : 'Complexion sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Peso : {data?.peso != null ? data?.peso : 'Peso sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Cabello :{' '}
                    {data?.colorCabello != null
                      ? data?.colorCabello
                      : 'Cabello sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Ojos :{' '}
                    {data?.colorOjos != null
                      ? data?.colorOjos
                      : 'Ojos sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Hijos :{' '}
                    {data?.hijos != null ? data?.hijos : 'Hijos sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Religión :{' '}
                    {data?.religion != null
                      ? data?.religion
                      : 'Religión sin definir'}
                  </div>
                </div>
                {
                  data?.email &&
                  <div className="row">
                    <div className="col">
                     
                    Tú correo: <b>{data?.email}</b>
                    </div>
                  </div>
                }
               
                {localStorage.getItem('email') ? (
                  <>
                    <div className="col text-right">
                      <Link
                        className="btn btn-primary2 text-uppercase"
                        to="/web/profile">
                        Editar perfil
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 p-0">
                {/* <div className="row pt-5">
                    <div className="col h5">
                      <b>Ver Galería</b>
                    </div>
                  </div> */}
                {data?.badge &&
                  <div className='text-center container-dataHeader__badge'>
                    <h3>
                      Insignia {data?.category}
                      <i
                        title='Hazme click'
                        onClick={handleClickBadge}
                      >i
                      </i>
                    </h3>
                    <div className={`badge-text ${messageBadge ? 'active' : ''}`}>
                      {data?.badgeTitle}
                    </div>
                    <div className='badge-donate'>
                      <img
                        src={data?.badge}
                        alt={`insignia por donar`}
                        loading="lazy"
                      />
                    </div>
                  </div>
                }
                <div>
                  <Galery data={data?.galery} />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-0 pt-2">
              <div className="card">
                <ButtonPremiere />
                <div className="card-header bg-primary-pgrw text-white">
                  Más acerca de mi
                </div>
                <div className="card-body card-profile">
                  <div className="row">
                    <div className="col-12 col-sm-2 text-center">
                      <FontAwesomeIcon size="2x" icon={faCocktail} />
                    </div>
                    <div className="col">
                      <div><strong>Bebo alcohol</strong> {data?.bebes_alcohol ? data?.bebes_alcohol : 'sin definir'}</div>
                      <div><strong>Fumo</strong> {data?.fumas ? data?.fumas : 'sin definir'}</div>
                    </div>
                  </div>
                  {data !== undefined &&
                    data?.tiempo_libre !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faBookOpen} />
                        </div>
                        <div className="col">
                          <div><strong>En mi tiempo libre me gusta:</strong></div>
                          <div>
                            {data.tiempo_libre.map((row, key) => {
                              return (
                                <span key={key}>
                                  {row}
                                  {key <
                                    data?.tiempo_libre.length - 1
                                    ? ', '
                                    : false}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data !== undefined &&
                    data.importanteRelacion !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faHandsHelping} />
                        </div>
                        <div className="col">
                          <div><b>Es importante para mi:</b></div>
                          <div>
                            {data.importanteRelacion.map((row, key) => {
                              return (
                                <p key={key}>
                                  {row}
                                  {key < data.importanteRelacion.length - 1
                                    ? ', '
                                    : false}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data.deporte !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faFutbol} />
                        </div>
                        <div className="col">
                          <div><b>Deportes favoritos:</b></div>
                          <div>
                            <p>{data.deporte}</p>
                            {/* {data.deporte.map((row, key) => {
                              return (
                                <span key={key}>
                                  {row}
                                  {key}
                                </span>
                              );
                            })} */}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data.hobbie !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faMicrophone} />
                        </div>
                        <div className="col">
                          <div><strong>Hobbies:</strong></div>
                          <div>
                            <p>{data.hobbie}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data.musica !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faMusic} />
                        </div>
                        <div className="col">
                          <div><b>Me gusta esta música:</b></div>
                          <div>
                            <p>{data.musica}</p>

                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data.importante !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faCompactDisc} />
                        </div>
                        <div className="col">
                          <div>
                            <strong>Es importante para mi</strong>
                            {data.importante.map((row, key) => {
                              return (
                                <p key={key}>
                                  {row}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
    </div >
  );
}

export default ProfileUser;