import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { fetchPost } from '../../helpers/helpCore'
import '../styles/HelpPage.css'
import CarruselVertical from '../components/CarruselVertical'
import TextPost from '../components/TextPost'

const ProjectDetails = () => {
  const [data, setData] = useState({})
  const { id } = useParams();
  const token = localStorage.getItem('jwt')
  useEffect(() => {
    fetchPost({ token, url: '/project/viewProject/uniqued', id })
      .then(res => {
        setData(res)
      })
    // eslint-disable-next-line
  }, [id])


  return (
    <div className="HelpPage">
      <div className="HelpPage-container">
        <div className="ProjectDeatils-container">
          <div className="ProjectDetails-box">
            <h4>{data?.titleProject}</h4>
            <div>
              <CarruselVertical
                image1={{img:data?.image1, name:data?.titleProject}}
                image2={{img:data?.image2, name:data?.titleProject}}
                image3={{img:data?.image3, name:data?.titleProject}}
                project={true}
                 />
            </div> 
            <div className="ProjectDetails-box__text">
              <TextPost text={data?.textProject} />
            </div>

            <div className='Project-card__amount'>
              <p><strong>Se requiere</strong>  ${data?.amountProject}</p>
              <p><strong>Colectados:</strong> ${data?.accumulated}</p>
              <p><strong>Faltante:</strong> ${data?.raised}</p>
            </div>

            <div className='ProjectDetails-button'>
              <Link to={`/proyectos/checkout/${id}`}>
                DONA AQUÍ
              </Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProjectDetails;