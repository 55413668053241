import React, { useState } from "react";
import { fetchPost } from "../../helpers/helpCore";
import "../styles/Checkout.css";
import LogoPC from '../../assets/images/design/logoPC.png';
import Cards from '../../assets/images/design/cards.png';

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement
} from "@stripe/react-stripe-js";
import { useLocation, Redirect } from 'react-router-dom'
import { useStripe, useElements } from "@stripe/react-stripe-js"
import IconLoading from '../components/IconLoading';
import MessageResponse from '../components/MessageResponse.jsx';
import { useModal } from '../../hooks/useModal'
import Modals from "../components/Modals";

//LLAVE DE DESARROLLO
//nota: antes de usarlo, activar modo de desarrollo en la plataforma 
//const keyStripe = "pk_test_51KMjmhHH8vPWoGOXhLhUINw7CglOFoFj5jH955qsjXcvjIFq2dGnD8dm49yFZenuoBXKYMT532NpaXZoVI8NYit900bBtLMTfp"

//LLAVE DE PRODUCCION
const keyStripe = "pk_live_51KMjmhHH8vPWoGOXlrdRdJAz7BKno8BrPbFxcZc4nZIIUHHZl2Kf9EZoTEoSN51pUBjYa4hjZ0lOjiAl8weoBRUc00JfjIxC0N";
const stripePromise = loadStripe(keyStripe)


const CheckoutForm = () => {
  const [input, setInput] = useState({name:''})
  const [redirect, setRedirect] = useState(false);
  const stripe = useStripe();
  const [result, setResult] = useState('')
  const [loading, setLoading] = useState(false)
  const elements = useElements();
  const token = localStorage.getItem('jwt');
  const location = useLocation()
  const data = location?.state?.row;
  const [isOpen, openModal, closeModal] = useModal(false);
  

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    console.log(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: localStorage.getItem('email'),
      },
    });

    if (!error && data?.afterDiscount !== null ) {
      const { id } = paymentMethod;
      try {
        fetchPost({
          url: '/payments/process-payment',
          token,
          amount: data?.afterDiscount * 100,
          description: `${data?.title}`,
          month: data?.month,
          name: input.name,
          idSubscription: data?.id,
          id
        })
          .then(res => {
            setLoading(false)
            setResult(res?.msg)
            openModal();
            setTimeout(() => {
              setRedirect(res?.msg.includes('Hubo un error')  ? false : true)
            }, 4000);
          })

        elements.getElement(CardElement).clear();
        return 0;

      } catch (err) {
        setLoading(false)
        openModal();
      }
    } else {
      alert(`Hubo un error, intente nuevamente ${error.message}`)
      setLoading(false)
    }
  }

  return (
    <form className="CardFormStripe" onSubmit={handleSubmit}>
      {redirect ? <Redirect to={`/web/gracias-por-tu-compra`} /> : false}

      <div className="CardFormStripe-logo">
        <img src={LogoPC} alt="Logo" />
      </div>
      <div className="text-center mb-3">
        <h4>Completa la información para realizar pago</h4>
      </div>
      <p><strong>Datos de la tarjeta</strong></p>
      <div className="CardFormStripe-name">
        <input 
          type="text" 
          name="name" 
          value={input.name} 
          placeholder="NOMBRE DEL TITULAR"
          minLength={10}
          required
          onChange={handleChange}
          />
      </div>
      <div className="CardFormStripe-form">
        <CardElement
          hidePostalCode={true}
          options={{
            style: {
              base: {
                iconColor: '#77e',
                color: '#2d2d2d',
                fontWeight: '600',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '18px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#2d2d2d',
                },
                '::placeholder': {
                  color: '#2d2d2d',
                },
              },
              invalid: {
                iconColor: '#FFC7EE',
                color: '#2d2d2d',
              },
            },
          }}
        />
      </div>
      <div className="cards">
        <span>Aceptamos:</span>
        <img src={Cards} alt="" />
      </div>
      <span><strong>Valor:</strong> ${data?.afterDiscount} </span>
      <span><strong>Plan:</strong> {data?.title} </span>
      <span><strong>Descripción: </strong> {data?.subtitle}</span>
      <input type="submit" value="Comprar" disabled={!stripe} />
      {loading && <IconLoading />}

      <MessageResponse
        result={result}
        setResult={setResult} />
        <Modals
        isOpen={isOpen}
        closeModal={closeModal}
        payment={true}
        text={result !== 'Hubo un error'
          ? 'Transacción exitosa'
          : 'Hubo un error'}>
        <div>
          <p><strong>{result}</strong></p>
        </div>
        </Modals>
    </form>
  );
};

const Form = () => {
  return (
    <div className="CardFormStripe-container">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  )
}
export default Form;