import { useState } from 'react';

export default function useCheckboxImportante (itemsImportante) {
  const [checkedImportante, setCheckedImportante] = useState(false);
  const dataImportante = [];

  // console.log(inputs)

  const limitCheckbox = (e) => {
    const limit = 3;
    var count = 0, i = 0;

    for (i = 0; i < itemsImportante.length; i++) {
      // console.log(`${i} ${itemsImportante[i].value} ${itemsImportante[i].checked}`)
      // console.log(itemsImportante[i].checkedImportante)

      if (itemsImportante[i].checked) {
        count = count + 1;
      }
      //     //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsImportante[i].checked = false;
      }
    }
  }


  const onChangeImportante = (e) => {
    limitCheckbox();
    setCheckedImportante({
      [e.target.value]: checkedImportante
    })
  }

  const handleClickImportante = () => {
    // console.log('esta funcionando')

    for (var i = 0; i < itemsImportante.length; i++) {
      // console.log(`${i} ${itemsImportante[i].value} ${itemsImportante[i].checked}`)

      if (itemsImportante[i].checked) {
        dataImportante.push(itemsImportante[i].value)
      }
    }

    return dataImportante;

  }

  return {
    // dataImportante,
    checkedImportante,
    onChangeImportante,
    handleClickImportante,
  }
}
