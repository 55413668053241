import React from 'react';

// import SocialNetworks from '../components/SocialNetworks';
import NavBar from '../components/Navbar';

const Header = () =>{
  return(
    <div className="main">
      {/* <SocialNetworks/> */}
      <NavBar/>
    </div>
  )
}

export default Header;