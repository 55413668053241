import React, { useState, useEffect } from 'react';
import { fetchPost } from '../../helpers/helpCore';
import useAuth from '../../hooks/useAuth';
import useCheckboxPermission from '../../hooks/useCkeckboxPermissionAdmi';
import MessageResponse from '../components/MessageResponse';


import '../styles/Admi.css'

const initialinput = {
  email: '',
  emailNewAdmin: '',
  password: '',
  rol: ''
}
const AdminCreateNewAdmin = () => {
  const [data, setData] = useState([])
  const [input, setInput] = useState(initialinput)
  const [result, setResult] = useState('')
  const [validateAdmin, setValidateAdmin] = useState();
  const [load, setLoad] = useState(false)
  const [validateEmail, setValidateEmail] = useState(false)
  const token = localStorage.getItem('jwt')
  const email = localStorage.getItem('email')
  const { logout } = useAuth()
  const itemsPermission = document.querySelectorAll('[name="checkboxPermission"]');


  const {
    onChangePermission,
    handleClickPermission,
    checkedPermission
  } = useCheckboxPermission(itemsPermission);

  useEffect(() => {
    fetchPost({ token, url: '/admin/validateAdmin' })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
        }
      })
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.emailNewAdmin === '' || !input.emailNewAdmin.includes('@')) {
      return alert('Debe ingresar el correo del nuevo administrador')
    }
   
    fetchPost({
      token,
      url: '/admin/validateNewAdmin',
      emailNewAdmin: input.emailNewAdmin,
      email: email,
    })
      .then(res => {
        console.log(res)
        setValidateEmail(res?.validate)
        setData(res?.msg)
        setValidateAdmin(res?.msg !== 'Usuario Administrador'
          ? true // no es admin
          : false // es admin
        )
        setResult(res?.msg)
      })
  }

  const handleSubmitChangeRol = (e) => {
    e.preventDefault();
    let permission = null;
    if(data !== 'Usuario Administrador'){
      permission = handleClickPermission();
      if (permission.length === 0) {
        return alert('Debe elegir al menos un permisos para el administrador')
      }
  
    }
   
    fetchPost({
      token,
      url: '/admin/createAdmin',
      emailNewAdmin: input.emailNewAdmin,
      password: input.password,
      createAdmin: validateAdmin,
      permission: permission

    })
      .then(res => {
        console.log(res)
        setValidateEmail(res?.validate)
        setResult(res?.msg)

      })
  }

  return (
    <div className="Admin-container">
      <div className='Admin-title'>{/*opciones: ver reportes, ver contactos, ver perfiles*/}
      </div>
      <div className="Admi-box">
        <h3>Control de Administrador</h3>
        {
          load &&
          <div className='AdminViewActivity-container'>
            <div className="AdminValidateAdmin">
              {
                !validateEmail &&
                <form onSubmit={handleSubmit}>
                  <h2>Ingrese correo del usuario a cambiar</h2>
                  <p>Correo del usuario</p>
                  <input
                    type="email"
                    name="emailNewAdmin"
                    value={input.emailNewAdmin}
                    onChange={handleChange}
                    placeholder="Correo del usuario"
                  />


                  <input type="submit" value="Enviar" className="btn btn-primary mt-4" />
                </form>
              }
              {
                validateEmail &&
                <form onSubmit={handleSubmitChangeRol}>
                  <h2>
                    {data === 'Usuario Administrador'
                      ? 'Remover permisos de Administrador'
                      : 'Asignar permisos de Administrador'}
                  </h2>

                  {
                    data !== 'Usuario Administrador' &&
                    <div className='m-4 AdminValidateAdmin-box'>
                      <h4>Permisos del administrador</h4>

                      <div className="text-center checkbox mt-4">
                        <input
                          type="checkbox"
                          defaultChecked={checkedPermission}
                          value="changeRolPermission"
                          name="checkboxPermission"
                          id="changeRolPermission"
                          onChange={onChangePermission} />
                        <label
                          title='Capacidad de Asignar/remover rol de Premiere'
                          className="btn btn-outline-primary2 btn-block"
                          htmlFor="changeRolPermission">
                          Cambiar roles
                        </label>
                      </div>

                      <div className="text-center checkbox mt-4">
                        <input
                          type="checkbox"
                          defaultChecked={checkedPermission}
                          value="controlAcountPermission"
                          name="checkboxPermission"
                          id="controlAcountPermission"
                          onChange={onChangePermission} />
                        <label
                          title='Capacidad de Supender/activar cuentas'
                          className="btn btn-outline-primary2 btn-block"
                          htmlFor="controlAcountPermission">
                          Control de cuentas
                        </label>
                      </div>

                      <div className="text-center checkbox mt-4">
                        <input
                          type="checkbox"
                          defaultChecked={checkedPermission}
                          value="controlAdmiPermission"
                          name="checkboxPermission"
                          id="controlAdmiPermission"
                          onChange={onChangePermission} />
                        <label
                          title='Capacidad de crear nuevos administradores o removerlos'
                          className="btn btn-outline-primary2 btn-block"
                          htmlFor="controlAdmiPermission">
                          Control de administradores
                        </label>
                      </div>

                      <div className="text-center checkbox mt-4">
                        <input
                          type="checkbox"
                          defaultChecked={checkedPermission}
                          value="projectPermission"
                          name="checkboxPermission"
                          id="projectPermission"
                          onChange={onChangePermission} />
                        <label
                          title='Capacidad de Crear/ocultar proyectos, y Crear historias de ayuda'
                          className="btn btn-outline-primary2 btn-block"
                          htmlFor="projectPermission">
                          Proyectos
                        </label>
                      </div>

                      <div className="text-center checkbox mt-4">
                        <input
                          type="checkbox"
                          defaultChecked={checkedPermission}
                          value="helpPermission"
                          name="checkboxPermission"
                          id="helpPermission"
                          onChange={onChangePermission} />
                        <label
                          title='Capacidad de Ocultar publicaciones por los usuarios'
                          className="btn btn-outline-primary2 btn-block"
                          htmlFor="helpPermission">
                          Ayudas
                        </label>
                      </div>

                    </div>
                  }


                  <p><b>Usuario a modificar:</b> {input.emailNewAdmin}</p>
                  <p>ingresa tu contraseña</p>

                  <input
                    type="password"
                    name="password"
                    value={input.password}
                    onChange={handleChange}
                    placeholder="Ingresa tu contraseña" />
                  <input type="submit" value="Enviar" className="btn btn-primary mt-4" />

                </form>
              }
            </div>
            <MessageResponse
              result={result}
              setResult={setResult} />
          </div>

        }

      </div>
    </div>
  )
}

export default AdminCreateNewAdmin;