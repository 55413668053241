import React, { useEffect, useState } from 'react';
import '../styles/Admi.css'
import { fetchPost } from '../../helpers/helpCore'
import IconLoading from '../components/IconLoading';
import MessageResponse from '../components/MessageResponse';
import useAuth from '../../hooks/useAuth';
import { MultipleFileProjectUpload } from '../../helpers/helpCore';
import { convertBase64} from '../../helpers/convertBase64';
// export default {convertBase64, };


const initialText = {
  text: '',
  amount: 0,
  title: '',
}

const AdminPostProject = () => {
  const [inputs, setInputs] = useState(initialText)
  // const [images, setImages] = useState({});
  const [imageBase64_1, setImageBase64_1] = useState('')
  const [imageBase64_2, setImageBase64_2] = useState('')
  const [imageBase64_3, setImageBase64_3] = useState('')
  const [image1, setImage1] = useState(false);
  const [image2, setImage2] = useState(false);
  const [image3, setImage3] = useState(false);
  const [viewImage1, setviewImage1] = useState('')
  const [viewImage2, setviewImage2] = useState('')
  const [viewImage3, setviewImage3] = useState('')
  const [result, setResult] = useState('');
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    const url = '/admin/validateAdmin'
    fetchPost({ token, url })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
        }
      })
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (image1 !== false) {
      try {
        setviewImage1(URL.createObjectURL(image1))
      } catch (err) {
        console.log(err)
        setviewImage1('')
        setImage1(false)
      }
    }
    if (image2 !== false) {
      try {
        setviewImage2(URL.createObjectURL(image2))
      } catch (err) {
        setviewImage2('')
        setImage2(false)
      }
    }
    if (image3 !== false) {
      try {
        setviewImage3(URL.createObjectURL(image3))
      } catch (err) {
        setviewImage3('')
        setImage3(false)
      }
    }
  }, [image1, image2, image3])

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  }

  const handleChangeImage1 = (e) => {
    setImage1(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_1)
  }
  const handleChangeImage2 = (e) => {
    setImage2(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_2)

  }
  const handleChangeImage3 = (e) => {
    setImage3(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64_3)
  }


  const handleSubmitMultipleImage = async (user) => {
    const images = [
      imageBase64_1, imageBase64_2, imageBase64_3
    ]

    if (image1 !== '') {
      const res = await MultipleFileProjectUpload({ image: images }, user);
      if (res) {
        setImageBase64_1('')
        setImageBase64_2('')
        setImageBase64_3('')
        setImage1(false)
        setImage2(false)
        setImage3(false)
        setviewImage1('')
        setviewImage2('')
        setviewImage3('')
        setLoading(false);
        setResult(res);
        // setImages({});
        setInputs(initialText)
      } else {
        setResult('Error - No se pudo subir el proyecto correctamente')
      }
     
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (image1 !== false && image2 !== false && image3 !== false) {
      if (inputs.text === '' || inputs.amount === 0 || inputs.title === '') {
        setResult('ERROR - Publicación de proyecto incorrecto');
      } else {
        setLoading(true)
        fetchPost({
          token,
          url: '/project/createProject',
          title: inputs?.title,
          text: inputs?.text,
          amount: inputs?.amount,
          image: [image1, image2, image3],
        })
          .then(res => {
            setResult(res?.msg);
            if (res?.id) {
              handleSubmitMultipleImage(res?.id)
            } else {
              setLoading(false)
            }
          })
      }
    } else {
      setResult('Error - debe llenar las 3 imágenes')
    }
  }

  return (
    <div className="Admin-container">
      <div>
        <h3>Publicar un proyecto</h3>
      </div>

      {
        load &&
        <div className='Admin-container__Project'>
          <div className="Admin-container__ProjectPost">
            <div className="Admin-ProjectPost__box">
              <h4>Añade 3 imágenes para el proyecto (*)</h4>

              <div className="multi-image__Input">
                <div className='multi-image__InputFile'>
                  <div className='multi-image__InputText'>
                    {
                      image1
                        ? (
                          <div className='InputFile-image__add'>
                            <img src={viewImage1} alt="Imagen 1" />
                          </div>
                        )
                        : (
                          <p>Añadir imagen</p>
                        )
                    }
                  </div>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    size="5120"
                    onChange={(e) => handleChangeImage1(e)} />
                </div>
                <div className='multi-image__InputFile'>
                  <div className='multi-image__InputText'>
                    {
                      image2
                        ? (
                          <div className='InputFile-image__add'>
                            <img src={viewImage2} alt="Imagen 1" />
                          </div>
                        )
                        : (
                          <p>Añadir imagen</p>
                        )
                    }
                  </div>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    size="5120"
                    onChange={(e) => handleChangeImage2(e)} />
                </div>

                <div className='multi-image__InputFile'>
                  <div className='multi-image__InputText'>
                    {
                      image3
                        ? (
                          <div className='InputFile-image__add'>
                            <img src={viewImage3} alt="Imagen 1" />
                          </div>
                        )
                        : (
                          <p>Añadir imagen</p>
                        )
                    }
                  </div>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    size="5120"
                    onChange={(e) => handleChangeImage3(e)} />
                </div>
              </div>
              <div className='Admin-container__PostText'>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="titulo del proyecto" className='PostText-title'>
                    <strong><p>Ingresa el titúlo del proyecto (*)</p></strong>
                    <textarea
                      type="text"
                      name="title"
                      maxLength="200"
                      placeholder="Titulo del proyecto"
                      id='titulo del proyecto'
                      value={inputs.title}
                      required
                      onChange={handleChange}
                    />
                  </label>
                  <label htmlFor="Descripción del proyecto" className='PostText-description'>
                    <strong><p>Ingresa un texto describiendo el proyecto (*)</p></strong>
                    <textarea
                      type="text"
                      name="text"
                      maxLength="2000"
                      placeholder="Descripción del proyecto"
                      id='Descripción del proyecto'
                      value={inputs.text}
                      required
                      onChange={handleChange}
                    />
                  </label>

                  <label htmlFor="amount" className='PostText-amount'>
                    <p>Ingrese cantidad a recaudar(*)</p>
                    <input
                      type="number"
                      name="amount"
                      id='amount'
                      precision={2}
                      min={20}
                      placeholder='Monto (MNX)'
                      value={inputs.amount}
                      onChange={handleChange}
                      required />
                  </label>

                  <br />
                  <strong><p>Los que tengan (*) son obligatorios</p></strong>
                  <input
                    type="submit"
                    value="Publicar Proyecto"
                    className="btn btn-primary2" />
                </form>
                <MessageResponse result={result} setResult={setResult} />
                {loading && <IconLoading />}

              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}

export default AdminPostProject;