import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Banner from '../../assets/images/design/registro-1.jpg';
import Input from '../components/Input';
import { fetchPost } from '../../helpers/helpCore'
import MessageResponse from '../components/MessageResponse';

const initialForm = {
  soy: '',
  busco: '',
  email: '',
  password: '',
};//inicializacion de datos


const Signup = () => {
  const [inputs, setInputs] = useState(initialForm);
  const [redirect, setRedirect] = useState(false)
  const [result, setResult] = useState('')

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (inputs.soy === '' || inputs.busco === '') {
      return setResult('Debe elegir una de las opciones de Soy, y Busco')
    }
    fetchPost({
      url: '/signup',
      soy: inputs.soy,
      busco: inputs.busco,
      email: inputs.email,
      password: inputs.password,
    })
      .then(res => {
        try {
          if (res.data.errors[0].msg) {
            setResult(res.data.errors[0].msg);
          }
        } catch (err) {
          setRedirect(!redirect)
        }
      });
  }

  return (
    <div className="container-fluid">
      {redirect ? (<Redirect to="/auth/signin" />) : false}

      <div className="row">
        <div className="col p-0">
          <img src={Banner} alt="programandoweb" className="img-fluid" />
        </div>
      </div>
      <div className="row justify-content-center bg-white pt-5">
        <div className="col-12 col-sm-8">
          <h3 className="text-center">
            Ayúdanos a llenar tu cuenta y perfil
          </h3>
        </div>
      </div>
      <div className="row justify-content-center bg-white pt-5 pb-5">
        <div className="col-12 col-sm-6">
          <div className="card border-danger">

            <form onSubmit={onSubmit}>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col text-dark">
                    Soy
                    <div>
                      <input
                        onChange={onChange}
                        name="soy"
                        type="radio"
                        className="radio_custom mr-2"
                        value={'Hombre'}
                      />
                      Hombre
                    </div>
                    <div>
                      <input
                        onChange={onChange}
                        name="soy"
                        type="radio"
                        className="radio_custom mr-2"
                        value={'Mujer'}
                      />
                      Mujer
                    </div>
                  </div>
                  <div className="col text-dark">
                    Busco
                    <div>
                      <input
                        onChange={onChange}
                        name="busco"
                        type="radio"
                        className="radio_custom mr-2"
                        value={'Hombre'}
                      />
                      Hombre
                    </div>
                    <div>
                      <input
                        onChange={onChange}
                        name="busco"
                        type="radio"
                        className="radio_custom mr-2"
                        value={'Mujer'}
                      />
                      Mujer
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center pt-3">
                  <div className="col text-dark">
                    <Input
                      placeholder='Correo electronico'
                      type="email"
                      name="email"
                      value={inputs.email}
                      required
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col text-dark">
                    <Input
                      placeholder='Contraseña'
                      type="password"
                      name="password"
                      minLenght="6"
                      maxLength="12"
                      value={inputs.password}
                      required
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <MessageResponse
                  result={result}
                  setResult={setResult}
                />
                <div className="m-3 col text-dark text-center text-mini">
                  Al registrarse confirmas que estás de acuerdo con los términos y condiciones
                  y declaras estar de acuerdo
                  con la política de privacidad de los datos personales
                </div>
                <div className="row justify-content-center">

                  <div className="col text-dark text-right">
                    <button type="submit" className="btn btn-primary2">Registrarme</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {
        window.performance.navigation.type == 1
        ? confirm('Desea actualizar') 
          ? (<Redirect to="/"/>)
          : null
        : null
      } */}
    </div>
  );
};

export default Signup;
// const SignupComponent = connect()(Signup);
// export {SignupComponent as Signup}
