import React, {
  useState,
  useEffect,
} from 'react'
import { fetchPost } from '../../helpers/helpCore'
import useAuth from '../../hooks/useAuth';
import ReactPaginate from 'react-paginate';
import PaginationPost from './PaginationPost';
import IconLoading from './IconLoading';

const HelpPost = ({ postNew }) => {
  const [post, setPost] = useState([])
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()
  const url = '/post'
  //===
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [loading, setLoading] = useState(false)
  const itemsPerPage = 20;//numero de post en pantalla
  //===


  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % post?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setLoading(true)
    fetchPost({ url, token })
      .then(res => {
        if (res === false) {
          setLoading(false)
          logout();
        } else {
          setLoading(false)
          setPost(res)
        }
      })
      .catch(err => {
        console.log(err)
      })
      // eslint-disable-next-line
  }, [postNew]);

  return (
    <div className="HelpPage-post">
      <div className="HelpPage-post__title">
        <h3>Publicaciones</h3>
      </div>
      <div>
        <div className='HelpPage-post__box'>
          {
            post?.length !== 0
              ? (
                <PaginationPost
                  data={post}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  itemsPerPage={itemsPerPage}
                  handlePageClick={handlePageClick}
                />
              )
              : (false)
          }

        </div>
        {loading && <IconLoading/>}
        <div className="ReactPaginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Siguiente >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< Anterior"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  )
}

export default HelpPost;