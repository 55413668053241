import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { fetchPost } from '../../helpers/helpCore';
import useAuth from '../../hooks/useAuth';

import '../styles/Admi.css'

const Admi = () => {
  const name = localStorage.getItem('email');
  const token = localStorage.getItem('jwt')
  const [load, setLoad] = useState(false);
  const { logout } = useAuth()


  useEffect(() => {
    fetchPost({ token, url: '/admin/validateAdmin' })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res)
        }
      })
      .catch(err => console.log(err))
      // eslint-disable-next-line
  }, [])

  return (
    <div className="Admin-container">
      <div className='Admin-title'>{/*opciones: ver reportes, ver contactos, ver perfiles*/}
        <h2>Hola, {name}</h2>
      </div>
      {
        load &&
        <div className='Admin-box'>
          <h3>Sección general</h3>
          <div className='Admin-card'>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/report'}>Ver reportes</Link>
            </div>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/contact'}>Ver mensajes de contactos</Link>
            </div>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/profiles'}>Suspender / Activar cuentas</Link>
            </div>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/change-rol'}>Cambiar roles de usuarios</Link>
            </div>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/viewActivity'}>Ver actividad de administadores</Link>
            </div>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/createAdmin'}>Control de Administrador</Link>
            </div>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/editSlider'}>Modificar slider principal</Link>
            </div>
          </div>
        </div>
      }

      {
        load &&
        <div className='Admin-box'>
          <h3>Sección de Ayuda</h3>
          <div className='Admin-card'>
            <div className='Admin--card__small'>
              <Link to={'/web/admin/Note-data'}>Publicaciones</Link>
            </div>
          </div>
        </div>
      }

      <div>
        {
          load &&
          <div>
            <div className="Admin-box">
              <h3>Sección de Proyectos</h3>
              <div className='Admin-card'>
                <div className='Admin--card__small'>
                  <Link to={'/web/admin/create-project'}>
                    Crear Proyecto
                  </Link>
                </div>
                <div className='Admin--card__small'>
                  <Link to={'/web/admin/hidden-project'}>
                    Ocultar proyecto
                  </Link>
                </div>
                <div className='Admin--card__small'>
                  <Link to={'/web/admin/create-help-history'}>
                    Crear HIstorias de ayuda
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

    </div>
  )
}

export default Admi;