import React from 'react'
import {Link} from 'react-router-dom'

const CommentPost = ({uid, idPost,comment}) => {
  return (
    <div className="buttonBox-Comment">
      {/* <input type="button" value={`Comentario 999`} /> */}
      <Link to={`/ayuda/${idPost}`}>
        {`Comentario ${comment === 0 ? '' : comment}`}
      </Link>
    </div>
  )
}

export default CommentPost;