import React, { useEffect, useState } from 'react'
import '../styles/Admi.css'
import { fetchPost } from '../../helpers/helpCore'
import IconLoading from '../components/IconLoading';
import MessageResponse from '../components/MessageResponse';
import useAuth from '../../hooks/useAuth';
import AdminHiddenProjectCard from '../components/AdminHiddenProjectCard';

const AdminHiddenProject = () => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState(false)
  const [result, setResult] = useState('');
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    setLoading(true)
    fetchPost({ token, url: '/admin/viewProjectAdmin' })
      .then(response => {
        setData(response)
        setLoading(false)
        if (status) {
          setStatus(false)
        }
      })
      // eslint-disable-next-line
  }, [status])

  useEffect(() => {
    const url = '/admin/validateAdmin'
    fetchPost({ token, url })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
        }
      })
      // eslint-disable-next-line
  }, [])




  return (
    <div className="Admin-container">
      <div>
        <h3>Activar / Ocultar Proyectos</h3>
      </div>
      <MessageResponse result={result} setResult={setResult} />
      {loading && <IconLoading />}
      {
        load &&
        <div className='Admin-Profile__container'>
          {
            data.map((data, key) => {
              return (
               <AdminHiddenProjectCard 
                  key={key} 
                  data={data}
                  token={token}
                  setResult={setResult}
                  setStatus={setStatus} />
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default AdminHiddenProject;