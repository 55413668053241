import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import '../styles/WebMiPerfil.css'

import Banner from '../../assets/images/design/img-proceso-de-registro-3.jpg';
import ciudad from '../../assets/images/design/ciudad.jpg';
import montanas from '../../assets/images/design/montanas.jpg';
import playa from '../../assets/images/design/playa.jpg';
import nieve from '../../assets/images/design/nieve.jpg';
import pueblo from '../../assets/images/design/pueblo.jpg';
import piramide from '../../assets/images/design/piramide.jpg';

import useCheckeboxTiempo from '../../hooks/useCheckeboxTiempo';
import useCheckboxImportante from '../../hooks/useCheckboxImportante';
import useCheckboxVacasiones from '../../hooks/useCheckboxVacasiones';

import { fetchPost } from '../../helpers/helpCore'
import MessageResponse from '../components/MessageResponse';

const initialState = {
  radioBox: '',
  radioFumas: ''
}

const WebMiPerfil3 = () => {
  const [form, setForm] = useState(0);
  const [idUser, setIdUser] = useState(null)
  const [result, setResult] = useState('')
  const [inputs, setInputs] = useState(initialState);
  const { radioBox, radioFumas} = inputs;
  const [redirect, setRedirect] = useState(false);

  // const [importante, setImportante] = useState([])
  const itemsTiempo = document.querySelectorAll('[name="checkboxTiempo"]');
  const itemsImportante = document.querySelectorAll('[name="checkboxImportante"]');
  const itemsVacasiones = document.querySelectorAll('[name="checboxVacasiones"]');
  const usuario = localStorage.getItem('uid');

  //===VERIFICACION DE DATOS EXISTENTES POR USUARIOS
  useEffect(() => {
    let idUrl = localStorage.getItem('uid');
    fetchPost({ idUrl, url: '/perfilAtractivo' })
      .then((response) => {
        setIdUser(response ? response._id : false)
      })
  }, [])
  //====================================================

  //=========== CUSTOM HOOKS =====================
  const {
    onChangeTiempo,
    handleClickTiempo,
    checkedTiempo
  } = useCheckeboxTiempo(itemsTiempo);

  const {
    checkedImportante,
    onChangeImportante,
    handleClickImportante,
  } = useCheckboxImportante(itemsImportante)

  const {
    checkedVacasiones,
    onChangeVacasiones,
    handleClickVacasiones,
  } = useCheckboxVacasiones(itemsVacasiones)
  //==============================================

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };


  const addInfoForm = () => {
    const dataImportante = handleClickImportante()
    const dataVacasiones = handleClickVacasiones()
    const dataTiempo = handleClickTiempo()
    setInputs({
      ...inputs,
      inputTiempo: dataTiempo,
      inputVacasiones: dataVacasiones,
      inputImportante: dataImportante
    })

    return { dataTiempo, dataImportante, dataVacasiones }


  }

  const PerfilAtractivoUpdate = ({ dataTiempo, dataImportante, dataVacasiones }) => {
    // console.log(usuario)
    fetchPost({
      bebes_alcohol: inputs.radioBox,
      fumas: inputs.radioFumas,
      importante: dataTiempo,
      tiempo_libre: dataVacasiones,
      vacaciones: dataImportante,
      usuario: usuario,
      _id: idUser,
      idUrl: usuario,
      method: 'PUT',
      url: '/perfilAtractivo'
    }).then(res => {
      //setResult(res?.msg);
      setRedirect(!redirect)
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { dataTiempo, dataImportante, dataVacasiones } = addInfoForm()

    if (inputs.radioBox === '' || inputs.radioFumas === '') { return setResult('Debes elegir una opción en fumas y bebes') }
    if (dataTiempo.length !== 3) { return setResult('Tiempo libre debe tener 3 opciones elegidas') }
    if (dataImportante.length !== 3) { return setResult('Que es importante para ti debe tener 3 opciones elegidas') }
    if (dataVacasiones.length !== 2) { return setResult('Vacaciones debe tener 2 opciones elegidas') }

    //VALIDACION DE ENVIOS DE DATOS Y ENVIO AL BACKEND
    if (radioBox !== '' && radioFumas !== '' && dataTiempo.length === 3 &&
      dataImportante.length === 3 && dataVacasiones.length === 2) {

      if (idUser === false) {
        fetchPost({
          bebes_alcohol: inputs.radioBox,
          fumas: inputs.radioFumas,
          importante: dataImportante,
          tiempo_libre: dataTiempo,
          vacaciones: dataVacasiones,
          usuario: usuario,
          url: '/perfilAtractivo'
        })
          .then(data => {
            setRedirect(!redirect)
          })
      } else {
        PerfilAtractivoUpdate({ dataTiempo, dataImportante, dataVacasiones })
      }

    }

  }



  return (
    <div className={'main'}>
      {redirect ? (<Redirect to="/web/profile4" />) : false}
      <div className="container-fluid bg-white">
        <div className="row mb-3">
          <div className="col p-0">
            <img src={Banner} alt="programandoweb" className="img-fluid" loading="lazy"/>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-3 text-center">
          <h5 className="col">
            <b>
              Vamos a hacer tu perfil más atractivo con datos de lo que te
              gusta, interesa, apasiona y motiva
            </b>
          </h5>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row mb-3">
            <div className="col-12 col-sm-6 p-0 ">
              {/* ============== FUMAS - BEBES ============== */}
              <div className="row mb-2 text-center box-perfil">
                <div className="">
                  ¿Bebes alcohol?
                </div>
                <div className="col text-messages ">
                  <div className="row pb-2 radio input-none">
                    <input
                      type="radio"
                      checked={inputs.radioBox === 'Regularmente'}
                      value="Regularmente"
                      name="radioBox"
                      id="Regularmente"
                      onChange={onChange} />
                    <label htmlFor="Regularmente">
                      Regularmente
                    </label>

                    <input
                      type="radio"
                      checked={inputs.radioBox === 'Ocasionalmente'}
                      value="Ocasionalmente"
                      name="radioBox"
                      id="Ocasionalmente"
                      onChange={onChange} />
                    <label htmlFor="Ocasionalmente">
                      Ocasionalemente
                    </label>

                    <input
                      type="radio"
                      checked={inputs.radioBox === 'No'}
                      value="No"
                      name="radioBox"
                      id="No"
                      onChange={onChange} />
                    <label htmlFor="No">No</label>
                  </div>
                </div>


              </div>
              <div className="row mb-2 text-center box-perfil">
                <div className="">
                  ¿Fumas?
                </div>
                <div className="col text-messages ">
                  <div className="row pb-2 radio input-none">
                    <input
                      type="radio"
                      checked={inputs.radioFumas === 'Si, todos los días'}
                      value="Si, todos los días"
                      name="radioFumas"
                      id="Si, todos los días"
                      onChange={onChange} />
                    <label htmlFor="Si, todos los días">
                      Sí, todos los días
                    </label>

                    <input
                      type="radio"
                      checked={inputs.radioFumas === 'De vez en cuando'}
                      value="De vez en cuando"
                      name="radioFumas"
                      id="De vez en cuando"
                      onChange={onChange} />
                    <label htmlFor="De vez en cuando">
                      De vez en cuando
                    </label>

                    <input
                      type="radio"
                      checked={inputs.radioFumas === 'Tratando de dejarlo'}
                      value="Tratando de dejarlo"
                      name="radioFumas"
                      id="Tratando de dejarlo"
                      onChange={onChange} />
                    <label htmlFor="Tratando de dejarlo">Tratando de dejarlo</label>

                    <input
                      type="radio"
                      checked={inputs.radioFumas === 'NO'}
                      value="NO"
                      name="radioFumas"
                      id="NO"
                      onChange={onChange} />
                    <label htmlFor="NO">NO</label>
                  </div>
                </div>

                {/* =============================================== */}

              </div>

              <div className="col-12 text-center mb-4 pt-2">
                ¿Qué te gusta hacer en tu tiempo libre? ( Escoger 3 )
              </div>
              <div>
                <div className="col-12">
                  <div className="row mb-3">
                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Leer"
                        name="checkboxTiempo"
                        id="Leer"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Leer">
                        Leer
                      </label>
                    </div>

                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Escuchar música"
                        name="checkboxTiempo"
                        id="Escuchar música"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Escuchar música">
                        Escuchar música
                      </label>
                    </div>

                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Caminar"
                        name="checkboxTiempo"
                        id="Caminar"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Caminar">
                        Caminar
                      </label>
                    </div>

                  </div>

                  <div className="row mb-3">
                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Salir de fiesta"
                        name="checkboxTiempo"
                        id="Salir de fiesta"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Salir de fiesta">
                        Salir de fiesta
                      </label>
                    </div>

                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Relajarme"
                        name="checkboxTiempo"
                        id="Relajarme"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Relajarme">
                        Relajarme
                      </label>
                    </div>

                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Estudiar"
                        name="checkboxTiempo"
                        id="Estudiar"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Estudiar">
                        Estudiar
                      </label>
                    </div>

                  </div>

                  <div className="row mb-3">
                    <div className="clo-12 col-sm-8 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Dedicarle tiempo a mis hobbies"
                        name="checkboxTiempo"
                        id="Dedicarle tiempo"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Dedicarle tiempo">
                        Dedicarle tiempo a mis hobbies
                      </label>
                    </div>


                    <div className="clo-12 col-sm-4 text-center checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checkedTiempo}
                        value="Otros"
                        name="checkboxTiempo"
                        id="Otros"
                        onChange={onChangeTiempo} />
                      <label
                        className="btn btn-outline-primary2 btn-block"
                        htmlFor="Otros">
                        Otros
                      </label>
                    </div>

                  </div>

                </div>
              </div>

              <div className="col-12 text-center mb-4 pt-2">
                ¿Qué es más importante para ti en una relación? ( Escoger 3 )
              </div>
              <div className="col-12">
                <div className="row mb-3">
                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Verdadera amistad"
                      name="checkboxImportante"
                      id="Verdadera amistad"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Verdadera amistad">
                      Verdadera amistad
                    </label>
                  </div>

                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Responsabilidad Mutua"
                      name="checkboxImportante"
                      id="Responsabilidad Mutua"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Responsabilidad Mutua">
                      Responsabilidad Mutua
                    </label>
                  </div>

                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Desarrollo personal y crecimiento"
                      name="checkboxImportante"
                      id="Desarrollo personal y crecimiento"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Desarrollo personal y crecimiento">
                      Desarrollo personal y crecimiento
                    </label>
                  </div>

                </div>

                <div className="row mb-3">
                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Amor propio"
                      name="checkboxImportante"
                      id="Amor propio"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Amor propio">
                      Amor propio
                    </label>
                  </div>

                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Una casa bonita"
                      name="checkboxImportante"
                      id="Una casa bonita"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Una casa bonita">
                      Una casa bonita
                    </label>
                  </div>

                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Una carrera exitosa"
                      name="checkboxImportante"
                      id="Una carrera exitosa"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Una carrera exitosa">
                      Una carrera exitosa
                    </label>
                  </div>

                </div>

                <div className="row mb-3">
                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Buena comunicación y respeto en general"
                      name="checkboxImportante"
                      id="Buena comunicacion y respeto en general"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Buena comunicacion y respeto en general">
                      Buena comunicación y respeto en general
                    </label>
                  </div>

                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Ayudar a otros"
                      name="checkboxImportante"
                      id="Ayudar a otros"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Ayudar a otros">
                      Ayudar a otros
                    </label>
                  </div>

                  <div className="clo-12 col-sm-4 text-center checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={checkedImportante}
                      value="Crear metas juntos"
                      name="checkboxImportante"
                      id="Crear metas juntos"
                      onChange={onChangeImportante} />
                    <label
                      className="btn btn-outline-primary2 btn-block"
                      htmlFor="Crear metas juntos">
                      Crear metas juntos
                    </label>
                  </div>

                </div>

              </div>

            </div>
            <div className="col-12 col-sm-6 p-0">
              <div className="row mb-2 text-center mb-3 justify-content-center">
                <div className="col-12 ">
                  Si quieres ir de vacaciones, cuál de las imágenes escogerías ( Escoger 2 )
                </div>
                <div className="col-10 pt-3 vacasiones">
                  <div className="row mb-5">

                    <div className="col-12 col-sm-6  text-center vacasiones">
                      <input
                        type="checkbox"
                        defaultChecked={checkedVacasiones}
                        value="playa"
                        name="checboxVacasiones"
                        id="playa"
                        onChange={onChangeVacasiones} />
                      <label
                        // className="btn btn-outline-primary2 btn-block"
                        htmlFor="playa">
                        <img
                          className="img-fluid rounded mx-auto d-block"
                          src={playa}
                          alt="playa"
                          loading="lazy" />
                      </label>
                    </div>

                    <div className="col-12 col-sm-6  text-center vacasiones">
                      <input
                        type="checkbox"
                        defaultChecked={checkedVacasiones}
                        value="montanas"
                        name="checboxVacasiones"
                        id="montanas"
                        onChange={onChangeVacasiones} />
                      <label
                        // className="btn btn-outline-primary2 btn-block"
                        htmlFor="montanas">
                        <img
                          className="img-fluid rounded mx-auto d-block"
                          src={montanas}
                          loading="lazy"
                          alt="montanas" />
                      </label>
                    </div>

                  </div>

                  <div className="row mb-5">

                    <div className="col-12 col-sm-6  text-center vacasiones">
                      <input
                        type="checkbox"
                        defaultChecked={checkedVacasiones}
                        value="nieve"
                        name="checboxVacasiones"
                        id="nieve"
                        onChange={onChangeVacasiones} />
                      <label
                        // className="btn btn-outline-primary2 btn-block"
                        htmlFor="nieve">
                        <img
                          className="img-fluid rounded mx-auto d-block"
                          src={nieve}
                          loading="lazy"
                          alt="nieve" />
                      </label>
                    </div>

                    <div className="col-12 col-sm-6  text-center vacasiones">
                      <input
                        type="checkbox"
                        defaultChecked={checkedVacasiones}
                        value="ciudad"
                        name="checboxVacasiones"
                        id="ciudad"
                        onChange={onChangeVacasiones} />
                      <label
                        // className="btn btn-outline-primary2 btn-block"
                        htmlFor="ciudad">
                        <img
                          className="img-fluid rounded mx-auto d-block"
                          src={ciudad}
                          loading="lazy"
                          alt="ciudad" />
                      </label>
                    </div>

                  </div>

                  <div className="row mb-5">

                    <div className="col-12 col-sm-6  text-center vacasiones">
                      <input
                        type="checkbox"
                        defaultChecked={checkedVacasiones}
                        value="pueblo"
                        name="checboxVacasiones"
                        id="pueblo"
                        onChange={onChangeVacasiones} />
                      <label
                        // className="btn btn-outline-primary2 btn-block"
                        htmlFor="pueblo">
                        <img
                          className="img-fluid rounded mx-auto d-block"
                          src={pueblo}
                          loading="lazy"
                          alt="pueblo" />
                      </label>
                    </div>

                    <div className="col-12 col-sm-6  text-center vacasiones">
                      <input
                        type="checkbox"
                        defaultChecked={checkedVacasiones}
                        value="piramides"
                        name="checboxVacasiones"
                        id="piramides"
                        onChange={onChangeVacasiones} />
                      <label
                        // className="btn btn-outline-primary2 btn-block"
                        htmlFor="piramides">
                        <img
                          className="img-fluid rounded mx-auto d-block"
                          src={piramide}
                          loading="lazy"
                          alt="piramides" />
                      </label>

                    </div>
                    <MessageResponse
                      result={result}
                      setResult={setResult}
                    />
                  </div>
                  <div className="row mb-3">

                    <div className="col text-right">
                      <input
                        type="submit"
                        className="btn btn-primary2"
                        value={'Guardar'}
                      // onClick={handleClick}
                      />
                    </div>
                    <div className="col text-center">
                      {idUser
                        ? (
                          <Link to="/web/profile4" className="btn btn-primary2">
                            Solo continuar
                          </Link>)
                        : false
                      }
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};


export default WebMiPerfil3;