import React, { useEffect } from 'react';
import '../styles/MessageResponse.css'

const MessageResponse = ({ result, setResult }) => {
  useEffect(() => {
    setTimeout(() => {
      setResult('')
    }, 5000);
    // eslint-disable-next-line 
  }, [result])

  return (
    <div className="MessageResponse">
      {
        result !== ''
          ? (
            <div className="MessageResponse-container" >
              <span>
                <strong>{`${result}`}</strong>
              </span>
            </div>
          )
          : (false)
      }
    </div>
  )
}

export default MessageResponse;