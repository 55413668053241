import React, { useState, useEffect } from 'react'
import { fetchPost } from '../../helpers/helpCore'
import Post from '../components/Post'
import '../styles/Post.css'
const ViewPostUser = () => {
  const [data, setData] = useState([])
  const token = localStorage.getItem('jwt')
  const [deletePost, setDeletePost] = useState('')
  useEffect(() => {
    fetchPost({
      token,
      url: '/post/viewPostUser',
      idUser: localStorage.getItem('uid')
    })
      .then(res => {
        setData(res)
      })
  }, [deletePost])

  return (
    <div className="ViewPostUser">
      <div className="ViewPostUser-container">
        <h4>Mis Publicaciones, puedes eliminarlos o editarlos</h4>
        {
          data.length !== 0
            ? (  
              data.map((data, key) => {
                return (
                  <Post
                    key={`${key}_${data?.uid}`}
                    uid={data?.idUser}
                    idPost={data?._id}
                    img={data?.img}
                    name={data?.nameUser}
                    dataPost={data?.dataPost}
                    reaction={data?.reaction}
                    comment={data?.Comment}
                    created={data?.createdAt}
                    reactionName={data?.reactionName}
                    editPost={data?.idUser}
                    setDeletePost={setDeletePost}
                  />
                )
              })
            )
            : (
              <div className='ViewPostUser-container__noPost'>
                <h5>No tiene publicaciones hechas</h5>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default ViewPostUser