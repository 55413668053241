import React, { useState, useEffect } from 'react';
import { fetchPost } from '../../helpers/helpCore';
import useAuth from '../../hooks/useAuth';
import CardAdminViewActivity from '../components/CardAdminViewActivity';

import '../styles/Admi.css'

const AdminViewActivity = () => {
  const [data, setData] = useState([])
  const [load, setLoad] = useState(false)
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    fetchPost({ token, url: '/admin/viewActivity' })
      .then(response => {
        setData(response ? response : [])
      })
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchPost({ token, url: '/admin/validateAdmin' })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
          // setData(res);
        }
      })
      // eslint-disable-next-line
  }, [])


  return (
    <div className="Admin-container">
      <div className='Admin-title'>{/*opciones: ver reportes, ver contactos, ver perfiles*/}
      </div>
      <div className="Admi-box">
        <h3>Actividad de Administradores</h3>
        {
          load &&
          <div className='AdminViewActivity-container'>
            {
              data.length !== 0
                ? (
                  data?.map((data, key) => {
                    return (
                      <CardAdminViewActivity
                        action={data.action}
                        email={data.email}
                        createdAt={data.createdAt}
                        key={key} />

                    )
                  })
                )
                : (false)
            }
          </div>
        }

      </div>
    </div>
  )
}

export default AdminViewActivity;