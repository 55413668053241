/*
name: texto a recortar
number: cantidad de palabras limite para cortar
*/
const recortName = (name, number) => {
  try {
    if (name?.length >= number) {
      let nameFirst = name.substr(0, number - 5)
      let ext = name.substr(-4)
      let nameEdit = `${nameFirst}...${number !== 180
        ? number !== 100 ? ext : ''
        : ''}`
      return nameEdit;
    } else {
      return name;
    }
  }
  catch (err) {
    return name;
  }
}

export default recortName;