import { useState } from 'react';

export default function useCheckboxTiempo (itemsTiempo) {
  const dataTiempo = [];
  const [checkedTiempo, setCheckedTiempo] = useState(false)


  const limitCheckbox = (e) => {
    const limit = 3;
    var count = 0, i = 0;

    for (i = 0; i < itemsTiempo.length; i++) {
      // console.log(`${i} ${itemsTiempo[i].value} ${itemsTiempo[i].checked}`)

      if (itemsTiempo[i].checked) {
        count = count + 1;
      }
      //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsTiempo[i].checked = false;
      }
    }
  }


  const onChangeTiempo = (e) => {
    limitCheckbox();

    setCheckedTiempo({
      [e.target.value]: checkedTiempo
    })
    
  }

  const handleClickTiempo = () => {
    // console.log('esta funcionando')

    for (var i = 0; i < itemsTiempo.length; i++) {
      // console.log(`${i} ${itemsTiempo[i].value} ${itemsTiempo[i].checked}`)

      if (itemsTiempo[i].checked) {
        dataTiempo.push(itemsTiempo[i].value)
      }
    }

    return dataTiempo;
  }


  return {
    // dataTiempo,
    checkedTiempo,
    onChangeTiempo,
    handleClickTiempo,
  }
}

