import React, { useEffect } from 'react'
import ItemProfile from './ItemProfile'
import Mujer from '../../assets/images/design/mujer.jpg';
import Hombre from '../../assets/images/design/hombre.jpg';

function Items({ currentItems }) {
  return (  
    <>
      {currentItems !== null
        ? (currentItems?.map((row, key) => {
          return (
            <ItemProfile 
              images={{ Hombre: Hombre, Mujer: Mujer }}
              key={`${currentItems.img}_${key}`}
              data={row}
              img={currentItems.img}
            />
          )
        })
        )
        : (<h2>Cargando...</h2>)
      }
    </>
  );
}

const ElementSearch = (
  { data , 
    currentItems,
    setCurrentItems,
    setPageCount,
    itemOffset,
    itemsPerPage,
  }) => {

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, itemsPerPage, data]);

  

  return (
    <>
      <Items currentItems={currentItems} />
    </>
  )
}

export default ElementSearch;