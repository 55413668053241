import React, { useState, useEffect } from "react";
import { fetchPost } from "../../helpers/helpCore";
import "../styles/Checkout.css";
import Cards from '../../assets/images/design/cards.png';

import LogoPC from '../../assets/images/design/logoPC.png';
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useParams, Redirect } from 'react-router-dom'
import IconLoading from '../components/IconLoading';
import MessageResponse from '../components/MessageResponse.jsx';
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useModal } from '../../hooks/useModal'
import Modals from "../components/Modals";

//LLAVE DE DESARROLLO
//nota: antes de usarlo, activar modo de desarrollo en la plataforma 
//const keyStripe = "pk_test_51KMjmhHH8vPWoGOXhLhUINw7CglOFoFj5jH955qsjXcvjIFq2dGnD8dm49yFZenuoBXKYMT532NpaXZoVI8NYit900bBtLMTfp"

//LLAVE DE PRODUCCION
const keyStripe = "pk_live_51KMjmhHH8vPWoGOXlrdRdJAz7BKno8BrPbFxcZc4nZIIUHHZl2Kf9EZoTEoSN51pUBjYa4hjZ0lOjiAl8weoBRUc00JfjIxC0N";
const stripePromise = loadStripe(keyStripe)


const InitialInputs = {
  day: null,
  month: null,
  year: null,
  name: '',
  email: localStorage.getItem('email'),
  lastnameMother: '',
  lastnameFather: '',
  numberPhone: '',
  amount: null,
  listProject: '',
}

const CheckoutForm = () => {
  const [input, setInput] = useState(InitialInputs);
  const [result, setResult] = useState('')
  const [loading, setLoading] = useState(false)
  const [listProjectDonate, setListProjectDonate] = useState([])
  const [redirect, setRedirect] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const token = localStorage.getItem('jwt');
  const { id } = useParams();
  const [isOpen, openModal, closeModal] = useModal(false);


  useEffect(() => {
    fetchPost({ token, url: '/project/viewDataProject' })
      .then(res => {
        setListProjectDonate(res);
      })
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    const {
      lastnameFather,
      lastnameMother,
      listProject,
      name,
      day,
      month,
      year,
      numberPhone } = input;

    if (!error
      && input?.amount !== null
      && name !== ''
      && lastnameFather !== ''
      && lastnameMother !== ''
      && day !== null
      && month !== null
      && year !== null
      && numberPhone !== null
      && listProject !== '') {
      const { id } = paymentMethod;

      try {
        fetchPost({
          url: '/payments/process-payment/donate-in-project',
          token,
          id,
          amount: input?.amount * 100,
          description: `Donación a proyecto`,
          name,
          lastnameFather,
          lastnameMother,
          day,
          month,
          year,
          numberPhone,
          listProject,//id del proyecto
          amountDonate: input?.amount
        })
          .then(res => {
            setLoading(false)
            setResult(res?.msg);
            openModal()
            if (res?.ok === true) {
              setInput(InitialInputs);
            }
            setTimeout(() => {
              setRedirect(res?.msg.includes('Hubo un error')  ? false : true)
            }, 6000);

          })

        elements.getElement(CardElement).clear();
      } catch (err) {
        setLoading(false)
        setResult(`Hubo un error - ${err.name}`)
        openModal();

      }
    } else {
      setLoading(false)
      alert(`Hubo un error - ${error.message}`)
      setResult(`Hubo un error - ${error.message}`)
    }
  }

  return (
    <form className="CardFormStripe" onSubmit={handleSubmit}>
      {redirect ? <Redirect to="/proyectos" /> : false}
      <div className="CardFormStripe-logo">
        <img src={LogoPC} alt="Logo" />
      </div>
      <div className="CardFormStripe-inputs">
        <p>¡Tus contribuciones son las que hacen esto posible!</p>
        <div className="CardFormStripe-inputs__option">
          <div className="CardFormStripe-inputs_optionTitleAge">
            <p>Fecha de nacimiento</p>

          </div>
          <div className="CardFormStripe-inputs_optionAge">
            <input
              required
              onChange={handleChange}
              type="number"
              placeholder="Día"
              min={1}
              max={31}
              value={input.day}
              name="day" />
            <input
              required
              onChange={handleChange}
              type="number"
              placeholder="Mes"
              min={1}
              max={12}
              value={input.month}
              name="month" />
            <input
              required
              onChange={handleChange}
              type="number"
              placeholder="Año"
              min={1900}
              max={2002}
              value={input.year}
              name="year" />
          </div>
        </div>
        <div className="CardFormStripe-inputs__option inputs-option__text">
          <input
            required
            onChange={handleChange}
            type="text"
            placeholder="Nombres"
            value={input.name}
            name="name" />
          <input
            required
            onChange={handleChange}
            type="text"
            placeholder="Apellido Paterno"
            value={input.lastnameFather}
            name="lastnameFather" />
          <input
            required
            onChange={handleChange}
            type="text"
            placeholder="Apellido Materno"
            value={input.lastnameMother}
            name="lastnameMother" />
        </div>
        <div className="CardFormStripe-inputs__option inputs-option__optional">
          <input
            required
            onChange={handleChange}
            type="text"
            disabled
            placeholder="Correo electrónico"
            name="email"
            value={input.email} />
          <input
            required
            onChange={handleChange}
            type="phone"
            placeholder="Teléfono"
            name="numberPhone"
            value={input.numberPhone} />
        </div>
        <div className="CardFormStripe-inputs__option">
          <div className="CardFromStripe-inputs__optionList">
            <select
              name="listProject"
              value={input.listProject}
              onChange={handleChange}
              required>
              <option
                className="select-option"
                defaultValue
                value={""}>
                Escoga un proyecto:
              </option>
              {
                listProjectDonate?.length !== 0
                  ? (
                    listProjectDonate.map((data, key) => {
                      return (
                        <>

                          {
                            data?.idProject === id
                              ? (
                                <option
                                  key={`${key}-${data?.idProject}`}
                                  className="select-option"
                                  value={data?.idProject}>
                                  Previamente elegido - {data?.title}
                                </option>
                              )
                              : (
                                <option
                                  key={`${key}-${data?.idProject}`}
                                  className="select-option"
                                  value={data?.idProject}>
                                  {data?.title}
                                </option>
                              )
                          }
                        </>
                      )
                    })
                  )
                  : (false)
              }
            </select>
          </div>
        </div>
        <div className="CardFormStripe-inputs__option">
          <input
            required
            onChange={handleChange}
            type="number"
            name="amount"
            placeholder="Ingrese cantidad a donar" />
        </div>


      </div>
      <div className="CardFormStripe-checkout">
        <p><strong>Datos de la tarjeta</strong></p>
        <div className="card">
          <CardElement
            hidePostalCode={true}
            options={{
              style: {
                base: {
                  iconColor: '#77e',
                  color: '#2d2d2d',
                  fontWeight: '600',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  fontSize: '18px',
                  fontSmoothing: 'antialiased',
                  ':-webkit-autofill': {
                    color: '#2d2d2d',
                  },
                  '::placeholder': {
                    color: '#2d2d2d',
                  },
                },
                invalid: {
                  iconColor: '#FFC7EE',
                  color: '#2d2d2d',
                },
              },
            }}
          />
        </div>
        <div className="cards">
          <span>Aceptamos:</span>
          <img src={Cards} alt="" />
        </div>
        <div className="mt-2">
          <span>
            Si eres de México, <a
              href="https://buscacp.com/"
              target="_blank"
              rel="noopener noreferrer">
              mira aqui tu código postal
            </a>
          </span>
        </div>
      </div>


      <input type="submit" value="Donar" disabled={!stripe} />
      {loading && <IconLoading />}

      <MessageResponse
        result={result}
        setResult={setResult} />
      <Modals
        isOpen={isOpen}
        closeModal={closeModal}
        payment={true}
        text={result !== 'Hubo un error'
          ? 'Transacción exitosa'
          : 'Hubo un error'}>
        <div>
          <p><strong>{result}</strong></p>
        </div>
      </Modals>
    </form>
  );
};

const Form = () => {
  return (
    <div className="CardFormStripe-container">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  )
}
export default Form;