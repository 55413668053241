import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import Logo from '../../assets/images/design/logo.png';
import { fetchPost } from '../../helpers/helpCore';

const initialRecovery = {
  email: '',
  valueRecovery: '',
  valueRecovery2: '',
  code: ''
}

const initialCode = {
  code: false,
  ok: false
}


const RecoveryPass = () => {
  const [input, setInput] = useState(initialRecovery)
  const [recovery, setRecovery] = useState(undefined)
  const [code, setCode] = useState(initialCode)
  const [redirect, setRedirect] = useState(false);

  //envio de correo
  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.email !== '') {
      const { email } = input;
      fetchPost({ email, url: '/recovery' })
        .then(res => {
          //localStorage.setItem('recovery', res)
          setRecovery(res?.ok ? res.ok : null)
          alert(res?.msg);

        })

    }
  }

  //envio de codigo, y validacion
  const handleSubmitCode = (e) => {
    e.preventDefault()
    const { code, email } = input;
    fetchPost({ code, email, url: '/recovery/code-validation' })
      .then(res => {
        setCode(res)
        alert(res?.msg);

      })
  }

  //envio de contraseña para modificar
  const handleSubmitPass = (e) => {
    e.preventDefault();

    if (input.valueRecovery !== '' && input.valueRecovery2 !== '') {

      if (input.valueRecovery !== input.valueRecovery2) {
        alert('La contraseña debe ser igual')
      } else {
        const { valueRecovery, valueRecovery2, email } = input;

        fetchPost({ valueRecovery2, valueRecovery, email, url: '/recovery-pass', method: "PUT" })
          .then(res => {
            if (res?.msg) {
              alert(res?.msg)
              setRedirect(res?.ok)

            }
          })
      }

    }
  }


  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="container auth">
      {redirect === true ? (<Redirect to="/auth/signin" />) : (false)}

      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 mt-3">



          {!recovery
            ? (
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body">
                    <div className="text-center">
                      <img src={Logo} alt="" className="img-fluid p-5" />
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={input.email}
                          onChange={handleChange}
                          placeholder="Ingrese el email"
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3 text-center">

                      <div className="col">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="Recuperar contraseña"
                        />
                      </div>


                    </div>
                  </div>
                </div>
              </form>
            )
            : (
              <div className="card row mb-3 text-center">
                <div className="text-center">
                  <img src={Logo} alt="" className="img-fluid p-5" />
                </div>
                {
                  //valida si el usuario ya valido el codigo
                  code.code === true
                    ? (
                      <div>
                        <h3>
                          Ingrese su nueva contraseña
                        </h3>
                        <span>
                          Asegurese que ambas coincidan
                        </span>
                        <div className="p-5">
                          <form onSubmit={handleSubmitPass}>
                            <input
                              type="text"
                              minLength="6"
                              name="valueRecovery"
                              value={input.valueRecovery}
                              onChange={handleChange}
                              placeholder="Ingrese su nueva contraseña"
                              className="form-control mt-2"
                              required />

                            <input
                              type="text"
                              minLength="6"
                              name="valueRecovery2"
                              value={input.valueRecovery2}
                              onChange={handleChange}
                              placeholder="Ingrese su nueva contraseña nuevamente"
                              className="form-control mt-2"
                              required />


                            <input
                              type="submit"
                              value="Cambiar contraseña"
                              className="btn btn-primary mt-3" />
                          </form>

                        </div>
                      </div>
                    )
                    : (
                      <div>
                        <h3>
                          Valida el código
                        </h3>
                        <span>
                          Revise su bandeja del correo, e ingrese el código enviado
                        </span>
                        <div className="p-5">
                          <form onSubmit={handleSubmitCode}>
                            <input
                              type="text"
                              minLength="8"
                              name="code"
                              value={input.code}
                              onChange={handleChange}
                              placeholder="Ingrese codigo de recuperacion"
                              className="form-control"
                              required />

                            <input
                              type="submit"
                              value="Enviar"
                              className="btn btn-primary mt-3" />
                          </form>

                        </div>
                      </div>
                    )
                }

              </div>)}
        </div>

      </div>
    </div>
  )
}

export default RecoveryPass