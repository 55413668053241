import React from 'react';
import { Link } from 'react-router-dom'

const Projects = ({
  title, image, amount, id, accumulated, raised
}) => {
  return (
    <div className='Project-card'>
      <div className="Project-card__box">
        <h4 className='Project-card__title' title='Haz click'>
          <Link to={`/proyectos/ver-proyecto/${id}`}>
            {title}
          </Link>
        </h4>

        <div className='Project-card__amount'>
          <p><strong>Se requiere</strong>  ${amount}</p>
          <p><strong>Colectados:</strong> ${accumulated}</p>
          <p><strong>Faltante:</strong> ${raised}</p>
        </div>
      </div>

      <div className='Project-card__image'>
        <img src={image} alt="imagen del proyecto" loading="lazy" />
      </div>
      <div className='Project-card__button' title='Haz click'>
        <Link to={`/proyectos/checkout/${id}`} className="text-uppercase">
          Dona aquí
        </Link>

      </div>

    </div>
  )
}

export default Projects;