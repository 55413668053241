import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import '../styles/Modals.css'
import { Link } from 'react-router-dom'
import parejas1 from '../../assets/images/design/parejas1.jpg'
import parejas2 from '../../assets/images/design/parejas2.jpg'
import parejas3 from '../../assets/images/design/parejas3.jpg'

const ModalSignup = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(show => !show)
    }, 1000);
  }, [])


  return (
    <>
      {/* <button variant="primary" onClick={() => setShow(true)}>
        Custom Width Modal
      </button> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w "
        centered={true}
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="modalBody-images">
              <img src={parejas1} alt="Images the modal" loading="lazy" />
              <img src={parejas2} alt="Images the modal" loading="lazy" />
              <img src={parejas3} alt="Images the modal" loading="lazy" />
            </div>
            <div className="modalBody-content">
              <h4>
                ¡REGÍSTRATE AHORA!
              </h4>
              <h3>
                SIN COSTO
              </h3>
              <h5>
                <Link to={'/auth/signup'}>
                SOLO HAZ CLICK AQUÍ Y EMPIEZA <br />
                A LLENAR LOS DATOS DE TU PERFIL
                </Link>
              </h5>
              <p>Conoce nuevas personas con tus mismos gustos e intereses </p>
              <p>Haz amistades y participa en proyectos interesantes con ellos</p>
            </div>
          </div>
          <div className="modalFooter">
            <h1>
            ¡FORMA PARTE DE LA NUEVA COMUNIDAD ME2YOU!
            </h1>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalSignup;