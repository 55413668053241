import React from 'react';
import BannerAnim from 'rc-banner-anim';
import QueueAnim from 'rc-queue-anim';
import '../../assets/common.css';

const { animType, setAnimCompToTagComp } = BannerAnim;
/*AQUI EL CUSTOM*/

animType.custom = (elem, type, direction, animData) => {
  //console.log(`custom animType, type:${type}`); // eslint-disable-line no-console
  let _y;
  const props = { ...elem.props };
  let children = props.children;
  //console.log(props);
  if (type === 'enter') {
    _y = direction === 'next' ? '100%' : '-100%';
  } else {
    _y = direction === 'next' ? '-10%' : '10%';
    children = React.Children.toArray(children).map(setAnimCompToTagComp);
  }
  return React.cloneElement(
    elem,
    {
      ...props,
      animation: {
        ...animData,
        y: _y,
        type: type === 'enter' ? 'from' : 'to',
      },
    },
    children
  );
};

const { Element } = BannerAnim;
const BgElement = Element.BgElement;

const BannerAnimado = (props) => {
  return (
    <section className={props.data.clases}>
      <BannerAnim type="custom">
        {props.data.map((row, key) => {
          return (
            <Element key={key} prefixCls="banner-user-elem">
              <BgElement key="bg" className="bg" />
              {row.label !== undefined && row.label !== '' ? (
                <QueueAnim name="QueueAnim">
                  <div className="row">
                    <div className="col-1 p-1"></div>
                    <div className="col-2 text-right pt-5 mt-4">
                      <img
                        src={row.src}
                        className="img-fluid rounded-circle border bg-white sombra-2x"
                        alt="Programandoweb"
                      />
                    </div>
                    <div className="col-9 pt-5 mt-5">
                      <p key="p">{row.label}</p>
                    </div>
                  </div>
                </QueueAnim>
              ) : (
                false
              )}
            </Element>
          );
        })}
      </BannerAnim>
    </section>
  );
};

export default BannerAnimado;
