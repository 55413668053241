import React, { useState } from 'react'
// import Input from '../../screens/inputs';
import Input from '../components/Input'
import Banner from '../../assets/images/design/banner1.jpg';
import { fetchPost } from '../../helpers/helpCore'
import MessageResponse from '../components/MessageResponse'
import '../styles/Report.css'


const initialForm = {
  nombre: '',
  asunto: '',
  contenido: '',
}

const Contact = () => {
  const [inputs, setInputs] = useState(initialForm);
  const [result, setResult] = useState('')
  const usuario = localStorage.getItem('email')
  const url = '/contacts'

  const onSubmit = (e) => {
    e.preventDefault()

    fetchPost({
      nombre: inputs.nombre,
      asunto: inputs.asunto,
      contenido: inputs.contenido,
      usuario: usuario ? usuario : 'Sin sesión iniciada',
      url
    })
      .then(res => {
        setInputs(initialForm)
        setResult(res?.msg)

      })
  }

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className="main">
      <form onSubmit={onSubmit}>
        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body report-box">
                  <div className="row ">
                    <div className="col report-box__img">
                      <img className="img-fluid" src={Banner} alt="Pgrw" />
                    </div>
                    <div className="col">
                      <h3 className="text-center pb-4">Contáctanos</h3>
                      <div>
                        <Input
                          // defaultValue={''}
                          type='email'
                          required={true}
                          onChange={onChange}
                          className="form-control"
                          name="nombre"
                          value={inputs.nombre}
                          placeholder="Ingrese su correo"
                          maxLength={200}
                        // onChange={onChange}
                        />
                      </div>
                      <div>
                        <Input
                          required={true}
                          onChange={onChange}
                          className="form-control"
                          name="asunto"
                          value={inputs.asunto}
                          placeholder="Asunto"
                          maxLength={300}
                          
                        />
                      </div>
                      <div>
                        <textarea
                          name="contenido"
                          value={inputs.contenido}
                          className="form-control"
                          onChange={onChange}
                          placeholder="¿Qué nos quieres comentar?"
                          required
                          maxLength={500}
                        />
                      </div>
                      <div className="mt-2">
                        <input type="submit" value="Enviar" className="btn btn-primary2" />
                      </div>
                    </div>

                  </div>
                </div>

                <MessageResponse
                  result={result}
                  setResult={setResult} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

}

export default Contact;