import React, { useState, useEffect } from 'react';
import { fetchPost } from '../../helpers/helpCore';
import useAuth from '../../hooks/useAuth';

import '../styles/Admi.css'

const AdminContact = () => {
  const [contact, setContact] = useState([])
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()
  
  useEffect(() => {
    fetchPost({ token, url: '/contacts/admin' })
      .then(response => {
        setContact(response ? response : [])
      })
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchPost({ token, url: '/admin/validateAdmin' })
      .then(res => {
        if (res === false) {
          logout()
        }
      })
      // eslint-disable-next-line
  }, [])


  return (
    <div className="Admin-container">
      <div className='Admin-title'>{/*opciones: ver reportes, ver contactos, ver perfiles*/}
        {/* <h2>Hola!, {name}</h2> */}
      </div>
      <div className="Admi-box">
        <h3>Mensajes de Contacto</h3>
        {/* {console.log(contact)} */}
        <div className="Admin-contact">
          {
            contact.length > 0
              ? (
                contact.map((data) => (
                  <div
                    key={data._id}
                    className="Admin--contact__small"
                  >
                    <p><strong>Nombre</strong>: {data.nombre}</p>
                    <p><strong>Asunto</strong>: {data.asunto}</p>
                    <p><strong>Email (si tenia sesion iniciada)</strong>: {data.usuario}</p>
                    <p><strong>Contenido</strong>: {data.contenido}</p>
                  </div>
                ))
              )
              : (
                <p>Cargando...</p>
              )
          }
        </div>

      </div>
    </div>
  )
}

export default AdminContact;