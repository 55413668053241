import React from 'react';
import '../styles/IconLoading.css'

const IconLoading = () => {
  return (
    <div className="IconLoading">
      <div className="lds-ring">
        <div>
          </div>
            <div>
              </div><div>
            </div>
          <div>
        </div>
      </div>

    </div>
  )
}

export default IconLoading;