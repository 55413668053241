import React from 'react'
import Banner from '../../assets/images/design/img-final-proceso-realizado.jpg';

const Support = () => {
  return (
    <div className={'main'}>
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">Bienvenido</h3>
          <div className="text-center">
            <p>
              a ME2U, tu perfil se ha creado con éxito.
              <br />
              Estamos seguros que te divertirás y<br />
              encontrarás personas afines a ti.
              <br />
            </p>
            <div className="mt-2">
              <b>¡Con suerte hasta tu pareja ideal!</b>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-0">
            <img src={Banner} alt="programandoweb" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );}

export default Support;