import { useMemo, useState } from 'react';

export default function useSearchFilter(data, valueSearch) {
  const [query, setQuery] = useState('');
  const [filterData, setFilterData] = useState(data);

  useMemo(() => {
    if (valueSearch === 'name') {
      const result = data.filter((data) => {
        return `${data.name.toLowerCase()}`.includes(query.toLowerCase());
      });
      setFilterData(result);

    }

    if (valueSearch === 'goals') {
      const result = data?.filter((data) => {
        return `${data.sueños}`.includes(query.toLowerCase());
      });
      setFilterData(result);
    }
    if (valueSearch === 'projects') {
      const result = data?.filter((data) => {
        return `${data.proyectos}`.includes(query.toLowerCase());
      });
      setFilterData(result);
    }
    if (valueSearch === 'propurse') {
      const result = data?.filter((data) => {
        return `${data.propositos}`.includes(query.toLowerCase());
      });
      setFilterData(result);
    }
    // eslint-disable-next-line
  }, [data, query]);




  // console.log(filterData)
  return { query, setQuery, filterData };
}

