import React from 'react';
import { Link } from 'react-router-dom'
import useSearchFilter from '../../hooks/useSearchFilter';
import AvatarDefault from '../../assets/images/design/avatar.png';

import '../styles/SearchBarBox.css';

export default function SearchBar(props) {
  const { data, handleClick, valueSearch } = props
  const id = localStorage.getItem('uid')
  const { query, setQuery, filterData } = useSearchFilter(data, valueSearch);
  return (
    <div>
      <input
        // className="SearchBar-box"
        className={` SearchBar-box 
        ${valueSearch !== 'name' ? `premiere` : ''}`
        }
        key="input-search"
        placeholder=" Buscar"
        autoComplete="off"
        list="searchBar"
        target="__blank"
        type="search"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        maxLength="30"
      />
      <div className="Result-search">
        {
          // eslint-disable-next-line
          filterData?.map((data, key) => {
            if (query.length !== 0) {

              if (id !== data.uid) {
                if (valueSearch === 'goals' || valueSearch === 'projects' || valueSearch === 'propurse') {
                  // console.log(data)
                  return (
                    <Link
                      to={{
                        pathname: `/web/perfil/${data.uid}`,
                        state: { data },
                      }}>
                      <div
                        className="search_premiere"
                        key={`result_search_${key}_${data.uid}`}>

                        <img src={
                          data.img ? data.img : AvatarDefault
                        } alt="Foto de perfil" />
                        <p>
                          <strong>{' '}{data.name}</strong> <br />
                        </p>
                      </div>
                    </Link>

                  )
                }
                if (handleClick) {

                 if(id.toString() !== data.uid.toString()){
                  return (
                    <li
                      className="Result-option"
                      key={`result_search_${key}_${data.uid}`}
                      onClick={() => handleClick(id === data.uid ? '' : data)}
                    >
                      {' '}{data.name}{' '}{data.lastname}
                    </li>
                  )
                 }
                } else {
                  return (
                    <li
                      className="Result-option"
                      key={data.uid}
                    // onClick={ () => handleData(data)}
                    >
                      <Link
                        to={{
                          pathname: `/web/perfil/${data.uid}`,
                          state: { data },
                        }}
                      >
                        {' '} {data.name}{' '}{data.lastname}
                      </Link>
                    </li>
                  );
                }

              }
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
}