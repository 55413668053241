import React, { useState, useEffect } from 'react';
import Banner from '../../assets/images/design/img-proceso-de-registro-4.jpg';
import Input from '../components/Input';
import { Redirect } from 'react-router-dom'
import { fetchPost } from '../../helpers/helpCore'
import '../styles/Premier.css'
import useAuth from '../../hooks/useAuth';
import MessageResponse from '../components/MessageResponse';


const initialState = {
  complexion: '',
  religion: '',
  rangoEdad1: null,
  rangoEdad2: null,
  hijos: '',
  estadoCivil: '',
  areaPropositos: '',
  areaProyectos: '',
  areaSueños: '',
  tituloPropositos: '',
  tituloProyectos: '',
  tituloSueños: '',
  descripcionPropositos: '',
  descripcionProyectos: '',
  descripcionSueños: '',
  piensasFuturo: '',
  aspectoImportante: '',
  shareGoals: '',

}

const FormPremier = () => {
  const [inputs, setInputs] = useState(initialState)
  const [result, setResult] = useState('')
  const [redirect, setRedirect] = useState(true)
  const [redirectFormComplete, setRedirectFormComplete] = useState(true)
  const [load, setLoad] = useState(false)
  const [goals, setGoals] = useState([])
  const [projets, setProjets] = useState([])
  const [purpose, setPurpose] = useState([])
  const token = localStorage.getItem('jwt')
  const usuario = localStorage.getItem('uid')
  const { logout } = useAuth()

  useEffect(() => {
    const token = localStorage.getItem('jwt')
    fetchPost({ token, url: '/premier/validatePremiere' })
      .then(res => {
        if (res === false) {
          console.log('exit session')
          logout();
        } else {
          if (res?.validate === false) {
            setRedirect(res?.validate)
          }
          if (res?.msg) {
            setResult(res?.msg);
          }
        }
        setLoad(true);
      })
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value

    })
  }

  const addGoals = () => {
    if (
      inputs.areaSueños === "" ||
      inputs.tituloSueños === "" ||
      inputs.descripcionSueños === "") {
      return setResult('Llena todos los datos del apartado de sueños')
    }

    if (goals.length !== 3) {
      const sueños = [inputs.tituloSueños, inputs.areaSueños, inputs.descripcionSueños]

      setGoals([
        ...goals,
        sueños]
      )

      setInputs({
        areaSueños: '',
        tituloSueños: '',
        descripcionSueños: ''
      })
      // setResult('Datos guardados')
    } else {
      setResult('Solo puede llenar este campo 3 veces')
    }
  }

  const addPurpose = () => {
    if (
      inputs.areaPropositos === "" ||
      inputs.tituloPropositos === "" ||
      inputs.descripcionPropositos === "") {
      return setResult('Llene todos los datos del apartado de Propositos')
    }

    if (purpose.length !== 3) {

      const propositos = [inputs.tituloPropositos, inputs.areaPropositos, inputs.descripcionPropositos]

      setPurpose([
        ...purpose,
        propositos
      ])

      setInputs({
        areaPropositos: '',
        tituloPropositos: '',
        descripcionPropositos: ''
      })
    } else {
      setResult('Solo puede llenar este campo 3 veces')
    }
  }

  const addProjects = () => {
    if (
      inputs.areaProyectos === "" ||
      inputs.tituloProyectos === "" ||
      inputs.descripcionProyectos === "") {
      return setResult('Llene todos los datos del apartado de Proyectos')
    }

    if (projets.length !== 3) {
      const projectos = [inputs.tituloProyectos, inputs.areaProyectos, inputs.descripcionProyectos]

      setProjets([
        ...projets,
        projectos
      ])

      setInputs({
        areaProyectos: '',
        tituloProyectos: '',
        descripcionProyectos: ''
      })

    } else {
      setResult('Solo puede llenar este campo 3 veces')
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const { areaPropositos, areaProyectos, areaSueños,
      descripcionPropositos, descripcionProyectos, descripcionSueños,
      // eslint-disable-next-line
      tituloPropositos, tituloSueños, ...rest } = inputs

    const data = {
      sueños: goals,
      propuestas: purpose,
      proyectos: projets,
      piensasFuturo: inputs.piensasFuturo,
      hijos: inputs.hijos,
      estadoCivil: inputs.estadoCivil,
      aspectoImportante: inputs.aspectoImportante,
      complexion: inputs.complexion,
      religion: inputs.religion,
      edad1: inputs.rangoEdad1,
      edad2: inputs.rangoEdad2,
      usuario: usuario,
    }

    fetchPost({ data, url: '/premier/formPremiere', token })
      .then(res => {
        setResult(res?.msg)
        if (res?.validate === true) {
          setRedirectFormComplete(!res?.redirectFormComplete)
        }
      })
  }


  return (
    <div className={'main bg-white'}>
      {redirect ? '' : <Redirect to="/premiere/form-premiere" />}
      {redirectFormComplete ? '' : <Redirect to={`/web/miperfil/${usuario}`} />}

      {/* Redireccionando en caso de no ser usuario premiere */}
      {/* {redirect ? (true) : (<Redirect to={"/"}/>) */}
      <>
        {
          load &&
          <>
            <section className="Banners position-relative">
              <div className="position-absolute position-absolute text-white w-100 pt-5 mt-5">
                <h3 className="text-center">¡Ya falta poco!</h3>
                <div className="text-center">
                  <p>Saber las cosas que te gustan es importante en M22U</p>
                </div>
              </div>
            </section>

            <div className="container-fluid bg-white">
              <div className="row">
                <div className="col p-0">
                  <img src={Banner} alt="programandoweb" className="img-fluid" loading="lazy" />
                </div>
              </div>
            </div>

            <form onSubmit={onSubmit}>
              <section>
                <div className="container mt-4 ">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      Ayúdanos a completar los siguientes datos
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="container mt-4">
                  <div className="row mb-3 form-premier">
                    <div className="col-12 col-sm-6">
                      <div>
                        <div className="h3">CUÉNTANOS TUS SUEÑOS</div>
                        <div className="row">
                          <div className="col-12 col-sm-6 ">
                            {/* form-goals */}
                            <input
                              // required
                              placeholder={'Título'}
                              type="text"
                              value={inputs.tituloSueños}
                              name="tituloSueños"
                              className="form-control form-control-border"
                              onChange={onChange}
                            // pattern="[A-Za-z]"
                            />
                          </div>
                          <div className="col-12 col-sm-6 form-goals">
                            <input
                              // required
                              placeholder={'Área (ej. Profesional, Familiar, etc)'}
                              type="text"
                              value={inputs.areaSueños}
                              name="areaSueños"
                              className="form-control form-control-border"
                              onChange={onChange}
                            // pattern="[A-Za-z]"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 description-form">
                            <textarea
                              // type="text" 
                              placeholder='Descripción hasta 500 caracteres'
                              className="form-control form-control-border"
                              value={inputs.descripcionSueños}
                              name="descripcionSueños"
                              maxLength="500"
                              onChange={onChange}
                            // id="" 
                            // required
                            />
                          </div>
                        </div>
                        <div className="row">



                          <div className="col-12 col-sm-4 text-right">
                            <input
                              type="button"
                              className="btn btn-primary2"
                              value="Añadir sueño +"
                              onClick={() => addGoals()}
                            />


                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="h3">CUÉNTANOS TUS PROPÓSITOS</div>
                        <div className="row pb-2">
                          <div className="col-12 col-sm-6">
                            <input
                              // required
                              placeholder={'Título'}
                              type="text"
                              value={inputs.tituloPropositos}
                              name="tituloPropositos"
                              className="form-control form-control-border"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <input
                              required={false}
                              placeholder={'Área (ej. Profesional, Familiar, etc)'}
                              type="text"
                              value={inputs.areaPropositos}
                              name="areaPropositos"
                              className="form-control form-control-border"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 description-form">
                            <textarea
                              // type="text" 
                              placeholder='Descripción hasta 500 caracteres'
                              className="form-control form-control-border"
                              value={inputs.descripcionPropositos}
                              name="descripcionPropositos"
                              maxLength="500"
                              onChange={onChange}
                            // id="" 
                            // required
                            />
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-12 col-sm-4 text-right">
                            <input
                              type="button"
                              className="btn btn-primary2"
                              value="Añadir Propósito +"
                              onClick={() => addPurpose()} />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="h3">CUÉNTANOS TUS PROYECTOS</div>
                        <div className="row pb-2">
                          <div className="col-12 col-sm-6">
                            <input
                              required={false}
                              placeholder={'Título'}
                              type="text"
                              value={inputs.tituloProyectos}
                              name="tituloProyectos"
                              className="form-control form-control-border"
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <input
                              required={false}
                              placeholder={'Área (ej. Profesional, Familiar, etc)'}
                              type="text"
                              value={inputs.areaProyectos}
                              name="areaProyectos"
                              className="form-control form-control-border "
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 description-form">
                            <textarea
                              // type="text" 
                              placeholder='Descripción hasta 500 caracteres'
                              className="form-control"
                              value={inputs.descripcionProyectos}
                              name="descripcionProyectos"
                              maxLength="500"
                              onChange={onChange}
                            // id="" 
                            // required
                            />
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-12 col-sm-4 text-right">
                            <input
                              type="button"
                              className="btn btn-primary2"
                              value="Añadir Proyecto +"
                              onClick={() => addProjects()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div>
                        <div>
                          <h3>¿Qué piensas del futuro?</h3>
                          <Input
                            required={false}
                            placeholder={'¿Qué piensas del futuro?'}
                            type="text"
                            name="piensasFuturo"
                            value={inputs.piensasFuturo}
                            className="form-control form-control-border"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="h3">Dinos cómo sería tu pareja ideal</div>
                        <div >
                          <div className="row">
                            <div className="col-12 col-sm-4">
                              <p>Rango de edad</p>
                              <div className="Rango-edad__box">
                                <input
                                  clasName="form-control form-control-border"
                                  type="number"
                                  name={'rangoEdad1'}
                                  value={inputs.rangoEdad1}
                                  min='18'
                                  max='80'
                                  onChange={onChange}
                                  placeholder="Min." />
                                <input
                                  clasName="form-control form-control-border "
                                  type="number"
                                  name={'rangoEdad2'}
                                  value={inputs.rangoEdad2}
                                  min='18'
                                  max='80'
                                  onChange={onChange}
                                  placeholder="Max." />
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p>Complexión</p>
                              <select
                                name="complexion"
                                value={inputs.complexion}
                                className="form-control form-control-border"
                                onChange={onChange}
                                required
                              >
                                <option selected value="">Complexión </option>
                                <option value="Delgado/a">Delgado/a</option>
                                <option value="Atletico/a">Atletico/a</option>
                                <option value="Robusto/a">Robusto/a</option>
                                <option value="Normal">Normal</option>
                              </select>
                            </div>
                            <div className="col-12 col-sm-4">
                              <p>Religión</p>
                              <select
                                name="religion"
                                className="form-control form-control-border"
                                value={inputs.religion}
                                onChange={onChange}
                                required
                              >
                                <option selected value="">Religiones </option>
                                <option value="Catolicismo">Catolicismo</option>
                                <option value="Scientology">Scientology</option>
                                <option value="Judaísmo">Judaísmo</option>
                                <option value="Hinduismo">Hinduismo</option>
                                <option value="Mormones">Mormones</option>
                                <option value="Protestante">Protestante</option>
                                <option value="Budismo">Budismo</option>
                                <option value="Iglesia Nativa Polaca">
                                  Iglesia Nativa Polaca
                                </option>
                                <option value="Espiritual">Espiritual</option>
                                <option value="Ateo">Ateo</option>
                                <option value="Hinduismo">Otra</option>
                              </select>
                            </div>
                            <div className="col-12 mt-3">
                              <select
                                name="hijos"
                                value={inputs.hijos}
                                className="form-control form-control-border"
                                onChange={onChange}
                                required
                              >
                                <option selected value="">¿Está bien si tu prospecto tiene hijos? </option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <div className="col-12 col-sm-12 pt-3">
                              <select
                                name="estadoCivil"
                                value={inputs.estadoCivil}
                                className="form-control form-control-border"
                                onChange={onChange}
                                required
                              >
                                <option selected value="">Estado Civil de tu pareja ideal</option>
                                <option value="Delgado/a">Soltero/a</option>
                                <option value="Atletico/a">Divorciado/a</option>
                                <option value="Robusto/a">Casado/a</option>
                                <option value="Normal">Viudo/a</option>
                              </select>
                            </div>
                            <div className="col-12 pt-3 description-form">
                              <textarea
                                // type="text" 
                                placeholder='Descripción aspectos importantes de tu pareja ideal'
                                className="form-control form-control-border"
                                value={inputs.aspectoImportante}
                                name="aspectoImportante"
                                maxLength="500"
                                onChange={onChange}
                              // id="" 
                              />
                            </div>

                            <div className="col text-right">

                              <div className="col-12 mb-4 text-right">
                                <input
                                  type="submit"
                                  className="btn btn-primary2"
                                  value="Guardar y Continuar"
                                  onSubmit={onSubmit} />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <MessageResponse
                        result={result}
                        setResult={setResult}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </>
        }
      </>
    </div>


  )
}

export default FormPremier;