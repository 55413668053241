import React, { useState } from 'react'
import photoUser from '../../assets/images/design/avatar.png';
import { fetchPost } from '../../helpers/helpCore';
import { Link } from 'react-router-dom'


const WinkCard = ({ data, winkDel, setWinkDel, result, setResult }) => {
  const [option, setOption] = useState(false)
  const uid = localStorage.getItem('uid')

  const handleClick = (e) => {
    setOption(!option)
  }

  const handleDelete = () => {
    setWinkDel(!winkDel)
    setOption(false)
    //user quien hizo el guiño
    //otheruser quien recibe el guiño
    fetchPost({user: data.uid, otherUser: uid, url:'/premier/winkDelete', method: "DELETE"})
      .then(response => {
        if (response?.msg) {
          // alert(response?.msg)
          setResult(response?.msg)
        }
      })
  }

  return (
    <div className="Wink-card" >
      <div className="Wink-card__photo">
        {/* eslint-disable-next-line */}
        <img src={
          
          data?.img 
          ? data?.img 
          : photoUser} 
          loading="lazy"/>
      </div>
      <div className="Wink-card__info"> 
        <p>
          {/* <strong>{'  '}{data.name ? data.name : 'Sin definir'} </strong> */}
          {/* <strong>{'  '}{data.name ? data.name : 'Sin definir'} </strong> */}
          {' '}¡Haz recibido un guiño! mira quíen es{'  '}
        </p>

        <div className="Wink-card__info__button">
          <i onClick={(e) => handleClick(e)}>•••</i>
          {option === true
            ? (
              <div className="Wink-card__option">
                <input
                  type="button"
                  value="Eliminar"
                  onClick={handleDelete} />

                <Link  to={{
                  pathname: `/web/perfil/${data.uid}`,
                  state: {
                    data
                  }
                }
                }>
                  Ver perfil
                </Link>
              </div>
            )
            : (false)
          }
        </div>

      </div>
    </div>
  )
}

export default WinkCard;