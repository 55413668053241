import React, { useState } from 'react';

import '../styles/HomePrincipal.css'
import BannerPrincipalLogin from './BannerPrincipalLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import BannerPrincipalHelp from './BannerPrincipalHelp';
import BannerPrincipalProject from './BannerPrincipalProject';
import { useEffect } from 'react';
import { fetchPost } from '../../helpers/helpCore';


const BannerPrincipal = () => {
  const [image, setImage] = useState(0) 
  const [bannerImage , setBannerImage] = useState()
  const [intervalStyle, setIntervalStyle] = useState('')
  //const timeInterval = 7000;
  const [timeInterval , setTimeInterval] = useState(12000);
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    fetchPost({token, url:'/admin/viewSlider'})
    .then( res => {
      setBannerImage(res)
    })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      selectNewImage(image)
    }, timeInterval);
    setIntervalStyle('')

    return () => clearInterval(interval);
    
  }, [image])



  const selectNewImage = (number) => {
    setTimeout(() => {
      const nextIndex = number === 0 || image === 1 ? (number + 1) : (number - 2)
      // const styleBanner = number === 0 || image === 1 ? 'move-right' : ''
      setIntervalStyle('move-right')
      setImage(nextIndex);
      setTimeInterval(7000);
    }, timeInterval);
  }

  const handleButtonLater = () => {
    if (image >= 1) {
      setIntervalStyle('move-left')
      setImage(image - 1)
    }
  }

  const handleButtonNext = () => {
    if (image <= 1) {
      setIntervalStyle('move-right')
      setImage(image + 1)
    }
  }



  return (
    <div className={`BannerPrincipal`}>
      {
        image === 0
          ? (<BannerPrincipalLogin
            intervalStyle={intervalStyle}
            bannerImage={bannerImage?.principal}
            setTimeInterval={setTimeInterval} />)
          : (false)
      }
      {
        image === 1
          ? (<BannerPrincipalHelp
            intervalStyle={intervalStyle}
            bannerImage={bannerImage?.help} />)
          : (false)
      }
      {
        image === 2
          ? (
            <BannerPrincipalProject
              intervalStyle={intervalStyle}
              bannerImage={bannerImage?.project} />
          )
          : (false)
      }



      <div className="BannerPrincipalButtons">
        <div className="BannerButton BannerButtonLeft">
          <FontAwesomeIcon
            size="2x"
            className="icons__menu"
            icon={faChevronLeft}
            onClick={handleButtonLater}
          />
        </div>
        <div className="BannerButton BannerButtonRight">
          <FontAwesomeIcon
            size="2x"
            className="icons__menu"
            icon={faChevronRight}
            onClick={handleButtonNext}
          />
        </div>
      </div>
    </div>


  );
}

export default BannerPrincipal;


