import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { fetchPost } from '../../helpers/helpCore';
import CreatePost from '../components/CreatePost'
import IconLoading from '../components/IconLoading';

import '../styles/Post.css'

const EditPostUser = () => {
  const token = localStorage.getItem('jwt');
  const [postNew, setPostNew] = useState({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const { id } = useParams();

  useEffect(() => {
    setLoading(true)
    fetchPost({
      url: '/post/view-post/uniqued',
      token,
      idPost: id
    })
      .then(res => {
        setData(res)
        setLoading(false)
      })
  }, [])

  return (
    <div className='EditPostUser'>
      <div className='EditPostUser-container' >
        <h4>Editar</h4>
        {
          loading === false &&
          <CreatePost
            setPostNew={setPostNew}
            imageEdit={data?.img}
            textPost={data}
            urlEdit={'/post/edit-post/uniqued'}
            idPost={id}

          />
        }
        {loading && <IconLoading />}
      </div>
    </div>
  )
}

export default EditPostUser