import React from 'react';

// import '../styles/components/Modals.css';
import '../styles/Modals.css'

import Modal from './Modal';

const Modals = ({
  children,
  isOpen,
  closeModal,
  payment,
  text
}) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      payment={payment}
      text={text}
    >
      <div className="modal-box">{children}</div>
    </Modal>
  );
};

export default Modals;