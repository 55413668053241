import React from 'react'

import Banner from '../../assets/images/design/ayuda.jpeg'

const BannerPrincipalHelp = ({intervalStyle, bannerImage}) => {
  return(
    <div className={`BannerPrincipalHelp bannerAutoplay ${intervalStyle}`}>
      <img src={bannerImage ? bannerImage : Banner} alt="Fondo de ayuda" />
      <div>
        <h3>
        ¿Con qué necesitas ayuda? <br /> 
        O bien, ¡Brinda tu Ayuda a Alguien! 
        </h3>
      </div>
    </div>
  )
}

export default BannerPrincipalHelp;