import React from 'react';


const BoxMessageSend = ({ 
    handleSubmit, 
    input, 
    setInput,
  classN}) => {
  // const [input, setInput] = useState(initalMessage)

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className={classN}>

      <form onSubmit={handleSubmit}>
        <textarea
          type="text"
          maxLength="500"
          value={input?.msg}
          name="msg"
          onChange={handleChange}
          placeholder="Enviale un mensaje..."
        />
        <input
          type="submit"
          value="Enviar"
          className="btn btn-primary2" />
      </form>

    </div>
  )

}

export default BoxMessageSend;