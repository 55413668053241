import React,{useState} from "react";
import recortName from '../../helpers/recortName'
import { Link } from 'react-router-dom'
import { fetchPost } from '../../helpers/helpCore'


const AdminHiddenProjectCard  = ({data, token, setResult, setStatus}) => {
  const [valueButton, setValueButton] = useState(false)

  const handleClick = () => {
    setValueButton(!valueButton)
  }

  const deleteProject = (id) => {
    const idUrl = id;
    fetchPost({ 
      token, 
      url: '/admin/hiddenProject', 
      idUrl, 
      method: "DELETE" })
      .then(res => {
        setResult(res?.msg)
        setStatus(true)
        setValueButton(!valueButton)
      })
  }


  return(
    <div className="Admin-ProfileHidden">
    <div className='Admin-hiddenProfile'>
      <div className='Admin-hiddenProfile__header'>
        <span>
          <strong>Estado actual:</strong> {data.status ? 'Activo' : 'Ocultado'}
        </span>
        <i onClick={(e) => handleClick()}>
          •••
        </i>
        <div
          className={`Admin-hiddenProfile__headerOption 
          ${valueButton ? 'active' : ''}`}
          onClick={() => deleteProject(data?._id)}>
          <span>
            {
              data?.status
                ? 'Ocultar'
                : 'Mostrar'
            }
          </span>
        </div>
      </div>
      <div className="Admin-hiddenProfile__title">
        <h4>{data?.titleProject}</h4>
      </div>

      <div className="Admin-hiddenProfile__text">
        <p>
          {
            recortName(data?.textProject, 180)
          }
        </p>
      </div>

      <div className="Admin-hiddenProfile__link">
        <Link to={`/proyectos/ver-proyecto/${data?._id}`}>
          Ver proyecto
        </Link>
      </div>

    </div>

  </div>
  )
}

export default AdminHiddenProjectCard 