import React, { useState, useEffect } from 'react'
import { fetchPost } from '../../helpers/helpCore';
import SearchBar from './SearchBar';

import '../styles/WebBuscar.css'

const SearchBoxPremiere = () => {
  const [data, setData] = useState(false);
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    fetchPost({ token , url:'/premier/searchPremiere'})
      .then(response => {
        setData(response)
      })
      // eslint-disable-next-line
  }, [])

  return (
    <div className="SearchBoxPremiere"> 
      {
        data !== false
          ? (
            <div className="SearchBoxPremiere-premiere">
              <div className="SearchBoxPremiere-premiere__title">
                <h5><strong>BÚSQUEDA PREMIERE</strong></h5>
              </div>
              <div className="SearchBoxPremiere-premiere__goals">
                <strong><span>Sueños</span></strong>
                <SearchBar
                  valueSearch={`goals`}
                  data={data ? data : []}
                />
              </div>
              <div className="SearchBoxPremiere-premiere__projects">
                <strong><span>Proyectos</span></strong>
                <SearchBar
                  valueSearch={`projects`}
                  data={data ? data : []}
                />
              </div>
              <div className="SearchBoxPremiere-premiere__propurse">
                <strong><span>Propuestas</span></strong>
                <SearchBar
                  valueSearch={`propurse`}
                  data={data ? data : []}
                />
              </div>
            </div>
          )
          : (
            <div className="SearchBoxPremiere-notPremiere">
              <span>Búsqueda premiere  -  </span><p>  Cámbiate a premiere y accede a nuevas funcionalidades</p>
            </div>
          )
      }
    </div>
  )
}


export default SearchBoxPremiere;