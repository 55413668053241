import React, { Fragment } from 'react';
import { useState } from 'react';
import { API } from '../../helpers/helpCore'
import useAuth from '../../hooks/useAuth'
//import '../';

import axios from 'axios';
import banner1 from '../../assets/images/design/banner1.jpg';
import { Redirect, Link } from 'react-router-dom';
import MessageResponse from './MessageResponse';

const initialForm = {
  soy: '',
  busco: '',
  email: '',
  password: '',
};//inicializacion de datos


const BannerPrincipalLogin = ({intervalStyle, bannerImage, setTimeInterval}) => {

  const [inputs, setInputs] = useState(initialForm);
  const [result, setResult] = useState('')
  const [open, setOpen] = useState(false);
  const { isLogged } = useAuth();
  const [redirect, setRedirect] = useState(false);

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs);
    if (inputs.soy === '' || inputs.busco === '') {
      return setResult('Debe elegir una de las opciones de Soy, y Busco')
    }

   await axios.post(`${API}/signup`, {
      soy: inputs.soy,
      busco: inputs.busco,
      email: inputs.email,
      password: inputs.password,
    })
      .then((res) => {
        console.log(res);

        try {
          if (res.data.errors[0].msg) {
            setResult(res.data.errors[0].msg);
          }
        } catch (err) {
          setRedirect(!redirect)
          console.log(err)
        }

      })

    setInputs({
      ...inputs,
      initialForm
    })

  }

  const opening = () => {
    setOpen(!open)
  }


  return (
    <>
      {
        redirect ? (<Redirect to="/auth/signin" />) : false
      }
      <section className={`Banners position-relative bannerAutoplay ${intervalStyle}`}>
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">Encuentra tu pareja ideal</h3>
          <div className="text-center">
            <p>
              Inscribete gratis y disfruta de una vida, <br />
              con esa persona especial <br />
              ¡Que comience la Aventura!.
            </p>
          </div>
          <form onSubmit={onSubmit}  onClick={() => setTimeInterval(100000)}>
            <div className="row justify-content-center">
              <div className="signup">
                <div className="card-transparent signup-absolute__p d-sm-block">
                  {!isLogged ? (
                    <Fragment>
                      <div className="row ">
                        <div className="col text-dark">
                          Soy
                          <div >
                            <input
                              onChange={onChange}
                              name="soy"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Hombre"
                              required
                            />
                            Hombre
                          </div>
                          <div>
                            <input
                              onChange={onChange}
                              name="soy"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Mujer"
                              required
                            />
                            Mujer
                          </div>
                        </div>
                        <div className="col text-dark">
                          Busco
                          <div >
                            <input
                              onChange={onChange}
                              name="busco"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Hombre"
                            />
                            Hombre
                          </div>
                          <div>
                            <input
                              onChange={onChange}
                              name="busco"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Mujer"
                            />
                            Mujer
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    false
                  )}

                  <div className={!open ? 'row mt-3' : 'd-none'}>
                    <div className="col text-center">
                      {isLogged ? (
                        <>
                          <div
                          // onClick={opening2}
                          >
                            <Link
                              to='/web/buscar'
                              className="btn btn-primary2 btn-lg text-uppercase ">
                              Buscar Personas
                            </Link>

                          </div>
                        </>
                      ) : (
                        <Fragment>

                          <div
                            className="btn btn-primary2 btn-lg text-uppercase "
                            onClick={opening}
                          >
                            Inscríbete Ahora
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      open ? 'row justify-content-center mt-3' : 'd-none'
                    }
                  >
                    <div className=" text-dark text-center text-mini">
                      <input
                        placeholder="Correo electronico"
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={onChange}
                        required
                        value={inputs.email}
                      />
                    </div>
                  </div>
                  <div
                    className={open ? 'row justify-content-center mt-1' : 'd-none'}>
                    <div className=" text-dark text-center text-mini">
                      <input
                        placeholder="Contraseña"
                        type="password"
                        name="password"
                        className="form-control"
                        onChange={onChange}
                        required
                        value={inputs.password}
                      />
                    </div>
                  </div>
                  <div className={open ? 'row mt-3' : 'd-none'}>
                    <MessageResponse
                      result={result}
                      setResult={setResult}
                    />
                    <div className="col text-center">
                      <button
                        type="submit"
                        className="btn btn-primary2 btn-lg text-uppercase "
                      >
                        Registrarse
                      </button>
                    </div>
                  </div>
                  <div className={open ? 'row mt-3' : 'd-none'}>
                    <div className="col text-dark text-center text-mini">
                      Al registrarse confirmas que estás de acuerdo con los términos y condiciones
                      y declaras estar de acuerdo
                      con la política de privacidad de los datos personales
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <img src={bannerImage ? bannerImage : banner1} className="w-100" alt="Programandoweb" />
      </section>
    </>
  )
}

export default BannerPrincipalLogin;