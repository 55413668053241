import React from 'react';

import Banner from '../../assets/images/design/banner2.jpg';


const HelpMe = () => {
  return (
    <div className={'main'}>
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">ME2YOU</h3>
          <div className="text-center">
            <p>
              {/* <img src={Mercadopago} alt="Mercadopago" className="img-fluid"/> */}
            </p>
            <div className="mt-2">
              <b>Muy Pronto, actividades que te gustará compartir...</b>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-0">
            <img src={Banner} alt="programandoweb" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <div>
              {/* <img src={Mercadopago} alt="Mercadopago" className="img-fluid"/> */}
            </div>
            <div>
              <p></p>
              {/* <a className="btn btn-primary2 mb-3 btn-lg mr-2" href={Config.ConfigApirest+"Mercadopago/pagoMembresia?id="+queryStringParams.id+"&user="+Store.get("user").token}>
                      Pagar ahora por Mercado Pago
                    </a> */}
              {/* <a className="btn btn-primary2 mb-3 btn-lg mr-2" href={Config.ConfigApirest+"PayU/pagoMembresia?id="+queryStringParams.id+"&user="+Store.get("user").token}>
                      Pagar ahora por PayU
                    </a>
                    <a className="btn btn-primary mb-3 btn-lg">
                      Pagar ahora por PayPal
                    </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpMe;