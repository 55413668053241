import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import banner from '../../assets/images/design/banner2.jpg'
import { API, fetchPost } from '../../helpers/helpCore'
import useAuth from '../../hooks/useAuth'
import MessageResponse from '../components/MessageResponse';
import ItemProfile from '../components/ItemProfile';
import Mujer from '../../assets/images/design/mujer.jpg';
import Hombre from '../../assets/images/design/hombre.jpg';
import bannerFinal from '../../assets/images/design/banner-inferior-final.jpg'
import bannerFinalPerson from '../../assets/images/design/pareja-banner-inferior.png'
import ModalSignup from '../components/ModalSignup';

const initialForm = {
  soy: '',
  busco: '',
  email: '',
  password: '',
};//inicializacion de datos

const WebBuscarFree = () => {
  const [inputs, setInputs] = useState(initialForm);
  const [data, setData] = useState([]);
  const [result, setResult] = useState('')
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { isLogged } = useAuth();

  useEffect(() => {
    fetchPost({ url: '/buscar/usuario-free', dataAny: 'Nada' })
      .then(res => {
        setData(res)
      })
  }, [])


  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs);
    if (inputs.soy === '' || inputs.busco === '') {
      return setResult('Debe elegir una de las opciones de Soy, y Busco')
    }

    await axios.post(`${API}/signup`, {
      soy: inputs.soy,
      busco: inputs.busco,
      email: inputs.email,
      password: inputs.password,
    })
      .then((res) => {
        console.log(res);

        try {
          if (res.data.errors[0].msg) {
            setResult(res.data.errors[0].msg);
          }
        } catch (err) {
          setRedirect(!redirect)
          console.log(err)
        }

      })

    setInputs({
      ...inputs,
      initialForm
    })

  }

  const opening = () => {
    setOpen(!open)
  }

  return (
    <div className='WebBuscarFree'>
      <header className='WebBuscarFree-container'>
        <img src={banner} alt="Foto de banner de amor y amistad" />
        <div className='WebBuscarFree-container__form'>
          <form onSubmit={onSubmit}>
            <div className="justify-content-center">
              <div className="">
                <h3>
                  ENCUENTRA TU PAREJA PERFECTA
                </h3>
                <div className="card-transparent signup-absolute d-sm-block">

                  {!isLogged ? (
                    <Fragment>
                      <div className=" ">
                        <div className="col text-dark">
                          Soy
                          <div >
                            <input
                              onChange={onChange}
                              name="soy"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Hombre"
                              required
                            />
                            Hombre
                          </div>
                          <div>
                            <input
                              onChange={onChange}
                              name="soy"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Mujer"
                              required
                            />
                            Mujer
                          </div>
                        </div>
                        <div className="col text-dark">
                          Busco
                          <div >
                            <input
                              onChange={onChange}
                              name="busco"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Hombre"
                            />
                            Hombre
                          </div>
                          <div>
                            <input
                              onChange={onChange}
                              name="busco"
                              type="radio"
                              className="radio_custom mr-2"
                              value="Mujer"
                            />
                            Mujer
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    false
                  )}

                  <div className={!open ? ' mt-3' : 'd-none'}>
                    <div className=" text-center">
                      {isLogged ? (
                        <>
                        </>
                      ) : (
                        <Fragment>

                          <div
                            className="btn btn-primary2 btn-lg text-uppercase "
                            onClick={opening}
                          >
                            Inscríbete Ahora
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      open ? 'row justify-content-center mt-3' : 'd-none'
                    }
                  >
                    <div className=" text-dark text-center text-mini">
                      <input
                        placeholder="Correo electronico"
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={onChange}
                        required
                        value={inputs.email}
                      />
                    </div>
                  </div>
                  <div
                    className={open ? 'row justify-content-center mt-1' : 'd-none'}>
                    <div className=" text-dark text-center text-mini">
                      <input
                        placeholder="Contraseña"
                        type="password"
                        name="password"
                        className="form-control"
                        onChange={onChange}
                        required
                        value={inputs.password}
                      />
                    </div>
                  </div>
                  <div className={open ? 'row mt-3' : 'd-none'}>
                    <MessageResponse
                      result={result}
                      setResult={setResult}
                    />
                    <div className="col text-center">
                      <button
                        type="submit"
                        className="btn btn-primary2 btn-lg text-uppercase "
                      >
                        Registrarse
                      </button>
                    </div>
                  </div>
                  <div className={open ? 'row mt-3' : 'd-none'}>
                    <div className="col text-dark text-center text-mini">
                      Al registrarse confirmas que estás de acuerdo con los términos y condiciones
                      y declaras estar de acuerdo
                      con la política de privacidad de los datos personales
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </header>

      <section className='WebBuscarFree-container__section'>
        <p>
          En Me2You sabemos que encontrar una amistad o el amor de tu vida con propósitos o proyectos
          similares a los tuyos, puede ser muy positivos para crear una relación más armoniosa, duradera
          y con mayor comprensión por ambas partes
          <b>Inicia la aventura y compruébalo tu mism@</b>
        </p>
      </section>

      <section className='WebBuscarFree-container__users'>
        <h4>Miembros Nuevos</h4>
        {
          data.length !== 0
            ? (
              <div className='img-buscar'>
                {
                  data.map((data, key) => {
                    return (
                      <ItemProfile
                        images={{ Hombre: Hombre, Mujer: Mujer }}
                        key={`${data.img}_${key}`}
                        data={data}
                        img={data.img}
                        free={true}

                      />
                    )
                  })
                }
              </div>
            )
            : (
              <p>No hay datos</p>
            )
        }
      </section>

      <section className='WebBuscarFree-container__banner'>
        <div className='WebBuscarFree-container__bannerFondo'>
          <img src={bannerFinal} alt="Banner de fondo" />
        </div>
        <img src={bannerFinalPerson} alt="" />
        <p>
          Aquí puedes encontrar el amor y amistad, no dejes pasar esta
          oportunidad única de conocer en línea a esa persona especial que
          ya está esperando por ti. Conoce gente con intereses afines.
        </p>
      </section>
      <ModalSignup />
    </div>
  )
}

export default WebBuscarFree;