import React, { useState } from 'react'
import Input from '../components/Input'

import Banner from '../../assets/images/design/banner1.jpg';
import { fetchPost } from '../../helpers/helpCore'
import MessageResponse from '../components/MessageResponse'
import '../styles/Report.css'
const usuario = localStorage.getItem('email')

const initialState = {
  nombre: '',
  cuenta: '',
  problema: '',
  detalles: ''
}

const Report = () => {
  const [inputs, setInputs] = useState(initialState);
  const [result, setResult] = useState('')
  // const shoot = () => {
  //   alert('Gracias por tu informe sobre esta cuenta')
  // }
  const url = '/reports'

  const onSubmit = (e) => {
    e.preventDefault()
    // Reporte
    fetchPost({
      name: inputs.nombre,
      nameReport: inputs.cuenta,
      problema: inputs.problema,
      detailsProblem: inputs.detalles,
      usuario: usuario ? usuario : '  Sin sesión iniciada',
      url
    })
      .then(res => {
        setInputs(initialState);
        setResult(res?.msg)
      })
  }

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className="main">
      <form onSubmit={onSubmit}>
        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body report-box">
                  <div className="row ">
                    <div className="col report-box__img">
                      <img className="img-fluid" src={Banner} alt="Pgrw" />
                    </div>
                    <div className="col">
                      <h3 className="text-center pb-4">Reportar</h3>
                      <div>
                        <Input
                          type="email"
                          name="nombre"
                          placeholder="Ingrese su correo"
                          className="form-control"
                          onChange={onChange}
                          required={true}
                        />
                      </div>
                      <div>
                        <Input
                          className="form-control"
                          name="cuenta"
                          placeholder="Cuenta a reportar (Nombres o link del perfil)"
                          onChange={onChange}
                          required={true}

                        />
                      </div>
                      <div>
                        <Input
                          className="form-control"
                          name="problema"
                          placeholder="Problema a reportar"
                          onChange={onChange}
                          required={true}

                        />
                      </div>
                      <div>
                        <textarea
                          name="detalles"
                          className="form-control"
                          onChange={onChange}
                          placeholder="Detalles del problema"
                          required={true}

                        ></textarea>
                      </div>
                      <div className="mt-2">
                        <input
                          // onClick={shoot}
                          type="submit"
                          className="btn btn-primary2"
                          value="Enviar"
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <MessageResponse
                  result={result}
                  setResult={setResult} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Report;