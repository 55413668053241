import React, { useState, useEffect } from 'react'
import { fetchPost } from '../../helpers/helpCore'

import '../styles/Wink.css'
import WinkCard from './WinkCard';

const Wink = ({ result, setResult }) => {
  const [wink, setWink] = useState(undefined)
  const [winkDel, setWinkDel] = useState(false)
  const uid = localStorage.getItem('uid')

  useEffect(() => {
    setWinkDel(false)
    const url = '/premier/winkView'
    fetchPost({ otherUser: uid, url })
      .then(response => {
        // console.log(response)
        setWink(response)
      })
    // eslint-disable-next-line
  }, [winkDel])


  return (
    <div className="Wink">
      <div className="Wink-title">
        <h4>
          <b>Mira quién te dio un guiño</b>
        </h4>
      </div>
      <div className="Wink-container">

        {wink !== undefined
          ? (

            wink.length !== 0
              ? wink?.map((wink, key) => {
                return (
                  <WinkCard
                    data={wink}
                    key={`${key}_${wink.uid}`}
                    winkDel={winkDel}
                    setWinkDel={setWinkDel}
                    result={result}
                    setResult={setResult} />

                )
              })

              : (
                <div>
                  Aún no tienes guiños
                </div>
              )
          )
          : (
            <div>
              Aún no tienes guiños
            </div>
          )}

      </div>

    </div>
  )
}

export default Wink;