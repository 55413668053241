import React, { useState, useEffect } from 'react';
import { fetchPost } from '../../helpers/helpCore'
import useAuth from '../../hooks/useAuth';

import '../styles/Admi.css'

const AdmiReport = () => {
  const [report, setReport] = useState([])
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    fetchPost({ token, url: '/admin/validateAdmin' })
      .then(res => {
        if (res === false) {
          logout()
        }
      })
      .catch(err => console.log(err))
      // eslint-disable-next-line
  })


  useEffect(() => {
    fetchPost({ token, url: '/reports/admin' })
      .then(response => {
        setReport(response ? response : [])
      })
      // eslint-disable-next-line
  }, [])

  return (
    <div className="Admin-container">
      <div className='Admin-title'>{/*opciones: ver reportes, ver contactos, ver perfiles*/}
        {/* <h2>Hola!, {name}</h2> */}
      </div>
      <div className="Admi-box">
        <h3>Reportes de los usuarios</h3>
        <div className="Admin-contact">
          {
            report.length > 0
              ? (
                report.map((data) => (
                  <div
                    key={data._id}
                    className="Admin--contact__small"
                  >
                    <p><strong>Nombre/correo</strong>: {data.name}</p>
                    <p><strong>Email (si tenia sesion iniciada)</strong>: {data.usuario}</p>

                    <p><strong>Cuenta reportada</strong>: {data.nameReport}</p>
                    <p><strong>Contenido</strong>: {data.problema}</p>
                    <p><strong>Detalles del problema</strong>: {data.detailsProblem}</p>
                  </div>
                ))
              )
              : (
                <p>Sin datos...</p>
              )
          }
        </div>

      </div>
    </div>
  )
}

export default AdmiReport;