import React, {
  useState,
  useEffect,
} from 'react'
import ReactPaginate from 'react-paginate';
import PaginationProject from './PaginationProject';
import IconLoading from './IconLoading';
import ViewHelpStory from './ViewHelpStory';
 
const ProjectPost = ({project, loading}) => {

  //===
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;//numero de post en pantalla
  //===

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % project?.length;
    setItemOffset(newOffset);
  };

 
  return (
    <div className="HelpPage-post">
      <div className="HelpPage-post__title">
        <h3><strong>ELIGE UN PROYECTO AL CÚAL APOYAR</strong></h3>
      </div>
      <div>
        <div className='HelpPage-project'>
          {
            project?.length !== 0
              ? (
                <PaginationProject
                  data={project}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                  itemsPerPage={itemsPerPage}
                  handlePageClick={handlePageClick}
                />
              )
              : (false)
          }



        </div>
        <ViewHelpStory />
        <div className='Project-box__text'>
          <h4>
            ¿Tienes un Proyecto de ayuda comunitaria que requiere ayuda?
            Mándanos un mail y si califica, con gusto lo subimos.
          </h4>
        </div>
        {loading && <IconLoading />}
        <div className="ReactPaginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel="   >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<  "
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectPost;