import { useState } from 'react';

export default function useCheckboxRazones (itemsRazones, inputs, setInputs) {
  const dataRazones = [];
  const [checkedRazones, setCheckedRazones] = useState(false)


  const limitCheckbox = (e) => {
    const limit = 2;
    var count = 0, i = 0;

    for (i = 0; i < itemsRazones.length; i++) {
      // console.log(`${i} ${itemsRazones[i].value} ${itemsRazones[i].checked}`)

      if (itemsRazones[i].checked) {
        count = count + 1;
      }
      //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsRazones[i].checked = false;
      }
    }
  }


  const onChangeRazones = (e) => {
    limitCheckbox();
    setCheckedRazones({
      [e.target.value]: checkedRazones
    })
  }

  const handleClickRazones = () => {
    // console.log('esta funcionando')

    for (var i = 0; i < itemsRazones.length; i++) {
      // console.log(`${i} ${itemsRazones[i].value} ${itemsRazones[i].checked}`)

      if (itemsRazones[i].checked) {
        dataRazones.push(itemsRazones[i].value)
      }
    }
    // console.log(dataRazones)

    // setInputs({
    //   ...inputs,
    //   ["inputRazones"]: dataRazones
    // })
    return dataRazones;
  }


  return {
    // dataRazones,
    checkedRazones,
    onChangeRazones,
    handleClickRazones,
  }
}

