import React from 'react'
import '../styles/Layout.css'

const PrivacyPolicies = () => {
  return (
    <div className='PolicityPrivate-container'>

      <h2>AVISO DE PRIVACIDAD INTEGRAL O COMPLETO.</h2>

      <h3>AVISO DE PRIVACIDAD.</h3>
      <article>
        <p> La sociedad mexicana que opera bajo el nombre comercial “Me2you”, cuyo responsable es la C. Maria Belen Contreras Mendoza (misma sociedad y responsable que en adelante serán indistintamente referidos como Me2you), con domicilio ubicado en la calle ______ es responsable de recabar sus datos personales, así como del uso, tratamiento y protección de los mismos. Lo anterior con fundamento en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos legales aplicables nacionales y supranacionales.
        </p>
        <br />
        <h4>Cómo contactarnos.</h4>
        <p>
          Me2you cuenta con unidad de protección de datos personales (oficina de privacidad), la cual se encuentra dentro del domicilio arriba señalado, cuyo responsable es el C. _______, quien puede ser localizado mediante el correo electrónico ______________
        </p>
        <br />
        <h4>Datos personales que se recaban.</h4>
        <p>
          Me2you puede recabar datos personales del titular mediante la entrega directa o personal, a través de cualquier medio de contacto físico o electrónico con el titular. Así también, Me2you puede recolectar datos de manera indirecta del titular, mediante fuentes de acceso público y otras fuentes disponibles en el mercado.

        </p>
        <p>
          Me2you recaba datos personales de identificación, datos financieros y/o patrimoniales como son correo electrónico, número de teléfono celular y nombre en la página de la compañía
        </p>
        <br />
        <h4>Alcance del presente “Aviso de Privacidad”.</h4>
        <p>
          El presente “Aviso de privacidad”, es aplicable para toda la información, incluyendo la de carácter personal recopilada por Me2you, así también, aquella información de terceros con los que Me2you celebre o vaya a celebrar relaciones comerciales de cualquier naturaleza. En este sentido, al momento de leer el presente “Aviso de Privacidad” usted otorga su consentimiento a Me2you para recopilar y utilizar lícitamente sus datos personales para los fines que más adelante se expresan.
        </p>
        <p>
          Para la recopilación y tratamiento de datos personales, Me2you se compromete y obliga a observar y cumplir con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad; así como todos los principios y disposiciones establecidas por la legislación nacional e internacional en materia de Protección de Datos Personales.
        </p>
        <p> Los cambios al presente “Aviso de Privacidad” ser harán de conocimiento general en el establecimiento comercial cuya dirección se indica en líneas previas, así como dentro del sitio de Internet ___________	</p>

        <h4>
          Finalidades del tratamiento de datos personales.
        </h4>
        <p>
          Los datos personales puestos a disposición de Me2you, se incluyen en una base de datos e información electrónica, la cual es utilizada para la clasificación, uso, resguardo y protección de datos personales de personas físicas y morales vinculadas con Me2you. Así entonces, la información y datos personales ahí contenidos podrá ser utilizada por Me2you para las siguientes finalidades:
        </p>
        <li>
          1. Finalidades Principales.
        </li>
        <ol>
          <li>
            • Permitir a Me2you proveer los servicios y/o productos que presta y comercializa, los cuales han sido contratados por usted.
          </li>
          <br />
          <li>
            • Proveer la información necesaria para que Me2you pueda proveer los productos y/o servicios contratados por usted.
          </li>
          <br />
          <li>
            • Cumplir las obligaciones que Me2you contrae con usted.
          </li>
          <br />
          <li>
            • Facilitar las comunicaciones entre usted y Me2you.
          </li>
          <br />
          <li>
            • Recibir pagos por virtud de la adquisición de los productos y servicios que Me2you comercializa y presta, así como emitir los respectivos comprobantes fiscales.
          </li>
          <br />

        </ol>
        <li>1. Finalidades Secundarias.</li>

        <ol>
          <li> Permitir que Me2you realice evaluaciones de calidad de sus productos y/o servicios.</li>
          <br />
          <li>
            Posibilitar que Me2you informe sobre los cambios relacionados con los productos y servicios contratados por sus clientes.
          </li>
        </ol>
        <br />
        <h4>Datos personales que se obtienen.</h4>

        <p>
          Para la realización de las finalidades anteriormente señaladas, Me2you requiere de múltiples datos personales, dentro de los cuales se destacan los siguientes:

        </p>
        <p>
          Datos de identificación: Nombre, domicilio, teléfono de casa o móvil, correo electrónico, fax, firma autógrafa, Registro Federal de Contribuyentes (RFC), Cédula única de registro de población (CURP), identificación vigente, nacionalidad, comprobante de domicilio, y aquellos que por la disposición legal deban ser proporcionados.
        </p>
        <p>
          Datos patrimoniales: Cuentas bancarias, cuenta CLABE y banco para que sean efectuados pagos a su favor, o bien para un posible reembolsos, así como los demás datos patrimoniales necesarios para el desarrollo de los servicios que Me2you presta en su favor.
        </p>
        <br />
        <h4>
          Limitación del uso y divulgación de sus datos personales.
        </h4>
        <p>
          El uso de datos personales que Me2you realice se ajustará a los objetivos acordados con sus clientes, proveedores o personas que por cualquier circunstancia pongan datos personales a su disposición.
        </p>
        <p>
          No obstante lo anterior, cuando el tratamiento de datos personales no se refiera a aquellos necesarios para cumplir con las obligaciones legales que Me2you ha asumido con usted, le será posible solicitar expresamente a Me2you que se abstenga a continuar usando sus datos personales (oposición). Enviando su solicitud por correo electrónico a _______, o bien, por escrito en el domicilio que al efecto Me2you ha designado.
        </p>

        <p>
          Los sistemas electrónicos de Me2you se encuentran debidamente protegidos por medio de datos encriptados para evitar pérdidas o filtraciones de los datos personales proporcionados.
        </p>
        <p>
          El tratamiento que Me2you ejercerá sobre sus datos personales será el que resulte necesario, correcto y relevante para las finalidades previstas en el presente “Aviso de Privacidad”.
        </p>
        <br />
        <h4>
          Medios para ejercer los derechos de Acceso, Rectificación, Cancelación y Oposición (mejor conocidos por doctrina en materia de derechos personales como “derechos ARCO”).
        </h4>
        <p>
          Conforme al Articulo 16 de Ley Federal de Protección de Datos Personales en Posesión de los Particulares es la ley que protege los datos personales y en la cual viene todo esto que se menciona aqui en materia de datos personales, usted tiene la facultad de ejercer sus derechos ARCO, mismos derechos que para su pronta referencia se describen a continuación:

        </p>
        <ol>
          <li>
            • Derecho de Acceso: Derecho del titular a solicitar a Me2you que le informe si en su base de datos cuenta con información relativa a sus derechos personales.
          </li>
          <br />
          <li>
            • Derecho de Rectificación: Derecho del titular a solicitar a Me2you la corrección de los datos personales que posee en su base de datos, siempre y cuando dichos datos sean incorrectos, imprecisos, incompletos o están desactualizados.
          </li>
          <br />
          <li>
            • Derecho de Cancelación: Derecho del titular de solicitar a Me2you la cancelación de sus datos personales que posee en sus bases de datos.
          </li>
          <br />
          <li>
            • Derecho de Oposición: Derecho del titular para solicitar a Me2you que se abstenga a continuar usando o tratando sus datos personales de forma total o parcial (para determinadas circunstancias o finalidades).
          </li>
          <br />
        </ol>
        <p>
          En atención a lo expuesto, usted tiene derecho de acceder a sus datos personales que Me2you posee y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el presente aviso de privacidad, estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio, o bien, oponerse al tratamiento de los mismos para fines específicos.
        </p>
        <p>
          En caso que usted desee hacer efectivos sus derechos ARCO, podrá hacerlo dirigiendo un correo electrónico a ______ la persona que para tal efecto Me2you ha designado, o también mediante la solicitud física (en papel) a nuestra oficina de privacidad.
        </p>
        <p>
          Una vez que Me2you reciba la solicitud respectiva, procederá a atender la petición en un plazo de 20 (veinte) días contados a partir de la fecha de solicitud, y se le informará sobre la procedencia de la misma a través del correo electrónico que al efecto se exprese en la solicitud.
        </p>
        <br />
        <h4>Transferencia de datos personales.</h4>
        <p>
          A efecto que Me2you desarrolle las actividades comerciales y promocionales que usted ha contratado, es posible que sus datos personales sean transferidos y tratados dentro y fuera del país por personas distintas a Me2you, principalmente en los casos en que Me2you requiera el apoyo de terceras partes para que realicen ciertas tareas a su nombre, las cuales estarán relacionadas con las actividades comerciales contratadas con los fines anteriormente descritos.
        </p>
        <p>
          En este sentido, Me2you se compromete a no transferir sus datos personales sin su consentimiento, salvo en los supuestos previstos en la legislación aplicable, así como a realizar esta transferencia en los términos establecidos por la ley.
        </p>
        <p>
          Asimismo, Me2you exigirá a los sujetos que reciban datos personales, el cumplimiento puntual de las disposiciones en materia de protección de datos, así como la protección, clasificación y debido manejo de los datos personales transferidos por parte de Me2you.
        </p>

        <p>
          En caso que Me2you requiera para el óptimo desarrollo de sus actividades comerciales la transferencia de datos sensibles, se deberá solicitar su consentimiento expreso.
        </p>
        <p>
          Si usted no manifiesta expresamente su oposición para que Me2you transfiera a terceros sus datos personales, se entenderá que usted otorga su consentimiento para ello.
        </p>
        <br />

        <h4>
          Modificaciones al “Aviso de Privacidad”.
        </h4>

        <p>
          Me2you se reserva el derecho a realizar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad para la atención de novedades legislativas o jurisprudenciales, políticas internas o nuevos requerimientos para la prestación de sus servicios o provisión de sus productos.
        </p>
        <p>
          Las modificaciones y/o actualizaciones al “Aviso de Privacidad” serán notificadas al público a través de la siguiente página de Internet: ________________
        </p>
        <p>
          Para cualquier duda en relación con el presente “Aviso de privacidad”, favor de comunicarse con la oficina de privacidad de Me2you.
        </p>
        <br />
        <h4>Uso de cookies y web beacons.</h4>
        <p>
          Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias para la visualización de las páginas en ese servidor, nombre y contraseña.
        </p>
        <p>
          Por su parte, las web beacons son imágenes insertadas en una página de Internet o correo electrónico, que pueden ser utilizadas para monitorear (i) el comportamiento de un visitante, (ii) la información almacenada sobre la dirección IP del usuario, (iii) la duración del tiempo de interacción en dicha página, (iv) el tipo de navegador utilizado, entre otros.
        </p>
        <p>
          En virtud de lo anterior, es propio mencionar que Me2you utiliza cookies y otras tecnologías de identificación en sus sitios web, aplicaciones móviles, comunicaciones electrónicas, anuncios y demás servicios en línea. Mediante dichas cookies, Me2you recopila datos personales para autentificación y configuración de usuarios, análisis de tráfico en su sitio web y aplicaciones móviles, tendencias y en general para poder comprender el comportamiento e intereses de los usuarios y personas que interactúan con los servicios y productos de Me2you.
        </p>
        <p>
          Usted tiene la facultad de impedir que Me2you utilice cookies, para lo cual es altamente probable que se afecte la funcionalidad de sus servicios y productos.
        </p>
        <br />
        <p>
          La mayoría de los navegadores web están configurados para aceptar cookies por defecto. Si lo prefiere, normalmente puede optar por configurar su navegador para eliminar o rechazar las cookies del navegador. Para ello, siga las instrucciones proporcionadas por su navegador que normalmente se encuentran dentro del menú “Ayuda” o “Preferencias”.
        </p>

        <h4>Quejas y denuncias por el tratamiento indebido de sus datos personales.</h4>
        <p>
          Si usted considera que sus derechos de protección de datos personales han sido lesionados por alguna conducta imputable a Me2you, podrá interponer la queja o denuncia correspondiente ante el INAI. Para mayor información visite
          <a href="http://inicio.ifai.org.mx/SitePages/ifai.aspx">
            http://inicio.ifai.org.mx/SitePages/ifai.aspx
          </a>  .
        </p>

      </article>

    </div>
  )


}

export default PrivacyPolicies;