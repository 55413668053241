import React from 'react'
import { plans } from '../../assets/plans.json';
import star from '../../assets/images/design/star-paquetes-premiere.png';
import '../styles/Membresia.css'
import { Link } from 'react-router-dom';
// import { useMercadopago } from "react-sdk-mercadopago";


const Planes = () => {
  const plansId = localStorage.getItem('planId');
  //const [plansId, setPlanesId] = useState(planId);

  return (
    <section className="planes">
      <div className="container mb-3 mt-5">
        <div className="row text-center justify-content-center">
          {
            !plansId ?
              plans.map((row, key) => {
                return (
                  <div key={row.id} className="col-12 col-sm-3 planes-card">
                    <div className="card">
                      <div className="plan_membresia--start">
                        {
                          key === 0
                            ? (<img src={star} alt="pgrw" />)
                            : key === 1
                              ? (<>
                                <img src={star} alt="pgrw" /> <img src={star} alt="pgrw" />
                              </>
                              )
                              : key === 2
                                ? (
                                  <>
                                    <img src={star} alt="pgrw" />
                                    <img src={star} alt="pgrw" />
                                    <img src={star} alt="pgrw" />

                                  </>
                                )
                                : (false)
                        }
                        <div className="plan-name"><strong>{row.title}</strong></div>
                      </div>
                      <div className="card-body p-0 border-primary2 membresia">
                        <div className="mt-4 mb-3">
                          {/* Inscripción <br />
                        por 1 mes */}
                          {row.subtitle}
                        </div>
                        <div className="bg-primary3 h3 p-2">
                          <strong>
                            $ {row.perMonth} x mes
                          </strong>
                        </div>
                        <div className="text-primary2 h1">
                          -{row.discount}%
                        </div>
                        <div className="mb-3">
                          Paga sólo $
                          <strong>{row.afterDiscount}</strong>
                        </div>



                        {/* <a 
                          href={row.id}
                          target='_blank'
                          className="btn btn-primary2 btn-lg mb-3"
                          >
                          Pagar ahora
                        </a> */}

                        <Link
                          className="btn btn-primary2 btn-lg mb-3"
                          to={{
                            pathname: `/comprar-plan`,
                            state: {
                              row
                            }
                          }}
                        >
                          Pagar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              }) :
              <h1>Success</h1>
          }
        </div>
        <div className="row mt-2 mb-2 ">
          <div className="col text-center p-3">
            {/* <Link
              className="btn btn-primary2 btn-lg" 
              to="web/actualizarPlan?id=Ma_planes_66d5056458a62e49e9258ff5002e62f9">
                ¡ACTUALIZA A PREMIER AHORA!
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  );

}

export default Planes;