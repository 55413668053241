import React, { Fragment } from 'react';
// import { connect } from 'react-redux';
import '../styles/HomePrincipal.css'

import BannerPrincipal from '../components/BannerPrincipal';
import Triseccion2 from '../components/Triseccion2';
import Monoseccion3 from '../components/Monoseccion3';
import Monoseccion from '../components/Monoseccion';

import ico4 from '../../assets/images/design/ico-4.png';
import ico5 from '../../assets/images/design/ico-5.png';
import img5 from '../../assets/images/design/bg-banner-inferior-final-home.jpg';
import img6 from '../../assets/images/design/pareja-banner-inferior-final-home.png';
import img8 from '../../assets/images/design/bg-historias-de-exito.jpg';
import banner from '../../assets/images/design/banner.jpg';
import Monoseccion2 from '../components/Monoseccion2';
import CardImageOption from '../components/CardImageOption';


const Landing = () => {
  return (
    <div className="main">
      {/* {firstForm === false ? <Redirect to="/web/profile" /> : (false)} */}

      <BannerPrincipal>
      </BannerPrincipal>

      <Fragment>
        <div className="bg-white">
          {/* <section className="p-5">
            <Triseccion />
          </section> */}
          <section >
            <CardImageOption />
          </section>
          <section className="p-5">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2>Bienvenido a <b>Me2You</b>.</h2>
                  <p>

                    <br />Este espacio digital está
                    enfocado en el apoyo mutuo con distintas modalidades. Contamos
                    con 2 fantásticos espacios en los que podrás participar,
                    recibir y brindar AYUDA de todo tipo; desde “dar un consejo”,
                    “solicitar información” y hasta “realizar una donación a un
                    proyecto importante de apoyo a la comunidad”...
                    <br />
                    <br />
                    Nuestra tercer área está enfocada en la BÚSQUEDA DEL AMOR Y LA
                    AMISTAD, con la genialidad de ayudarte a conocer los proyectos
                    y propósitos en común que puedan tener ambas partes.
                    <br />
                    <br />
                    Sabemos que cuando 2 o más se unen con propósitos o metas comunes las cosas caminan mejor y se disfrutan 
                    más. Explora, conoce, diviértete, apoya y recibe apoyo de los demás, 
                    ¡Gracias por estar aquí!                    
                    <br />
                    <br />
                    ¡CREA TU PERFIL HOY MISMO!
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Triseccion2 />
        <Monoseccion3
          img={img5}
          img2={img6}

          text="Se parte"
          text2="Mantente en contacto"
        />
        <Monoseccion
          className="bg-blue"
          ico={ico4}
          img2={img8}
          text="Historias de exito"
          text2={
            'Llevaba mas de 3 años buscando una pareja y aunque ya había buscado en otros sitios de parejas, no había acabado por encontrar a alguien que sintiera me complementaba… Hoy, gracias a Me2You puedo decir que por fin lo encontré! Es genial encontrar una pareja que tiene propósitos comunes y que camina contento tu mismo camino. Gracias Me2You!'
          }
        />
        <Monoseccion2
          className="bg-white"
          ico={ico5}
          banner={banner}
          text="Proyectos en Proceso"
        />
      </Fragment>

    </div>
  )
}

export default Landing;
