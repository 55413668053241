import React, { useState, useEffect } from "react";
import { fetchPost } from "../../helpers/helpCore";
import TextPost from "./TextPost";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import '../styles/HomePrincipal.css'


const ViewHelpStory = () => {
  const [data, setData] = useState([])
  const [viewCount, setViewCount] = useState(1)
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    fetchPost({ url: '/admin/viewHelpStory', token })
      .then(res => {
        setData(res)
      })
      // eslint-disable-next-line
  }, [])

  const handleButtonLater = () => {
    if (viewCount >= 1) {
      setViewCount(viewCount - 1)
    }
  }

  const handleButtonNext = () => {
    if (viewCount <= 1) {
      setViewCount(viewCount + 1)
    }
  }

  return (
    <div className="HelpStory-container">
      <div className="text-center">
      <h4>Historias de Ayuda</h4>

      </div>
      {
        data.length !== 0
          ? (
            <div className="HelpStory-container__box">
              <div className="HelpStory-image">
                <img src={data[viewCount]?.img} alt="" loading="lazy" />
              </div>
              <div className="HelpStory-text text-center">
                <TextPost text={data[viewCount]?.text} />
              </div>
            </div>
          )
          : (false)

      }
      <div className="BannerPrincipalButtons">
        <div className="BannerButton BannerButtonLeft">
          <FontAwesomeIcon
            size="2x"
            className="icons__menu"
            icon={faChevronLeft}
            onClick={handleButtonLater}
          />
        </div>
        <div className="BannerButton BannerButtonRight">
          <FontAwesomeIcon
            size="2x"
            className="icons__menu"
            icon={faChevronRight}
            onClick={handleButtonNext}
          />
        </div>
      </div>

    </div>
  )
}

export default ViewHelpStory