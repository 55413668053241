import React, {useState} from 'react'
import '../styles/Galery.css'
import Modals from '../components/Modals'
import { useModal } from '../../hooks/useModal'



const Galery = ({ data }) => { 
  const [image, setImage] = useState()
  const [isOpen, openModal, closeModal] = useModal(false);

  const handleImage = (data) => {

    setImage(data)
    openModal()
  }

  return (
    <div className="Galery-container">
      <div className="Galery-container__title">
        <h3>Galería</h3>
      </div>
      <div className="Galery-container__box">
        {
          data?.length !== 0
            ? (
              data?.map((data, key) => {
                return ( 
                  <div key={`${key}_${data}`} onClick={() => handleImage(data)}>
                    <img
                      src={data}
                      alt="Imagen de galeria"
                      
                      className="Galery-container__img"
                      loading="lazy"
                       />
                  </div>
                )
              })
            )
            : (
              <div className="text-center">
                <h5>El usuario no cuenta con imagénes en la galería</h5>
              </div>
            )
        }
      </div>
      <Modals
      isOpen={isOpen}
      closeModal={closeModal}>
        <img src={image} alt="Imagen de galeria" />
      </Modals>
    </div>
  )
}

export default Galery;