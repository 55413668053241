import { useState } from 'react';

export default function useCheckboxPermission (itemsPermission) {
  const dataPermission = [];
  const [checkedPermission, setCheckedPermission] = useState(false)


  const limitCheckbox = (e) => {
    const limit = 5;
    var count = 0, i = 0;

    for (i = 0; i < itemsPermission.length; i++) {
      // console.log(`${i} ${itemsPermission[i].value} ${itemsPermission[i].checked}`)

      if (itemsPermission[i].checked) {
        count = count + 1;
      }
      //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsPermission[i].checked = false;
      }
    }
  }


  const onChangePermission = (e) => {
    limitCheckbox();

    setCheckedPermission({
      [e.target.value]: checkedPermission
    })
    
  }

  const handleClickPermission = () => {

    for (var i = 0; i < itemsPermission.length; i++) {
      if (itemsPermission[i].checked) {
        dataPermission.push(itemsPermission[i].value)
      }
    }

    return dataPermission;
  }


  return {
    checkedPermission,
    onChangePermission,
    handleClickPermission,
  }
}

