import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CommentPost from './CommentPost';
import ReactionPost from './ReactionPost';
import dateFormat from '../../helpers/dateFormat'
import '../styles/HelpPage.css'
import '../styles/ButtonPost.css'
import TextPost from './TextPost';
import { fetchPost } from '../../helpers/helpCore';

const Post = ({
  uid,
  idPost,
  img,
  name,
  dataPost,
  reaction,
  comment,
  created,
  reactionName,
  editPost,
  setDeletePost }) => {
  const [active, setActive] = useState(false)
  
  const handleClickControlPost = () => {
    setActive(!active)
  }

  const handleDeletePost = () => {
    fetchPost({
      token: localStorage.getItem('jwt'),
      method: 'DELETE',
      url: '/post/deletePost',
      idPost,

    }).then(res => {
      if (setDeletePost) {
        setDeletePost(res?.msg)
      }
      alert(res?.msg)
      setActive(false)
    })
  }

  return (
    <div className="PostContainer">
      {/* si esta solo POST img, es porque existe una imagen */}
      <div className={`Post ${img !== null ? 'img' : ''}`}>
        {
          img !== null
            ? (
              <div className={`Post-img ${img !== null ? 'img' : ''}`}>
                <img src={img} alt="Foto de publicación" loading="lazy" />
              </div>
            )
            : (false)
        }
        {/* <div className="Post-data"> */}
        <div className={`Post-data ${img !== null ? 'img' : ''}`}>
          <h5><strong>{name}</strong>{' '} <span>Publicado: {dateFormat(created)}</span></h5>
          { /*<p>
            {dataPost}
                     
          </p>*/}

          <TextPost text={dataPost} post={true} />
          <div className="Post-data__button">
            <div className="Post-data__buttonBox">
              <ReactionPost
                // uid={uid}//id del creador 
                idPost={idPost} //id del post
                reactionValue={reaction} //cant de reacciones
                reactionNameInPost={reactionName} //nombre de la reaccion del usuario
              />
            </div>
            <div className="Post-data__buttonBox">
              <CommentPost
                uid={uid}
                idPost={idPost}
                comment={comment}
              />
            </div>
          </div>
        </div>
        {
          editPost === uid &&
          <div className="Post-data__editNote">
            <div className="editNote-button" onClick={() => handleClickControlPost()}>
              •••
            </div>
            <div className={`editNote-option ${active ? 'active' : ''}`}>
              <p>
                <Link to={`/mis-publicaciones/editar/${idPost}`}>
                  Editar
                </Link>
              </p>
              <p onClick={() => handleDeletePost()}>Eliminar</p>
            </div>

          </div>

        }
      </div>
    </div>
  )
}

export default Post;