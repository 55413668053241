import axios from 'axios'

//export const API = 'http://localhost:8080' //DESARROLLO 
export const API = 'https://me2you.mx' //PRODUCCION

//======= AUTH

export const authenticate = (token, email, uid, rol, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('jwt', token);
    localStorage.setItem('email', email);
    localStorage.setItem('uid', uid);
    localStorage.setItem('role', rol);
    if (rol === 'ADMIN_ROLE') {
      localStorage.setItem('admin', token)
    }

    next();
  }
}

export const signout = (next) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('jwt');
    next();
    return fetch(`${API}/auth/signout`, {
      method: 'GET',
    })
      .then(response => {
      })
      .catch(err => console.log(err));
  }
}

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }
  if (localStorage.getItem('jwt')) {
    return JSON.parse(localStorage.getItem('jwt'));
    // return localStorage.getItem('jwt')
  }
  return false;
}

//IMAGENES ===========================
export const singleFileUpload = async (data, user) => {
  const usuarios = 'usuarios'
  let res;
  await axios.put(
    `${API}/uploads/${usuarios}/${user}`,
    data
  ).then(response => {
    if (response?.data?.msg) {
      //  alert(response?.data?.msg);
      res = response?.data?.msg;
    }
  }).catch(err => {
    console.log(err)
  })

  return res;
}

export const coverFileUpload = async (data, user) => {
  let res;
  await axios.put(
    `${API}/uploads/cover/${user}`, data
  ).then(response => {
    if (response?.data?.msg) {
      // alert(response?.data?.msg) 
      res = response?.data?.msg;
    }
  }).catch(err => {
    console.log(err)
  })
  return res;
}

export const MultipleFileUpload = async (data, user) => {
  let res;
  await axios.put(`${API}/uploads/info/${user}`, data
  ).then(response => {
    if (response?.data?.msg) {
      res = response?.data?.msg;
    }
  }).catch(err => {
    console.log(err)
  })
  return res;
}

export const MultipleFileProjectUpload = async (data, user) => {
  let res;
  await axios.put(`${API}/uploads/project/${user}`, data
  ).then(response => {
    if (response?.data?.msg) {
      res = response?.data?.msg;
    }
  }).catch(err => {
    console.log(err)
  })
  return res;
}

//======= POST
export const singleFilePost = async (data, user) => {
  let result;
  await axios.put(
    `${API}/post/createPost/${user}`,
    data
  ).then(response => {
    if (response?.data?.msg) {
      result = response?.data?.msg
      // alert(response?.data?.msg)
    }
  }).catch(err => {
    console.log(err);
  })

  return result;
}


export const fetchPost = (data) => {
  const { url, idUrl, method, ...rest } = data;
  return fetch(`${API}${url}/${idUrl ? idUrl : ''}`, {
    method: `${method ? method : "POST"}`,
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(rest),
  })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log(err);
    })
};




