import React from 'react'

import Banner from '../../assets/images/design/proyectos.jpeg'
//

const BannerPrincipalProject = ({intervalStyle, bannerImage}) => {
  return(
    <div className={`BannerPrincipalProject bannerAutoplay ${intervalStyle}`}>
      <img src={bannerImage ? bannerImage : Banner} alt="Fondo de proyectos" />
      <div>
        <h3>
          Proyectos comunitarios <br />

          Explora los proyectos que tenemos en nuestro sitio y decide a cuál quieres contribuir.
        </h3>
      </div>
    </div>
  )
}

export default BannerPrincipalProject;