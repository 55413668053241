import React from 'react'
import { Link , useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth'
import Banner from '../../assets/images/design/banner-superior-perfil-usuario.jpg';
import AvatarDefault from '../../assets/images/design/avatar.png';
import mujer from '../../assets/images/design/mujer.jpeg';
import { fetchPost } from '../../helpers/helpCore'
import {
  faCompactDisc,
  faBookOpen,
  faHandsHelping,
  faFutbol,
  faMicrophone,
  faMusic,
  faCocktail,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import queryString from 'query-string';
import '../styles/WebMiPerfil.css'
import { useState, useEffect } from 'react';
import { useModal } from '../../hooks/useModal'
import Modals from '../components/Modals'
import Galery from '../components/Galery';
import MessageResponse from '../components/MessageResponse';
import BoxMessageSend from '../components/BoxMessageSend';


const initalMessage = {
  msg: ''
}

const ProfilePublic = () => { 
  const { logout } = useAuth()
  const [data, setData] = useState(null)
  const [result, setResult] = useState('')
  const [messageBadge, setMessageBadge] = useState(false)
  const [boxMessage, setBoxMessage] = useState(false);
  const [input, setInput] = useState(initalMessage)
  const token = localStorage.getItem('jwt')
  const [isOpen, openModal, closeModal] = useModal(false);
  const idUser = localStorage.getItem('uid');
  const { id } = useParams();
  const uid = id;
  const membresia = localStorage.getItem('role') === "normal" ? false : true;
  


  useEffect(() => { 
    const idUrl = uid;
    fetchPost({ idUrl, url: '/user/info', uid })
      .then(response => {
        try {
          if (response === 'JsonbTokenError') {
            //Si en el back e token ha expirado cierra session
            logout()
          } else {
            setData(response);
          }
        } catch (err) {
          console.log(err)
        }
      })
      // eslint-disable-next-line
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = '/premier/wink'
    fetchPost({ token, otherUser: uid, url })
      .then(response => {
        // console.log(response)
        if (response?.msg) {
          setResult(response?.msg)
        }
      })
  }

  const handleHiddenBoxMessage = () => {
    setBoxMessage(!boxMessage);
  }

  const handleSubmitMessage = (e) => {
    e.preventDefault();
    if (input.msg !== '') {
      fetchPost({
        token: token,
        sender: idUser, //quien envía
        otherUser: uid,//quien recibe
        text: input.msg,
        url: '/premier/sendMessage'
      })
        .then(res => {
          setInput(initalMessage)
          if (res === 'TokenExpiredError') {
            logout()
          } else {
            // console.log(res)
            if (res?.msg) {
              setResult(res?.msg)
            }
            if (res?.message) {
              setResult(res?.message)
            }
          }
        })
    } else {
      setResult('El mensaje esta vacío')

    }
  }

  const handleClickBadge = () => {
    setMessageBadge(!messageBadge)
  }

  return (
    <div className={'main'}>
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">{/* Bienvenido */}</h3>
          <div className="text-center">
          </div>
        </div>
      </section>
      <div className="container-fluid bg-white ">
        <div className="row">
          <div className="col p-0 perfil-img__portada">
            <img
              src={data?.cover ? data?.cover : Banner}
              alt="Foto de perfil"
              className="img-fluid"
              loading="lazy"
            />

          </div>
        </div>
      </div>
      <div className="container-fluid bg-white pb-5">
        <div className="container bg-white">
          <div className="row">
            <div className="col-12 col-sm-6 p-0 border-primary-left">
              <div className="container-dataHeader">
                <div className="container-dataHeader__photo">
                  <div className="dataHeader__photo">
                    <img
                      src={data?.img
                        ? data?.img
                        : data?.soy === 'Hombre'
                          ? AvatarDefault
                          : mujer}
                      alt={`Foto de ${data?.name} ${data?.lastname}`}
                      title={`Foto de perfil de ${data?.name} ${data?.lastname}`}
                      className="float-left border-radius"
                      loading="lazy"
                      onClick={openModal}
                    />
                    {
                      data?.premiere === 'Premiere'
                        ? (
                          <div>
                            <p>Premiere</p>
                          </div>
                        )
                        : (false)
                    }
                  </div>
                </div>
                <div className="container-dataHeader__fullname">
                  <b>
                    {' '}
                    {data?.name != null
                      ? data?.name
                      : 'Nombres sin definir'}{' '}
                    {data?.lastname != null
                      ? data?.lastname
                      : 'Apellidos sin definir'}
                  </b>
                </div>
                <div className="container-dataHeader__link">
                  <form onSubmit={handleSubmit} className="text-center mt-3">
                    <input
                      className="btn btn-primary2"
                      type="submit"
                      value="Guiño" />
                  </form>
                </div>
              </div>

              <hr></hr>

              <MessageResponse
                result={result}
                setResult={setResult} />
              <div className="pl-5 pt-2">
                <div className="row">
                  <div className="col">
                    <strong>Nacionalidad</strong> :{' '}
                    {data?.nacionalidad != null
                      ? data?.nacionalidad
                      : 'Nacionalidad sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Estado Civil</strong> :{' '}
                    {data?.estadoCivil != null
                      ? data?.estadoCivil
                      : 'Estado Civil sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Complexión</strong> :{' '}
                    {data?.complexion != null
                      ? data?.complexion
                      : 'Complexion sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Peso</strong> : {data?.peso != null ? data?.peso : 'Peso sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Cabello</strong> :{' '}
                    {data?.colorCabello != null
                      ? data?.colorCabello
                      : 'Cabello sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Ojos</strong> :{' '}
                    {data?.colorOjos != null
                      ? data?.colorOjos
                      : 'Ojos sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Hijos</strong> :{' '}
                    {data?.hijos != null ? data?.hijos : 'Hijos sin definir'}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Religión</strong> :{' '}
                    {data?.religion != null
                      ? data?.religion
                      : 'Religión sin defini'}
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">

                {data?.badge &&
                  <div className='text-center container-dataHeader__badge'>
                    <h3>
                      Insignia {data?.category}
                      <i 
                      title='Hazme click'
                      onClick={handleClickBadge}
                      >i
                      </i>
                    </h3>
                    <div className={`badge-text ${messageBadge ? 'active' : ''}`}>
                      {data?.badgeTitle}
                    </div>
                    <div className='badge-donate'>
                      <img
                        src={data?.badge}
                        alt={`insignia por donar`}
                        loading="lazy"
                         />
                    </div>
                  </div>
                }
                <div>
                  <Galery data={data?.galery} />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-0 pt-2">

              <div className="container-dataHeader__link">
                <Link
                  className="btn btn-primary2"
                  to={{
                    pathname: `/web/perfil/verMas/${uid}`,
                    state: {
                      data
                    }
                  }}>Ver Perfil Premiere</Link>

                <input
                  type="button"
                  // value="Enviar mensaje" 
                  onClick={handleHiddenBoxMessage}
                  value={`${boxMessage === false ? 'Enviar Mensaje' : 'Ocultar'}`}
                  className="btn btn-primary2" />
              </div>
              <div className='content-message'>
                {
                  boxMessage === true
                    ? (
                      <div>
                        <span>Escribele</span>
                        <BoxMessageSend
                          handleSubmit={handleSubmitMessage}
                          input={input}
                          setInput={setInput}
                          classN={'content-message__send'} />
                      </div>
                    )
                    : (false)
                }
              </div>
              {membresia ? (
                <>
                  <div className="p-4"></div>
                </>
              ) : (
                <>
                  <div className="p-4 bg-gray">
                    Si deseas comunicarte con esta persona y enviar mensajes,
                    compra un plan haciendo{' '}

                    <Link to="/web/membresia" className="text-primary"><strong>click aquí</strong></Link>
                  </div>
                </>
              )}
              <div className="card">
                <div className="card-header bg-primary-pgrw text-white">
                  Más acerca de mi
                </div>
                <div className="card-body card-profile">
                  <div className="row">
                    <div className="col-12 col-sm-2 text-center">
                      <FontAwesomeIcon size="2x" icon={faCocktail} />
                    </div>
                    <div className="col">
                      <div><strong>Bebo alcohol</strong> {data?.bebes_alcohol ? data?.bebes_alcohol : 'sin definir'}</div>
                      <div><strong>Fumo</strong> {data?.fumas ? data?.fumas : 'sin definir'}</div>
                    </div>
                  </div>
                  {data !== undefined &&
                    data?.tiempo_libre !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faBookOpen} />
                        </div>
                        <div className="col">
                          <div><strong>En mi tiempo libre me gusta:</strong></div>
                          <div>
                            {data?.tiempo_libre.map((row, key) => {
                              return (
                                <span key={key}>
                                  {row}
                                  {key <
                                    data?.tiempo_libre.length - 1
                                    ? ', '
                                    : false}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data !== undefined &&
                    data?.importanteRelacion !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faHandsHelping} />
                        </div>
                        <div className="col">
                          <div><b>Es importante para mi:</b></div>
                          <div>
                            {data?.importanteRelacion.map((row, key) => {
                              return (
                                <p key={key}>
                                  {row}
                                  {key < data?.importanteRelacion.length - 1
                                    ? ', '
                                    : false}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data?.deporte !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faFutbol} />
                        </div>
                        <div className="col">
                          <div><b>Deportes favoritos:</b></div>
                          <div>
                            <p>{data?.deporte}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data?.hobbie !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faMicrophone} />
                        </div>
                        <div className="col">
                          <div><strong>Hobbies:</strong></div>
                          <div>
                            <p>{data?.hobbie}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data?.musica !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faMusic} />
                        </div>
                        <div className="col">
                          <div><b>Me gusta esta música:</b></div>
                          <div>
                            <p>{data?.musica}</p>

                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                  {data?.importante !== undefined ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-12 col-sm-2 text-center">
                          <FontAwesomeIcon size="2x" icon={faCompactDisc} />
                        </div>
                        <div className="col">
                          <div>
                            <strong>Es importante para mi</strong>
                            {data?.importante.map((row, key) => {
                              return (
                                <p key={key}>
                                  {row}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
            {data === undefined ? (
              <>
                <div className="col-12 col-sm-5 p-0">
                  <div className="row pt-5">
                    <div className="col h5">
                      <b>Ver Galería</b>
                    </div>
                  </div>
                  <div className="row">
                    {/* <Galeria data?={data?.galeria} /> */}
                  </div>
                </div>
              </>
            ) : (
              false
            )}
          </div>
        </div>
        <Modals
          isOpen={isOpen}
          closeModal={closeModal}>
          <img
            src={data?.img ? data?.img : AvatarDefault}
            loading="lazy"
            alt="programandoweb"
          // className="float-left border-radius"
          // onClick={openModal}
          />
        </Modals>
      </div>

    </div >
  )
}

export default ProfilePublic;