import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import Banner from '../../assets/images/design/img-proceso-de-registro-2.jpg';
import Banner2 from '../../assets/images/design/img-proceso-de-registro-2-a.jpg';

import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MultipleFileUpload, singleFileUpload } from '../../helpers/helpCore';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MultiImageInput from 'react-multiple-image-input';
 
import '../styles/FormImage.css'
import MessageResponse from '../components/MessageResponse';
import recortName from '../../helpers/recortName';
import { useEffect } from 'react';

const WebMiPerfil2 = () => {
  const crop = {
    unit: 'px',
    aspect: 6 / 6,
  };

  const [images, setImages] = useState("");
  const [image, setImage] = useState(false);
  const [viewImage, setviewImage] = useState('')
  const [result, setResult] = useState('');
  const [redirect, setRedirect] = useState(false)
  const imageProfileStore = false;
  const imageGalleryStore = [];
  const user = localStorage.getItem('uid')

  useEffect(() => {
    if (image !== false) {
      try {
        setviewImage(URL.createObjectURL(image))
      } catch (err) {
        setviewImage('')
        setImage(false)
      }
    }
  }, [image])

  // HANDLECHANGE ============
  const handleChange = (e) => {
    setImage(e.target.files[0])
  }

  //SUB SUBMIT ===========================
  const uploadSingleFile = async () => {
    const ext = image?.name?.substr(-4);
    if (ext === '.png' || ext === 'jpeg' || ext === '.jpg') {
      const formData = new FormData();
      formData.append('archivo', image)
      const res = await singleFileUpload(formData, user)
      setResult(res);
    } else {
      // setLoading(false)
      let res = 'Solo son admitidas las imágenes de tipo (png, jpg, jpeg)'
      setResult(res);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (image !== false) {
      uploadSingleFile();
    }

    if (images !== "") {
      for (let key in images) {
        if (images[key].length < 50) {
          setResult("Una de las imágenes de la galería está mal ingresada\n");
          return;
        
        }
      }
      const res = await MultipleFileUpload({ image: images }, user);
      setResult(res);
      setImages("");
    }

    if(image !== false || images !== ''){
      setRedirect(true)
    }
  }

  return (
    <div className={'main'}>
      {redirect ? (<Redirect to={`/web/profile2`} />) : false}

      <form onSubmit={handleSubmit}>
        <div className="container-fluid bg-white">
          <div className="row mb-3">
            <div className="col p-0">
              <img src={Banner} alt="programandoweb" className="img-fluid" loading="lazy"/>
            </div>
          </div>
          <div className="row justify-content-center pt-3">

            <div className="col-12 col-sm-4 bg-white mb-3">
              <hr></hr>
              <center>Añade una foto actual de ti para tu perfil</center>
              <hr></hr>
              {/* <h5>{context.Langs.tips[context.Lang]}</h5> */}
              <h5>Tips :</h5>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faCheck}
                  />
                </div>
                <div className="col">
                  Fotografía que muestre tu rostro de cerca y reciente
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faCheck}
                  />
                </div>
                <div className="col">
                  Puede ser una selfie sonriendo desde tu computadora o teléfono,
                  ante todo natural. Muéstrate tal cual eres.
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faCheck}
                  />
                </div>
                <div className="col">Procura que tu foto tenga buena luz</div>
              </div>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faTimes}
                  />
                </div>
                <div className="col">No se aceptan desnudos e imágenes inapropiadas </div>
              </div>
              <div className="row mb-5">
                <div className="col text-center">
                  {imageProfileStore !== '' &&
                    imageProfileStore !== false &&
                    imageProfileStore !== 'false' ? (
                    <div className="position-relative">
                      <div
                        className="position-absolute position-absolute-trash"
                      // onClick={() => deleteImgProfile()}
                      >
                        <FontAwesomeIcon size="1x" icon={faTrash} />
                      </div>
                      <img
                        src={imageProfileStore}
                        alt="programandoweb"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                  ) : (
                    <img
                      src={Banner2}
                      alt="programandoweb"
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                </div>
              </div>

              <div className="imageSaveProfile">
                <div className="imageSaveProfile-box">
                  <div>

                    {
                      image
                        ? (
                          <div className="imageSaveProfile-box__file">
                            <img src={viewImage} alt="Foto de perfil" loading="lazy" />
                            <p>{recortName(image?.name, 15)}</p>
                          </div>
                        )
                        : (
                          <div>
                            <FontAwesomeIcon size="3x" icon={faUpload} />
                            <p>Presiona o Arrastra tu foto para cargar en tu perfil</p>
                          </div>
                        )
                    }

                  </div>
                </div>
                <input
                  type="file"
                  // src=""
                  name="img"
                  accept="image/*"
                  size="5120"
                  onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-3">
              <hr></hr>
              <center>Agrega más fotos para tu galería</center>
              <hr></hr>
              <div className="pt-4">
                {/* "{context.Langs.text1[context.Lang]}" */}
                <center>
                  <h3>
                    “Si muestras más imágenes, les darás una idea más completa de
                    cómo eres". Generalmente se consiguen interesados con más
                    afinidades al hacerlo.
                  </h3>{' '}
                </center>
              </div>
              <div>{/* {context.Langs.text2[context.Lang]} */}</div>
              <p></p>
              <div>
                <div className="border-point p-5 text-center position-relative multi-image">

                  <div className="multi-image__Input">
                    <MultiImageInput
                      max={8}
                      images={images}
                      setImages={setImages}
                      cropConfig={{ crop, ruleOfThirds: true }}
                    />
                  </div>
                  {
                    images === ""
                      ? (
                        <div className="multi-image__notImage" >
                          <FontAwesomeIcon size="3x" icon={faUpload} />
                          <div>Presiona el cuadro de arriba</div>
                          <p>Al seleccionar la imagen, te pedirá cortar o "Crop" a tu gusto</p>
                        </div>
                      )
                      : (false)
                  }
                </div>
              </div>
              <p></p>
              Tips : <p></p>
              <div className="row mb-3 mt-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faCheck}
                  />
                </div>
                <div className="col">Sube fotos que hablen más de ti<br /> Ya sea de tu rostro o cuerpo entero</div>
              </div>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faCheck}
                  />
                </div>
                <div className="col">Podrías subir fotos de viajes, con amigos o realizando actividades que te gustan</div>
              </div>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faCheck}
                  />
                </div>
                <div className="col">Puedes subir hasta 8 imágenes</div>
              </div>
              <div className="row mb-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="1x"
                    icon={faTimes}
                  />
                </div>
                <div className="col">No se aceptan desnudos e imágenes inapropiadas</div>
              </div>
              {imageGalleryStore.length > 0 ? (
                <>
                  <div className="row">
                    {imageGalleryStore.map((row, key) => {
                      return (
                        <div
                          key={key}
                          className="col-12 col-sm-3 text-right position-relative"
                        >
                          <div
                            className="position-absolute position-absolute-trash"
                          // onClick={() => deleteImg(row)}
                          >
                            <FontAwesomeIcon size="1x" icon={faTrash} />
                          </div>
                          <img
                            src={row.image}
                            alt="programandoweb"
                            className="img-fluid border"
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                false
              )}
            </div>

          </div>
          <div className="row mb-3">
            <div className="col text-center">

              <input type="submit" value="Guardar" className="btn btn-primary2" />
            </div>
            <div className="col text-center">
              <Link to="/web/profile3" className="btn btn-primary2">
                Solo continuar
              </Link>
            </div>
          </div>
          <p>&nbsp;</p>

          {/* <IconLoading/> */}
          <MessageResponse result={result} setResult={setResult} />

        </div>
      </form>


    </div>
  );
};


export default WebMiPerfil2;