import React, { useState } from 'react';
import '../styles/CarruselVertical.css'
import BackgroundDefault from '../../assets/images/design/fondo-grey.png'
import { Redirect } from 'react-router';
import useAuth from '../../hooks/useAuth';


const CarruselVertical = ({ image1, image2, image3, project }) => {
  const [activa, setActiva] = useState(image1);
  /**
   * si esta logeado lo lleva a la pagina del proyecto
   * si NO  esta logeado lo lleva a la ventana de registro
  */
  const [redirect, setRedirect] = useState(false)
  const [redirectProject, setRedirectProject] = useState(false)
  const { isLogged } = useAuth();

  const handleClick = () => {
    setRedirect(!redirect)
  }

  const handleClickProject = () => {
    setRedirectProject(project ? false : !redirectProject)
  }

  return (
    <div className="row carrusel-container">
      {redirect ? <Redirect to="/auth/signin" /> : false}
      {redirectProject ? <Redirect to={`/proyectos/ver-proyecto/${activa?.id}`}/> : false}

      <div className={`col-12 col-sm-10 carrusel-image__box ${project ? 'project-img' : ''}`}>
        <img
          src={activa?.img ? activa?.img : BackgroundDefault}
          className="img-fluid sombra-2x"
          alt="Imagen del carrusel"
          loading="lazy"
        />
        <h5 onClick={isLogged ? handleClickProject : handleClick}>
          {activa?.name ? `${activa?.name}` : ' '}
        </h5>
      </div>

      <div className={`col-12 col-sm-2 ${project ? 'option-carrusel__vertical' : ''}`}>
        <div
          onClick={() => setActiva(image1)}
          className="item-carousel-vertical item-carrusel"
          style={{ backgroundImage: 'url(' + image1.img + ')' }}
        ></div>
        <div
          onClick={() => setActiva(image2)}
          className="item-carousel-vertical item-carrusel"
          style={{ backgroundImage: 'url(' + image2.img + ')' }}
        ></div>
        <div
          onClick={() => setActiva(image3)}
          className="item-carousel-vertical item-carrusel"
          style={{ backgroundImage: 'url(' + image3.img + ')' }}
        ></div>
      </div>
    </div>
  );
};
export default CarruselVertical;
