import React from 'react'
import {Link} from 'react-router-dom'

const WebCompra = () => {
  return (
    <div className={'main'}>

      <div className="container-fluid bg-white text-center">
        <div className="row">
          <div className="col p-0 pt-3 pb-3">
            <div className="h1 mb-2"> Gracias por tu compra</div>
            <Link to="/">Continuar</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebCompra;