import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Banner from '../../assets/images/design/img-proceso-de-registro-4.jpg';
import Banner2 from '../../assets/images/design/img--inferior-proceso-de-registro-4.jpg';

import '../styles/WebMiPerfil.css'
import useCheckboxParejaIdeal from '../../hooks/useCheckboxParejaIdeal';
import useCheckboxRazones from '../../hooks/useCheckboxRazones';
import useCheckboxPiensas from '../../hooks/useCheckboxPiensas';

import { fetchPost } from '../../helpers/helpCore'
import MessageResponse from '../components/MessageResponse';


const initialState = {
  deporte: '',
  hobbie: '',
  musica: '',
  parejaIdeal: [],
  razones: [],
  piensas: []
}

const WebMiPerfil4 = () => {
  const [inputs, setInputs] = useState(0);
  const [form, setForm] = useState(initialState);
  const [result, setResult] = useState('');
  const reload = false;
  const [idUser, setIdUser] = useState(null);
  const { deporte, hobbie, musica, razones, parejaIdeal, piensas } = form;
  const [redirect, setRedirect] = useState(false);
  const itemsParejaIdeal = document.querySelectorAll('[name="checkboxParejaIdeal"]');
  const itemsRazones = document.querySelectorAll('[name="checkboxRazones"]');
  const itemsPiensas = document.querySelectorAll('[name="checkboxPiensas"]');
  const url = '/detalles'
  const usuario = localStorage.getItem('uid');
  //===VERIFICACION DE DATOS EXISTENTES POR USUARIOS
  useEffect(() => {
    const idUrl = localStorage.getItem('uid');

    fetchPost({ idUrl, url: '/detalles' })
      .then((res) => {
        setIdUser(res ? res._id : false)
      })
  }, [])
  //====================================================

  // ============= CUSTOM HOOKS ======================
  const {
    checkedParejaIdeal,
    onChangeParejaIdeal,
    handleClickParejaIdeal,
  } = useCheckboxParejaIdeal(itemsParejaIdeal)

  const {
    checkedRazones,
    onChangeRazones,
    handleClickRazones,
  } = useCheckboxRazones(itemsRazones)

  const {
    checkedPiensas,
    onChangePiensas,
    handleClickPiensas,
  } = useCheckboxPiensas(itemsPiensas)
  //=================================================

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const addInfoForm = () => {
    const dataParejaIdeal = handleClickParejaIdeal()
    const dataPiensas = handleClickPiensas()
    const dataRazones = handleClickRazones()

    setForm({
      ...form,
      razones: dataRazones,
      piensas: dataPiensas,
      parejaIdeal: dataParejaIdeal
    })

    return { dataRazones, dataParejaIdeal, dataPiensas }
  }

  const detallesUpdate = ({ dataParejaIdeal, dataRazones, dataPiensas }) => {
    const idUrl = usuario;
    fetchPost({
      deporte: form.deporte,
      hobbie: form.hobbie,
      musica: form.musica,
      intereses: dataParejaIdeal,
      quererRelacion: dataRazones,
      importanteRelacion: dataPiensas,
      formAll: true,
      usuario: usuario,
      _id: idUser,
      idUrl,
      method: "PUT",
      url: '/detalles'
    })
      .then(res => {
        // setResult(res?.msg);
        setRedirect(!redirect);
      })

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { dataParejaIdeal, dataRazones, dataPiensas } = addInfoForm()

    if (inputs.deporte === '' || inputs.hobbie === '' || inputs.musica === '') { return setResult('Debes elegir una opción en deporte, hobbie y musica') }
    if (dataParejaIdeal.length !== 1) { return setResult('Pareja ideal debe tener una opciones elegidas') }
    if (dataRazones.length !== 2) { return setResult('Principales razones debe tener 2 opciones elegidas') }
    if (dataPiensas.length !== 3) { return setResult('Que piensas debe tener 3 opciones elegidas') }


    if (deporte !== '' && hobbie !== '' && musica !== '' && dataParejaIdeal.length === 1
      && dataRazones.length === 2 && dataPiensas.length === 3) {

      if (idUser === false) {
        // Intereses
        fetchPost({
          deporte: form.deporte,
          hobbie: form.hobbie,
          musica: form.musica,
          intereses: dataParejaIdeal,
          quererRelacion: dataRazones,
          importanteRelacion: dataPiensas,
          formAll: true,
          usuario: usuario,
          url
        }).then(data => {
          setRedirect(!redirect)
        })
      } else {
        detallesUpdate({ dataParejaIdeal, dataRazones, dataPiensas })
      }
    }



  }

  return (
    <div className={'main bg-white'}>
      {redirect ? (<Redirect to={`/web/profile5`} />) : false}
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute text-white w-100 pt-5 mt-5">
          <h3 className="text-center">¡Ya falta poco!</h3>
          <div className="text-center">
            <p>Saber las cosas que te gustan es importante en ME2YOU</p>
          </div>
        </div>
      </section>

      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-0">
            <img src={Banner} alt="programandoweb" className="img-fluid" loading="lazy" />
          </div>
        </div>
      </div>

      <section>
        <div className="container mt-4">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-6 perfil-select">
                {/* //====================== */}
                <div className="row pb-2">
                  <select
                    name="deporte"
                    id="deporte"
                    onChange={handleChange}
                    className="form-control w-100"
                    selectDefault="Elige tu deporte favorito"
                    value={form.deporte}
                  >
                    <option value="">Elige tu deporte favorito</option>
                    <option value="Futbol">Futbol</option>
                    <option value="Rugbi, adaptación de rugby">Rugbi, adaptación de rugby</option>
                    <option value="Natacion">Natacion</option>
                    <option value="Hockey">Hockey</option>
                    <option value="Sóftbol y sófbol">Sóftbol y sófbol</option>
                    <option value="Bádminton">Bádminton</option>
                    <option value="Béisbol, béisbol o pelota">Béisbol, béisbol o pelota</option>
                    <option value="Balonmano o handball">Balonmano o handball</option>
                    <option value="Bowling">Bowling</option>
                    <option value="Canotaje ">Canotaje </option>
                    <option value="Clavados o saltos">Clavados o saltos</option>
                    <option value="Esgrima">Esgrima</option>
                    <option value="Judo ">Judo </option>
                    <option value="Karate ">Karate </option>
                    <option value="Halterofilia o levantamiento de pesas">Halterofilia o levantamiento de pesas</option>
                    <option value="El maratón">El maratón</option>
                    <option value="Natación artística, nado sincronizado o natación sincronizada">Natación artística, nado sincronizado o natación sincronizada</option>
                    <option value="Pentatlón y triatlón">Pentatlón y triatlón</option>
                    <option value="Ráquetbol o raquetball">Ráquetbol o raquetball</option>
                    <option value="Squash">Squash</option>
                    <option value="Tenis de mesa">Tenis de mesa</option>
                    <option value="Voleibol, vóleibol, balonvolea">Voleibol, vóleibol, balonvolea</option>
                    <option value="Wakeboard">Wakeboard</option>
                    <option value="Waterpolo o polo acuático">Waterpolo o polo acuático</option>
                  </select>
                </div>
                {/* //====================== */}
                {/* //====================== */}
                <div className="row pb-2">
                  <select
                    name="hobbie"
                    id="hobbie"
                    onChange={handleChange}
                    className="form-control w-100"
                    selectDefault="Elige tu hobbie favorito"
                    value={form.hobbie}
                  >
                    <option value="">Elige tu hobbie favorito</option>
                    <option value="Actuar en teatro">Actuar en teatro</option>
                    <option value="Aerobic">Aerobic</option>
                    <option value="Aikido">Aikido</option>
                    <option value="Ajedrez">Ajedrez</option>
                    <option value="Animación (creación de cine de animación y dibujos animados)">Animación (creación de cine de animación y dibujos animados)</option>
                    <option value="Aquagym">Aquagym</option>
                    <option value="Aromaterapia">Aromaterapia</option>
                    <option value="Arte: asistir a espectáculos culturales (teatro, música, danza, ópera, zarzuela)">Arte: asistir a espectáculos culturales (teatro, música, danza, ópera, zarzuela)</option>
                    <option value="Asociaciones (pertenencia a grupos sociales de tipo artístico, deportivo, festivo, caridad etc.)">Asociaciones (pertenencia a grupos sociales de tipo artístico, deportivo, festivo, caridad etc.)</option>
                    <option value="Atletismo">Atletismo</option>
                    <option value="Bailar">Bailar</option>
                    <option value="Banda o grupo musicales">Banda o grupo musicales</option>
                    <option value="Estampación de tejidos">Estampación de tejidos</option>
                    <option value="Béisbol">Béisbol</option>
                    <option value="Belleza y estética (afición a)">Belleza y estética (afición a)</option>
                    <option value="Bicicleta (ciclismo)">Bicicleta (ciclismo)</option>
                    <option value="Billar">Billar</option>
                    <option value="Bingo">Bingo</option>
                    <option value="Blog (y video blog)">Blog (y video blog)</option>
                    <option value="Bolos (bowling, boliche)">Bolos (bowling, boliche)</option>
                    <option value="Boxeo">Boxeo</option>
                    <option value="Caballo (montar, hípica, equitación)">Caballo (montar, hípica, equitación)</option>
                    <option value="Caligrafía artística y lettering">Caligrafía artística y lettering</option>
                    <option value="Campismo">Campismo</option>
                    <option value="Cantar">Cantar</option>
                    <option value="Carpintería">Carpintería</option>
                    <option value="Carreras por montaña">Carreras por montaña</option>
                    <option value="Caza (deportiva)">Caza (deportiva)</option>
                    <option value="Cerámica">Cerámica</option>
                    <option value="Cicloturismo">Cicloturismo</option>
                    <option value="Cocina">Cocina</option>
                    <option value="Composición musical">Composición musical</option>
                    <option value="Comprar (o vender: ropa, complementos, objetos, etc.)">Comprar (o vender: ropa, complementos, objetos, etc.)</option>
                    <option value="Conferencias (asistir)">Conferencias (asistir)</option>
                    <option value="Cosmética casera">Cosmética casera</option>
                    <option value="Costura, corte y confección">Costura, corte y confección</option>
                    <option value="Dibujo artístico">Dibujo artístico</option>
                    <option value="Diseño de ropa (moda)">Diseño de ropa (moda)</option>
                    <option value="Diseño y creación de páginas web">Diseño y creación de páginas web</option>
                    <option value="Escribir literatura (prosa, poesía, teatro, ensayo, guiones)">Escribir literatura (prosa, poesía, teatro, ensayo, guiones)</option>
                    <option value="Escultura">Escultura</option>
                    <option value="Estudiar (idiomas, otras asignaturas etc.)">Estudiar (idiomas, otras asignaturas etc.)</option>
                    <option value="Fotografía">Fotografía</option>
                    <option value="Futbol">Futbol</option>
                    <option value="Gimnasia de mantenimiento y fitness">Gimnasia de mantenimiento y fitness</option>
                    <option value="Historia (afición a la)">Historia (afición a la)</option>
                    <option value="Huerto casero">Huerto casero</option>
                    <option value="Karaoke">Karaoke</option>
                    <option value="Karate">Karate</option>
                    <option value="Madera (tallas en madera)">Madera (tallas en madera)</option>
                    <option value="Magia (ilusionismo, prestidigitación)">Magia (ilusionismo, prestidigitación)</option>
                    <option value="Meditación">Meditación</option>
                    <option value="Música (afición a la)">Música (afición a la)</option>
                    <option value="Nadar (natación)">Nadar (natación)</option>
                    <option value="Naturaleza (interés por la)">Naturaleza (interés por la)</option>
                    <option value="Paintball">Paintball</option>
                    <option value="Pasatiempos (crucigramas, sudokus, infantiles, memoria, etc…)">Pasatiempos (crucigramas, sudokus, infantiles, memoria, etc…)</option>
                    <option value="Pasear">Pasear</option>
                    <option value="Pesca">Pesca</option>
                    <option value="Pintura artística">Pintura artística</option>
                    <option value="Podcasts (afición/creación)">Podcasts (afición/creación)</option>
                    <option value="Robótica">Robótica</option>
                    <option value="Roller derby">Roller derby</option>
                    <option value="Rollerski y cross skating">Rollerski y cross skating</option>
                    <option value="Rugby">Rugby</option>
                    <option value="Scalextric">Scalextric</option>
                    <option value="Scrapbook (album de recortes)">Scrapbook (album de recortes)</option>
                    <option value="Senderismo">Senderismo</option>
                    <option value="Series de tv (afición a las series)">Series de tv (afición a las series)</option>
                    <option value="Tenis">Tenis</option>
                    <option value="Videojuegos">Videojuegos</option>
                    <option value="Voleibol">Voleibol</option>
                    <option value="Voluntariado social">Voluntariado social</option>
                    <option value="Yoga">Yoga</option>
                    <option value="Youtuber">Youtuber</option>
                    <option value="Zumba">Zumba</option>

                  </select>
                </div>
                {/* //====================== */}


                {/* //====================== */}
                <div className="row pb-2">
                  <select
                    name="musica"
                    id="musica"
                    onChange={handleChange}
                    className="form-control w-100"
                    selectDefault="Elige el tipo de música que escuchas"
                    value={form.musica}
                  >
                    <option value="">Elige el tipo de música que escuchas</option>
                    <option value="Jazz">Jazz</option>
                    <option value="Bluzz">Bluzz</option>
                    <option value="Rock">Rock</option>
                    <option value="Salsa">Salsa</option>
                    <option value="Pop">Pop</option>
                    <option value="Hip-hop">Hip-hop</option>
                    <option value="Baladas">Baladas</option>
                    <option value="Bachata">Bachata</option>
                    <option value="Blues">Blues</option>
                    <option value="Bolero">Bolero</option>
                    <option value="Clásica">Clásica</option>
                    <option value="Corrido">Corrido</option>
                    <option value="Country">Country</option>
                    <option value="Cumbia">Cumbia</option>
                    <option value="Disco">Disco</option>
                    <option value="Electrónica">Electrónica</option>
                    <option value="Electro Pop">Electro Pop</option>
                    <option value="Flamenco">Flamenco</option>
                    <option value="Folk">Folk</option>
                    <option value="Funk">Funk</option>
                    <option value="Gospel">Gospel</option>
                    <option value="Hard Rock">Hard Rock</option>
                    <option value="Heavy Metal">Heavy Metal</option>
                    <option value="Hip Hop">Hip Hop</option>
                    <option value="House">House</option>
                    <option value="Indie">Indie</option>
                    <option value="Jazz">Jazz</option>
                    <option value="K-Pop">K-Pop</option>
                    <option value="Merengue">Merengue</option>
                    <option value="Pop">Pop</option>
                    <option value="Punk">Punk</option>
                    <option value="Ranchera">Ranchera</option>
                    <option value="Rap">Rap</option>
                    <option value="Rap Rock">Rap Rock</option>
                    <option value="Reggae">Reggae</option>
                    <option value="Reggaeton">Reggaeton</option>
                    <option value="Rock">Rock</option>
                    <option value="Rock and Roll">Rock and Roll</option>
                    <option value="Rumba">Rumba</option>
                    <option value="Rhythm and Blues">Rhythm and Blues</option>
                    <option value="Salsa">Salsa</option>
                    <option value="Samba">Samba</option>
                    <option value="Soul">Soul</option>
                    <option value="Swing">Swing</option>
                    <option value="Tango">Tango</option>
                    <option value="Techno">Techno</option>
                    <option value="Trap">Trap</option>
                    <option value="Trova">Trova</option>
                    <option value="Vals">Vals</option>
                    <option value="Vallenato">Vallenato</option>

                  </select>
                </div>
                {/* //====================== */}
                <div className="position-relative">
                  <div className="position-absolute w-100">
                    <div className="p-4 text-justify text-white">
                      <div className="text-center">
                        <b>A TODOS NUESTROS MIEMBROS</b>
                      </div>
                      <div className="p-4">
                        <div className="mb-3">
                          Los invitamos a ayudar a crear un gran sitio en el que
                          todos
                          <br />
                          seamos respetuosos y tengamos buenos modales
                        </div>
                        <div className="mb-3">
                          Si alguien llega a ser irrespetuoso contigo, te
                          sugerimos no
                          <br />
                          engancharte y denunciar el ofensivo, sin concesiones al
                          <br /> respecto. Gracias
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={Banner2} className="img-fluid" alt="pgrw" loading="lazy"/>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="row mb-3">
                  <div className="col-12 mb-1 text-center">
                    <b>Háblanos de tus intereses en el amor</b>
                  </div>
                  <div className="col-12 mb-4 text-center">
                    ¿Cómo es tu pareja ideal? (Escoger 1)
                  </div>
                  <div className="col-12">
                    {!reload ? (
                      <>
                        <div className="row mb-3 pareja-ideal">{/**/}
                          <div className="col-12 col-sm-6  text-center mb-2 ">
                            <input
                              type="checkbox"
                              defaultChecked={checkedParejaIdeal}
                              value="Trabajador para asegurar el bienestar familiar"
                              name="checkboxParejaIdeal"
                              id="Trabajador para asegurar el bienestar familiar"
                              onChange={onChangeParejaIdeal}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Trabajador para asegurar el bienestar familiar">
                              Trabajador para asegurar el bienestar familiar
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedParejaIdeal}
                              value="Compartir intereses, propósitos y crear acuerdos conmigo"
                              name="checkboxParejaIdeal"
                              id="Compartir intereses, propósitos y crear acuerdos conmigo"
                              onChange={onChangeParejaIdeal}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Compartir intereses, propósitos y crear acuerdos conmigo">
                              Compartir intereses, propósitos y crear acuerdos conmigo
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedParejaIdeal}
                              value="Ser muy cariñoso, atento y comunicarse en todo momento"
                              name="checkboxParejaIdeal"
                              id="Ser muy cariñoso, atento y comunicarse en todo momento"
                              onChange={onChangeParejaIdeal}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Ser muy cariñoso, atento y comunicarse en todo momento">
                              Ser muy cariñoso, atento y comunicarse en todo momento
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedParejaIdeal}
                              value="Atento pero no empalagoso"
                              name="checkboxParejaIdeal"
                              id="Atento pero no empalagoso"
                              onChange={onChangeParejaIdeal}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Atento pero no empalagoso">
                              Atento pero no empalagoso
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="col-12 mb-4 text-center">
                    Adicionalmente del amor hacia una pareja.
                    <br />
                    ¿Cuáles son las principales razones para querer una relación? (Escoger 2)
                  </div>
                  <div className="col-12">
                    {!reload ? (
                      <>
                        <div className="row mb-3 razones">
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedRazones}
                              value="Para no estar sol@"
                              name="checkboxRazones"
                              id="Para no estar sol@"
                              onChange={onChangeRazones}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Para no estar sol@">
                              Para no estar sol@
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedRazones}
                              value="Intimidad"
                              name="checkboxRazones"
                              id="Intimidad"
                              onChange={onChangeRazones}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Intimidad">
                              Intimidad
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/* Apoyo y admiración mútua para lograr mejores cosas como pareja */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedRazones}
                              value="Apoyo y admiración mútua para lograr mejores cosas como pareja"
                              name="checkboxRazones"
                              id="Apoyo y admiración mútua para lograr mejores cosas como pareja"
                              onChange={onChangeRazones}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Apoyo y admiración mútua para lograr mejores cosas como pareja">
                              Apoyo y admiración mútua para lograr mejores cosas como pareja
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedRazones}
                              value="Tener hijos y crear una familia"
                              name="checkboxRazones"
                              id="Tener hijos y crear una familia"
                              onChange={onChangeRazones}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Tener hijos y crear una familia">
                              Tener hijos y crear una familia
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="col-12 mb-4 text-center">
                    ¿Qué piensas es lo más importante en una relación? (Escoger 3)
                  </div>
                  <div className="col-12">
                    {!reload ? (
                      <>
                        <div className="row mb-3 piensas">
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/* Comunicación, acuerdos y creación */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Comunicación, acuerdos y creación"
                              name="checkboxPiensas"
                              id="Comunicación, acuerdos y creación"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Comunicación, acuerdos y creación">
                              Comunicación, acuerdos y creación
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/* Darle mucho espacio al otro */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Darle mucho espacio al otro"
                              name="checkboxPiensas"
                              id="Darle mucho espacio al otro"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Darle mucho espacio al otro">
                              Darle mucho espacio al otro
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/* Mantenerse en la rutina */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Mantenerse en la rutina"
                              name="checkboxPiensas"
                              id="Mantenerse en la rutina"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Mantenerse en la rutina">
                              Mantenerse en la rutina
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/* Hacer la vida más fácil y pacífica para ambos */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Hacer la vida más fácil y pacífica para ambos"
                              name="checkboxPiensas"
                              id="Hacer la vida más fácil y pacífica para ambos"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Hacer la vida más fácil y pacífica para ambos">
                              Hacer la vida más fácil y pacífica para ambos
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Impulsar a hacer las cosas bien mutuamente"
                              name="checkboxPiensas"
                              id="Impulsar a hacer las cosas bien mutuamente"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Impulsar a hacer las cosas bien mutuamente">
                              Impulsar a hacer las cosas bien mutuamente
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/* Confianza, respeto y fidelidad */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Confianza, respeto y fidelidad"
                              name="checkboxPiensas"
                              id="Confianza, respeto y fidelidad"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Confianza, respeto y fidelidad">
                              Confianza, respeto y fidelidad
                            </label>
                          </div>
                          <div className="col-12 col-sm-6  text-center mb-2">
                            {/*  */}
                            <input
                              type="checkbox"
                              defaultChecked={checkedPiensas}
                              value="Apoyarnos en todo momento"
                              name="checkboxPiensas"
                              id="Apoyarnos en todo momento"
                              onChange={onChangePiensas}
                            />
                            <label
                              className="btn btn-outline-primary2 btn-block"
                              htmlFor="Apoyarnos en todo momento">
                              Apoyarnos en todo momento
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      false
                    )}
                    <MessageResponse
                      result={result}
                      setResult={setResult}
                    />
                  </div>


                </div>
                <div className="row mb-3">
                  <div className="col text-right">
                    <input
                      className="btn btn-primary2"
                      type="submit"
                      value={'Guardar'}
                    />
                  </div>
                  <div className="col text-center">
                    {idUser
                      ? (<Link className="btn btn-primary2" to="/web/profile5">
                        Solo continuar
                      </Link>)
                      : false
                    }
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );

}

export default WebMiPerfil4;











//  Pop




//  Rock
//  Metal
//  Hop



// -Pop





//  Rock



//  and Roll

//  and Blues









