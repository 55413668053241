import React from 'react';

import { Link } from 'react-router-dom';
import contact from '../../assets/images/design/Contactanos-24:7.png'
import phone from '../../assets/images/design/Telefono-de-contacto.png'
import mail from '../../assets/images/design/Correo-de-contacto.png'
import '../styles/Layout.css'

const Footer = () => {
  return (
    <footer className="pt-5 pb-3 mb-3 text-center color-gray text-white">
      <div className="container mb-5">
        <div className="row">
          <div className="col text-center">
            <div className="h1 title-footer">Contáctanos</div>
            <div className="h6">
            Mándanos un mensaje, nos interesa poder servirte mejor.
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-12">
        <div className="row footer-icons">
          <div className="col footer-icons__icons footer-icons__singup">
            <div>
              <Link
                className="text-white h4"
                to='/auth/signup'>
                <img src={phone} alt="" loading="lazy"/>
                Regístrate aquí
              </Link>
              {/* <FontAwesomeIcon size="6x" icon={faUser} /> */}

            </div>

          </div>
          <div className="col footer-icons__icons footer-icons__help">
            <div>
              <Link
                className="text-white h4"

                to='/web/contact'
              >
                <img src={mail} alt="Contactenos"  loading="lazy"/>
                <span>Soporte Técnico</span>
              </Link>


              {/* <FontAwesomeIcon size="6x" icon={faHeadphonesAlt} /> */}
            </div>


          </div>
          <div className="col footer-icons__icons footer-icons__report">
            <div>
              <Link
                className="text-white h4"
                to='/web/report'>
                <img src={contact} alt="Contactenos" loading="lazy"/>
                Reporta a un miembro
              </Link>
              {/* <FontAwesomeIcon size="6x" icon={faUserSecret} /> */}

            </div>


          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-1 bg-white p-1 rounded-circle "></div>
        </div>

        <div className="row justify-content-center mt-5 footer-text">
          <div>
            <h5>
              <Link to="/politicas-privacidad">
                POLÍTICAS DE PRIVACIDAD
              </Link>
            </h5>
            <h5>
              <Link to="/terminos-condiciones">
                TÉRMINOS Y CONDICIONES
              </Link>
            </h5>

          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
