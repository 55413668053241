import React, { useState } from 'react';

const Input = (props) => {
  let limiteCaracteres = false;

  if (props.limiteCaracteres) {
    limiteCaracteres = props.limiteCaracteres;
  }

  const [limite, setLimite] = useState(props.limiteCaracteres);

  const onChange = (e) => {
    setLimite(limiteCaracteres - e.target.value.length);
    props.onChange(e);
  };
  return (
    <div className="row pb-2">
      <div
        className={
          props.classNameMain !== undefined ? props.classNameMain : 'col'
        }
      >
        <div
          className={
            props.classNameLabel !== undefined ? props.classNameLabel : ''
          }
        >
          <b>
            {props.title}{' '}
            {limiteCaracteres ? ' | Límite óptimo (' + limite + ')' : false}
          </b>
        </div>
        <div>
          <input
            autoComplete={props.autocomplete ? props.autocomplete : 'on'}
            type={props.type !== undefined ? props.type : 'text'}
            name={props.name !== undefined ? props.name : ''}
            required={props.required !== undefined ? props.required : ''}
            disabled={props.disabled !== undefined ? props.disabled : ''}
            className={
              props.className !== undefined
                ? props.className + ' form-control-border'
                : ' form-control-border '
            }
            onChange={onChange}
            placeholder={props.placeholder}
            value={props.defaultValue}
          />
        </div>
      </div>
    </div>
  );
};

export default Input;
