import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom' 
import Header from './Header';
import Footer from './Footer';
// import Footer from './Footer';
import '../styles/Layout.css'


//CREACION DE LAYOUT PARA CONTENER EL HEADER Y FOOTER DE LA APLICACION
const Layout = ({ children }) => {
  //Validacion para la aparicion del footer en la seccion de mensajes
  const location = useLocation();
  
  return (
    <Fragment>
      <Header />
      {children}
      {location?.pathname === '/web/mensajes'
        ? (false)
        : <Footer />}

    </Fragment>
  );
};

export default Layout;