import React from 'react';
import { Link } from 'react-router-dom'

import ico1 from '../../assets/images/design/ico-1.png';
import ico2 from '../../assets/images/design/ico-2.png';
import ico3 from '../../assets/images/design/ico-3.png';

const Triseccion2 = () => {

  const data = [
    {
      ico: ico1,
      text1: 'n ',
      text: 'Parejas\n\r ',
      text2:
        'Crea amigos o ENCUENTRA A TU PAREJA IDEAL con propósitos afines. La vida es más placentera cuando la vives con quien comparte y disfruta tus sueños. ¡HOY INSCRIPCIONES GRATIS!'
      ,
      url: '/web/buscar'
    },
    {
      ico: ico2,
      text: 'Ayuda ',
      text2:
        'Ya sea que busques ayuda o quieras darla, estás en el lugar correcto. Da clic aquí y dinos: ¿CON QUÉ NECESITAS AYUDA? O bien, ¡Brinda tu AYUDA A ALGUIEN!',
      url: '/ayuda'
    },
    {
      ico: ico3,
      text: 'Donaciones',
      text2:
        'DONA A UN PROYECTO. No hay actividad más satisfactoria que brindar ayuda. Explora los proyectos que tenemos en nuestro sitio y decide a cuál quieres contribuir. Es muy sencillo y harás una gran diferencia. DONA HOY.',
      url: '/proyectos'
    },
  ];

  const divStyle = {
    fontSize: '16px',
  };

  return (
    <section className="Triseccion2 pb-5">
      <div className="container">
        <div className="row text-center p-5">
          {data.map((row, key) => {
            return (
              <div key={key} className="col-12 col-sm-4 mb-3">
                <div className="p-2 item ">
                  <div className="row justify-content-center">
                    <div className="col-5">
                      <Link to={row.url}>
                        <img
                          src={row.ico}
                          className="img-fluid rounded-circle border bg-white sombra-2x"
                          alt="Programandoweb"
                          loading="lazy"
                        />
                      </Link>

                    </div>
                    <div className="col-9 p-2">
                      <h2 className="h5"><b>{row.text}</b></h2>
                    </div>
                  </div>
                  <p></p>
                  <p></p>
                  <div className="row justify-content-center  mt-1 mb-3  text-left">
                    <div className="col-12 content" style={divStyle}>{row.text2}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Triseccion2;
