import React, { useState, useEffect } from 'react'
import '../styles/HelpPage.css'
import { fetchPost } from '../../helpers/helpCore';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import dateFormat from '../../helpers/dateFormat';
import ReactionPost from '../components/ReactionPost';
import CreateComment from '../components/CreateComment';

import '../styles/CommentPost.css'
import ContentComment from '../components/ContentComment';
import IconLoading from '../components/IconLoading';
import TextPost from '../components/TextPost';

const initialData = {
  img: '',
  name: '',
  created: '',
  dataPost: '',
  reaction: 0,
  reactionName: '',
}

const HelpPostComment = () => {
  const [data, setData] = useState(initialData)
  const [comment, setComment] = useState([])
  const [commentValue, setCommentValue] = useState(0)
  const [postComment, setPostComment] = useState('');
  const [loading, setLoading] = useState(false)
  const [validatePost, setValidatePost] = useState(true)
  const location = useLocation();
  const { pathname } = location;
  const idPost = pathname.slice(7);
  const token = localStorage.getItem('jwt');
  const { logout } = useAuth()
  const id = localStorage.getItem('uid')


  useEffect(() => {
    if (data.length !== 0) {
      setLoading(true)
      const url = '/post/viewComment';
      fetchPost({ url, idPost, token })
        .then(res => {
          setLoading(false);
          setComment(res);
        })
    }
    // eslint-disable-next-line
  }, [postComment])

  useEffect(() => {
    const url = '/post/viewPost';
    fetchPost({ url, idPost, token })
      .then(res => {
        if (res === false) {
          logout();
        } else {
          if (res === 'No se encontro publicación') {
            setValidatePost(false);
          } else {
            setData(res);
            setCommentValue(res?.Comment)
          }
        }
      })
      // eslint-disable-next-line
  }, [postComment])

  return (
    <div className="HelpPage">
      <div className="HelpPage-container">
        {
          validatePost === true
            ? (
              <div className="HelpPage-container__post">
                <div className="PostContainer">
                  {/* si esta solo POST img, es porque existe una imagen */}
                  <div className={`Post ${data?.img !== null ? 'img' : ''}`}>
                    {
                      data?.img !== null
                        ? (
                          <div className={`Post-img ${data?.img !== null ? 'img' : ''}`}>
                            <img src={data?.img} alt="Foto de publicación" />
                          </div>
                        )
                        : (false)
                    }
                    {/* <div className="Post-data"> */}
                    <div className={`Post-data ${data?.img !== null ? 'img' : ''}`}>
                      <h5><strong>{data?.nameUser}</strong>{' '} <span>Publicado: {dateFormat(data?.createdAt)}</span></h5>
                      {/* <p>
                        {}
                      </p> */}
                      <TextPost text={data?.dataPost} />
                      <div className="Post-data__button">
                        <div className="Post-data__buttonBox">
                          <ReactionPost
                            idPost={data?._id} //id del post
                            reactionValue={data?.reaction} //cant de reacciones
                            reactionNameInPost={data?.reactionName} //nombre de la reaccion del usuario
                          />
                        </div>
                        <div className="Post-data__buttonBox">
                          <div className="buttonBox-Comment">
                            <input type="button" value={`Comentario ${commentValue}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* secciión de comentarios */}
                <div className="PostComment-container">
                  <div className="PostComment-container__Box">
                    <div className="PostComment-container__title">
                      <h5>Comentarios</h5>
                    </div>
                    <div className="PostComment-container__createComment">
                      <CreateComment
                        idPost={idPost}
                        setPostComment={setPostComment}
                        commentValue={commentValue}
                        setCommentValue={setCommentValue}
                      />
                    </div>
                    <div className="PostComment-container__contentComment">
                      {loading && <IconLoading />}
                      {
                        comment?.length !== 0
                          ? (
                            comment?.map((data, key) => {
                              return (
                                <ContentComment
                                  key={key}
                                  name={data.nameUser}
                                  img={data.img}
                                  comment={data.comment}
                                  idUser={data.idUser}
                                  id={id}
                                  soy={data?.soy}

                                />
                              )
                            })
                          )
                          : (false)
                      }

                    </div>
                  </div>
                </div>
              </div>
            )
            : (
              <div className="HelpPage-container__post notPost">
                <h5>No se pudo encontrar la publicación</h5>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default HelpPostComment;