import React, { useEffect, useState } from 'react';
import { fetchPost } from '../../helpers/helpCore';
import '../styles/AdminNoteData.css';
import AdminUserNotes from '../components/AdminUserNotes';
import AdminPostNotes from '../components/AdminPostsNotes';
import IconLoading from '../components/IconLoading.jsx';
import useAuth from '../../hooks/useAuth';
import MessageResponse from '../components/MessageResponse';

const AdminNoteData = () => {
  const [viewNotes, setViewNotes] = useState([])
  const [viewNotesForId, setViewNotesForId] = useState(null)
  const [viewNotesTheUser, setViewNotesTheUser] = useState([])
  const [updateState, setUpdateState] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPost, setLoadingPost] = useState(false)
  const [result, setResult] = useState('')
  const [load, setLoad] = useState(false);
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    const url = '/admin/validateAdmin'
    fetchPost({ token, url })
      .then(res => {
        if (res === false) {
          logout()
        } else {

          setLoad(res);
        }
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLoading(true);
    fetchPost({ token, url: '/admin/viewNotes' })
      .then(res => {
        setLoading(false);
        setViewNotes(res)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (viewNotesForId !== null) {
      setLoadingPost(true);
      // setViewNotesTheUser([]);
      fetchPost({ token, url: '/admin/viewAllNotes', id: viewNotesForId })
        .then(res => {
          setLoadingPost(false);
          setViewNotesTheUser(res);
          setUpdateState(false)
        })
    }
    // eslint-disable-next-line
  }, [viewNotesForId, updateState])

  return (
    <div className="AdminNoteData">
      <div className="AdminNoteData-box">
        {
          load &&
          <div className="AdminNoteData-container">
            <div className="AdminNoteData-container__userPosts">
              <h5>Publicaciones por Usuarios</h5>
              <MessageResponse
                result={result}
                setResult={setResult}
              />
              <div className="userPosts-view">
                {loading && <IconLoading />}
                <div className="userPosts-view__note">
                  {
                    viewNotes.length !== 0
                      ? (
                        viewNotes?.map((data, key) => {
                          return (
                            <AdminUserNotes
                              name={data.name}
                              idUser={data.idUser}
                              countPost={data.countPost}
                              key={key}
                              setViewNotesForId={setViewNotesForId} />
                          )
                        })
                      )
                      : (false)
                  }
                </div>
              </div>
            </div>
            <div className="AdminNoteData-container__viewPosts">
              <h5>Publicaciones hechas</h5>
              {loadingPost && <IconLoading />}
              {
                viewNotesTheUser.length !== 0
                  ? (
                    viewNotesTheUser?.map((data, key) => {
                      return (
                        <AdminPostNotes
                          key={`${key}-${data.idPost}`}
                          name={data.name}
                          idUser={data.idUser}
                          status={data.status}
                          idPost={data.idPost}
                          reports={data.reports}
                          token={token}
                          setUpdateState={setUpdateState}
                          createdAt={data.createdAt}
                          setResult={setResult} />
                      )
                    })
                  )
                  : (false)
              }


            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default AdminNoteData;