import React from 'react';
import dateFormat from '../../helpers/dateFormat';
import { Link } from 'react-router-dom';
import { fetchPost } from '../../helpers/helpCore';


const AdminPostNotes = ({name,token,setUpdateState, reports, status, idPost, createdAt, setResult}) => {
  const handleUpdateState = (status, idPost) =>{
    fetchPost(
      {token, url:'/admin/hiddenNote',status, idPost, method:'PUT'}
    ).then(res => {
      setUpdateState(res);
      setResult(res?.msg)
    })
  }
  
  return (
    <div className="AdminNoteData-container__viewPost">
      <div className="viewPost-data">
        <span>{name}</span>
        <span>
          Publicado el: {dateFormat(createdAt)}
        </span>
        <span>
          {`Reportes: ${reports}`} 
        </span>
      </div>
      <div className="viewPost-button">
        <Link to={`/ayuda/${idPost}`}>
          Ver 
        </Link>

        <span 
          title={`${status === true ? 'Ocultar': 'Mostrar'} para los usuarios`}
          onClick={() => handleUpdateState(status, idPost)}
          className={`${status === false ? 'viewPost-button__active' :'' }`}
          >
          {status === true ? 'Ocultar' : 'Mostrar'}
        </span>
      </div>
    </div>
  )
}

export default AdminPostNotes;