import React from 'react';

// import Banner from './bannersAnimados';
import parejas1 from '../../assets/images/design/parejas1.jpg';
import parejas2 from '../../assets/images/design/parejas2.jpg';
import parejas3 from '../../assets/images/design/parejas3.jpg';
import BannerAnimado from './BannerAnimado';
import img7 from '../../assets/images/design/arrow-down.png';

const Monoseccion = (props) => {

  const data = [
    {
      src: parejas1,
      label:
        'Llevaba mas de 3 años buscando una pareja y aunque ya había buscado en otros sitios de parejas, no había acabado por encontrar a alguien que sintiera me complementaba… Hoy, gracias a Me2You puedo decir que por fin lo encontré! Es genial encontrar una pareja que tiene propósitos comunes y que camina contento tu mismo camino. Gracias Me2You!',
    },
    {
      src: parejas2,
      label:
        ' Por su química y sus romances hicieron historia. Comenzamos a celebrar San Valentín recordando a estas 50 parejas icónicas',
    },
    {
      src: parejas3,
      label:
        'Amor es un sentimiento muy abstracto que muy pocas parejas ... el viaje de una vida llena de éxitos con un denominador común',
    },
  ];

  return (
    <section className={props.className + ' Monoseccion mb-5 pb-4'}>
      <div className="container-fluid">
        <div className="row text-center mt-5">
          <div className="col">
            <div className="p-2 item position-relative">
              <div className="position-absolute-bg-exito">
                {/*eslint-disable-next-line*/}
                <img className="img-fluid" src={props.img2} loading="lazy" />
              </div>
              <div className="row justify-content-center">
                <div className="ico col-2 ">
                  <img
                    src={props.ico}
                    className="img-fluid rounded-circle border bg-white sombra-2x"
                    alt="Programandoweb" loading="lazy"
                  />
                </div>
                <div className="col-12 p-2">
                  <div className="content">
                    <h3 className="text-white h5">{props.text}</h3>
                    <div className="item text-center text-white">
                      <div className="h2">Sé parte</div>
                      <div className="row justify-content-center">
                        <div className="col-4">
                          Mantente en contacto
                          <div className="text-center">
                            <img src={img7} className=" mt-8 " alt="pgrw" loading="lazy" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <section className="p-5">
        <div className="container">
          <div className="row justify-content-center-">
            <div className="col-12">
              {/* <Banner data={data} /> */}
              <BannerAnimado data={data} />
            </div>
          </div>
        </div>
      </section>
    </section>

  )
}

export default Monoseccion;