import React from 'react'
import '../styles/Layout.css'

const TermsConditions = () => {
  return (
    <div className='PolicityPrivate-container'>
      
      <h2>Términos y Condiciones para Campañas de Donación de Me2you</h2>
      <article>
        <br />
        <p>1. <b>Objeto.</b> Estas Condiciones regulan el proceso de donación a través de la plataforma por internet propiedad de Me2you, que voluntariamente desean realizar los donantes que previamente hayan rellenando el correspondiente Formulario, con los datos necesarios para poder llevar a cabo la relación entre Me2you y los donantes, que hayan prestado su consentimiento y aceptación plena, sin reserva alguna, de todas y cada una de las condiciones publicadas por Me2you en cada momento en que el Donante acceda al referido sitio Web. El donante deberá leer íntegramente las Condiciones, cada vez que desee realizar una donación a Me2you a través de esta plataforma por internet. Las Condiciones se aplicarán a todas las donaciones que se deseen realizar en la plataforma por internet “Me2you”, rigiendo las que estén vigentes en el momento de realizar cada donación.</p>
          <br />
        <p>2. <b>Para los Donantes. Concepto y registro de donante.</b> Se entiende por donante aquel que acceda a la plataforma Me2you, que consigne los datos obligatorios que se soliciten y realice una donación siguiendo las correspondientes instrucciones de la plataforma y haya prestado previamente su consentimiento y aceptación plena con estas Condiciones. Me2you se reserva el derecho de cancelar la donación si la información suministrada se considera falsa o poco a veraz por Me2you.</p>
        <p>Los donantes no pueden recibir un reembolso, cuando realizan un donativo en línea a través de la plataforma Me2you a una Campaña de recaudación de fondos cubierta, solo podrá recibir un reembolso de la donación, el Donante que en la campaña de Donación, el organizador o beneficiario de la Campaña cometiera un Uso indebido de acuerdo a estos términos y condiciones.</p>
        <p>Para los fines de esta Política, “Uso indebido” significa cualquiera de los siguientes:</p>
        <p>El organizador no entrega los fondos al Beneficiario previsto (tal y como se define más abajo); El contenido de la campaña de recaudación de fondos es inexacto con respecto a un hecho sustancial sobre el organizador, beneficiario o finalidad, que Me2you determine que es el motivo principal en que se basaría un donante razonablemente atento para realizar una contribución; u otra conducta engañosa por parte del organizador o beneficiario que Me2you determine, a su entera discreción, que justifica un reembolso total o parcial.
          Si Me2you determina, a su entera discreción, que se ha producido un Uso indebido, actuara conforme a las leyes aplicables en materia civil y penal.</p>
        <p>Me2you, a su entera discreción, decide sobre todas las actuaciones a llevar a cabo, y todas sus decisiones son definitivas.</p>
        <br />
        <p>3. <b>Aceptación de las condiciones establecidas.</b> El Donante tiene acceso, en cualquier momento, a las instrucciones de utilización de la plataforma Me2you, así como a su utilización y aceptación de la plataforma Me2you. Por el simple hecho de navegar a través de la plataforma y/o por la realización de cualquier donación, el Donante acepta, sin reserva alguna, las instrucciones de navegación y, en especial, todas y cada una de Condiciones de Uso, su Aviso Legal y Política de Privacidad, así como las Condiciones particulares que, en su caso, rijan la realización de donaciones. En caso de disconformidad el usuario deberá salir cerrando la plataforma de Me2you. En el supuesto de cometer un error por parte del Donante en la introducción de los datos, éste debe comunicarlo a Me2you a través de teléfono, e-mail que aparecen como contacto en la plataforma de Me2you. El Donante declara, bajo su responsabilidad, que es mayor de edad, ha leído íntegramente las presentes Condiciones particulares de las donaciones ofertadas por Me2you; Así como las instrucciones e indicaciones que, para el desplazamiento o navegación por la referido plataforma de Me2you, figuran en cada una de las pantallas que lo integran, aceptándolas plenamente.</p>
        <p><b>Exclusiones del donante.</b> Las siguientes situaciones están excluidas y no dan lugar a la aplicación de esta Política:</p>
        <p>solicitar un reembolso por un donativo a tu propia campaña de recaudación de fondos;</p>
        <p>solicitar un reembolso por donativos no realizados a través de Internet y por ello no realizados en la Plataforma;</p>
        <p>arrepentirse de hacer el donativo;</p>
        <p>desacuerdo personal con el organizador o beneficiario o antipatía hacia los mismos;</p>
        <p>desacuerdo con la forma en que un organizador o beneficiario utiliza los fondos recaudados en exceso del objetivo establecido de la campaña de recaudación de fondos en el momento de dar tu donativo;</p>
        <p>desacuerdo sobre el instrumento de pago o medio por el que el organizador o el Beneficiario previsto recibirá los fondos;</p>
        <p>ofertas no entregadas o promesas, recompensas o promociones realizadas u ofrecidas por el organizador o beneficiario de la campaña de recaudación de fondos;</p>
        <p>donación a una campaña de recaudación de fondos que se haya eliminado por motivos distintos al Uso indebido;</p>
        <p>donativos a una organización benéfica;</p>
        <p>desacuerdo con Me2you por cualquier motivo; o
          cualquier motivo que no se base en la convicción documentada y objetiva de que se ha producido un Uso indebido.</p>
        <p>Es posible que puedas recibir un reembolso en algunos casos, incluso si no reúnes los requisitos para ello en virtud de esta Política. Ponte en contacto con Me2you si tienes alguna pregunta.</p>
        <p>
          <br />
          4. <b>Para los Beneficiarios.</b> Puedes tener derecho a recibir una contribución de Me2you cuando eres el Beneficiario previsto de una Campaña de recaudación de fondos cubierta, resides en un País Cubierto, se ha producido un Uso indebido en el sentido de que el organizador no te entregó los fondos recaudados por la Campaña de recaudación de fondos cubierta, y no se aplica ninguna exclusión.
        </p>
        <p>
          Exclusiones del beneficiario. Las siguientes situaciones están excluidas y no dan lugar al pago de prestaciones en virtud de esta Política:</p>
        <p>La no recepción de fondos debido a un embargo u otra retención;</p>
        <p>La decisión de Me2you de designar a un beneficiario, independientemente de si es el Beneficiario previsto de la campaña de recaudación de fondos;</p>
        <p>Un desacuerdo sobre el canal o instrumento de pago a través del cual recibirás los fondos (por ejemplo, un cheque en lugar de una transferencia bancaria electrónica);</p>
        <p>Campañas de recaudación de fondos en las que no se usaron los fondos entregados por Me2you para la finalidad de la campaña;</p>
        <p>Campañas de recaudación de fondos en las que no seas el Beneficiario previsto, incluso si incurres en los gastos para los que se recaudó el dinero;</p>
        <p>Consentimiento para que otra persona retire los donativos;</p>
        <p>Campañas de recaudación de fondos en las que el beneficiario sea una organización benéfica;</p>
        <p>Desacuerdo personal con el organizador o beneficiario o antipatía hacia los mismos; o
          casos en los que, por orden judicial, se haya ordenado la entrega de los donativos de una campaña de recaudación de fondos al Beneficiario previsto.</p>
        <br />
        <p>5. <b>Cómo presentar una reclamación.</b> Para realizar una reclamación en virtud de esta Política, debes cumplir los siguientes criterios:</p>
        <p>No causaste, contribuiste ni participaste en ningún Uso indebido o indujiste en este de ninguna manera, ya sea directa o indirectamente;</p>
        <p>Notificaste al organizador sobre tu preocupación, esperaste, al menos, 72 horas para que el organizador abordara tu inquietud, hiciste todo lo razonablemente posible para intentar revertir el Uso indebido antes de presentar una reclamación (por ejemplo puedes pedir al organizador pruebas del uso adecuado o distribución de los fondos), y el organizador no respondió o rechazó tu solicitud;</p>
        <p>Usted completó íntegramente el formulario de reclamación y atendió todas las solicitudes posteriores de información para nuestra evaluación y tramitación de tu reclamación, lo que incluye, en la mayoría de los casos, el suministro de pruebas del Uso indebido;</p>
        <p>aceptas cooperar plenamente con cualquier investigación por parte de Me2you o de cuerpos policiales u autoridades judiciales;</p>
        <p>aceptas notificar a Me2you si, en cualquier momento, recibes cualquier comunicación o fondos por parte del organizador; y</p>
        <p>aceptas que, si recibes una compensación por tu reclamación, por parte de cualquier persona o entidad que no sea Me2you (por ejemplo, si recibes un reembolso del organizador a través de un procedimiento judicial), devolverás a Me2you toda cantidad que se te abone en virtud de esta Política.</p>
        <p>Si eres un donante que presenta una reclamación en virtud de esta Política, debes cumplir todos los siguientes requisitos adicionales:</p>
        <p>sigues siendo usuario del instrumento de pago que utilizaste para realizar el donativo en cuestión y estás autorizado a aceptar una transferencia de fondos a dicho instrumento de pago;</p>
        <p>has enviado tu reclamación en el plazo de un año desde la fecha de tu donativo; y
no debes haber solicitado ya una solicitud de devolución ni haber recibido un reembolso por el mismo donativo.</p>
        <p>Si eres un beneficiario que presenta una reclamación en virtud de esta Política, debes cumplir todos los siguientes requisitos adicionales:
</p>
        <p>Debes ser identificado claramente por el contenido de la campaña de recaudación de fondos como beneficiario de la Campaña de recaudación de fondos cubierta;</p>
        <p>no debes haber recibido todos los fondos recaudados por la campaña de recaudación de fondos, excluidos reembolsos, donativos hechos fuera de Internet y cualquier otro cargo aplicable del procesador de pagos o de terceros;</p>
      <p>has presentado una reclamación en el plazo de un año desde la fecha de creación de la campaña de recaudación de fondos;</p>
      <p>has notificado el Uso indebido a cuerpos policiales locales o autoridades judiciales, en forma de denuncia oficial por escrito; y
a petición de Me2you, aceptas devolver los fondos contribuidos a Me2you, si los fondos que faltan a causa de un Uso indebido te son finalmente abonados por cualquier persona o entidad distinta de Me2you, incluido el organizador o de conformidad con una orden judicial.</p>
      <br />
      <p>6. <b>Reclamaciones abusivas.</b> Las reclamaciones abusivas incluyen, entre otras, lo siguiente:
</p>
      <p>presentar reclamaciones excesivas o duplicadas;</p>
      <p>tergiversar hechos o circunstancias relacionados con una campaña de recaudación de fondos o donativo, buscar pagos de otro modo en virtud de esta Política mediante fraude o engaño, o contribuir o participar en conductas engañosas por parte de cualquier usuario de Me2you (incluido el donante, el organizador o el beneficiario);</p>
      <p>en el caso de donantes, solicitar una devolución tras recibir un reembolso, presentar una reclamación en virtud de esta Política después de solicitar una devolución, o iniciar un pago o solicitud de reembolso a través de un instrumento de pago fraudulento; o
cualquier reclamación por una campaña de recaudación de fondos en la que hayas participado o que haya causado o inducido el Uso indebido, ya sea directa o indirectamente.
Los usuarios que presenten reclamaciones abusivas estarán sujetos a las consecuencias descritas en nuestros Términos de Servicio. Nos reservamos el derecho a investigar a cualquier persona por cualquier medio y a tomar las medidas adecuadas contra cualquier persona que, a nuestra entera discreción, infrinja cualquiera de las condiciones o el espíritu de esta Política o de los Términos de Servicio.</p>
      <br />
      <p>7. <b>Ausencia de cesión, seguro o garantía.</b> Esta Política no pretende constituir una oferta de seguro, no constituye un seguro ni un contrato de seguro, no sustituye al seguro que hayas obtenido o puedas obtener, y no has pagado ninguna prima con respecto a la Política. No puedes ceder ni transferir los beneficios proporcionados en virtud de esta Política. Esta Política no constituye una garantía contra el fraude o cualquier otro uso indebido de la Plataforma. Recomendamos que solamente hagas donativos a organizadores y beneficiarios que conozcas y en los que confíes.
</p>
<br />
      <p>8. <b>Modificación o extinción.</b> Me2you se reserva el derecho de modificar o terminar esta Política, en cualquier momento, a su entera discreción y sin previo aviso. Si modificamos esta Política, publicaremos la modificación en el sitio web de Me2you, o bien te notificaremos la modificación y continuaremos tramitando todas las reclamaciones realizadas antes de la fecha de entrada en vigor de la modificación.</p>
      <br />
      <p>9. <b>Acuerdo completo y definiciones.</b> Esta Política constituye el acuerdo y entendimiento completo y exclusivo entre Me2you y tú en relación con el objeto del presente documento, y sustituye y reemplaza a todos y cada uno de los acuerdos orales o escritos previos entre Me2you y tú en relación con tu capacidad para reclamar o tener derecho a un reembolso o pago de Me2you.</p>
      <br />
      <p>10. <b>Otros requisitos.</b> Aceptas que se pueda requerir una determinada cantidad mínima de donativo para poder optar a los beneficios de esta Política, y que todos los pagos de donativos siguen siendo definitivos y no se reembolsarán a menos que Me2you, a su entera discreción, acepte emitir un reembolso de acuerdo con las condiciones de esta u otra Política.</p>
      <br />
      <p>11. <b>Sin Ausencia de renuncia.</b> Aceptas que la decisión de Me2you de otorgar beneficios en virtud de esta Política no constituye una renuncia a su derecho a denegar reembolsos, pagos u otros beneficios en el futuro, por cualquier motivo y a su entera discreción.</p>
      <br />
      <p>12. <b>Preguntas.</b> Si tienes alguna pregunta sobre esta Política, ponte en contacto con Me2you a través de los teléfonos o el correo proporcionado en la plataforma por internet de Me2you.</p>
      <br />
      <p>13. <b>Campañas por país.</b> Las Campañas de recaudación de fondos cubiertas son recaudaciones de fondos personales de Me2you creadas en cada país admitido. Las campañas de recaudación de fondos para organizaciones benéficas, incluidas, entre otras, aquellas creadas a través de PayPal Giving Fund, no están cubiertas por la Garantía de Me2you.</p>
      <br />
      <p>14. <b>Aviso de privacidad de la información proporcionada.</b> Toda información proporcionada por el Beneficiario, el Donante o cualquier persona que acceda a la plataforma y proporcione información personal o sensible, será tratada como confidencial bajo el Aviso de Privacidad de Me2you.</p>

      
      </article>
    </div>
  )
}

export default TermsConditions;