import React, { useEffect } from 'react'
import Post from './Post'


function Items({ currentItems }) {
  return (
    <>
      {currentItems !== null
        ? (currentItems?.map((data, key) => {
          return (
            <Post
              key={key}
              uid={data.idUser}
              idPost={data._id}
              img={data.img}
              name={data.nameUser}
              dataPost={data.dataPost}
              reaction={data.reaction}
              comment={data.Comment}
              created={data.createdAt}
              reactionName={data.reactionName}
            />
          )
        })
        )
        : (<h2>Cargando...</h2>)
      }
    </>
  );
}

const PaginationPost = (
  { data,
    currentItems,
    setCurrentItems,
    pageCount,
    setPageCount,
    itemOffset,
    setItemOffset,
    itemsPerPage,
    handlePageClick,
  }) => {

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math?.ceil(data?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, itemsPerPage, data]);



  return (
    <>
      <Items currentItems={currentItems} />
    </>
  )
}

export default PaginationPost;