import { useState } from 'react';

export default function useCheckboxVacasiones (itemsVacasiones, inputs, setInputs) {
  const [checkedVacasiones, setCheckedVacasiones] = useState(false)
  const dataVacasiones = [];


  const limitCheckbox = (e) => {
    const limit = 2;
    var count = 0, i = 0;
    // console.log(itemsVacasiones.length)

    for (i = 0; i < itemsVacasiones.length; i++) {
      // console.log(`${i} ${itemsVacasiones[i].value} ${itemsVacasiones[i].checked}`)
      // console.log(itemsVacasiones[i].checkedVacasiones)

      if (itemsVacasiones[i].checked) {
        count = count + 1;
      }
      //     //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsVacasiones[i].checked = false;
      }
    }
  }


  const onChangeVacasiones = (e) => {
    limitCheckbox();
    setCheckedVacasiones({
      [e.target.value]: checkedVacasiones
    })
  }

  const handleClickVacasiones = () => {

    for (var i = 0; i < itemsVacasiones.length; i++) {
      // console.log(`${i} ${itemsVacasiones[i].value} ${itemsVacasiones[i].checked}`)

      if (itemsVacasiones[i].checked) {
        dataVacasiones.push(itemsVacasiones[i].value)
      }
    }
    // setInputs({
    //   ...inputs,
    //   ["inputVacasiones"]: dataVacasiones
    // })
    return dataVacasiones;

  }


  return {
    // dataVacasiones,
    checkedVacasiones,
    onChangeVacasiones,
    handleClickVacasiones,
  }
}