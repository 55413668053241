import React, { useState, useEffect } from 'react'
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchPost } from '../../helpers/helpCore'

import Banner from '../../assets/images/design/registro-1.jpg';
import { Link, Redirect } from 'react-router-dom';
import MessageResponse from '../components/MessageResponse';

const initialForm = { //20
  name: '',
  lastname: '',
  nickname: '',
  dia: null,
  mes: null,
  year: null,
  estadoCivil: '',
  nivelAcademico: '',
  ocupacion: '',
  peso: '',
  colorCabello: '',
  complexion: '',
  nacionalidad: '',
  colorOjos: '',
  altura: null,
  pais: '',
  codigoPostal: '',
  religion: '',
  comoNosConociste: '',
  hijos: undefined
}

const Prolife = () => {
  const [inputs, setInputs] = useState(initialForm);
  const [idUser, setIdUser] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const usuario = localStorage.getItem('uid');
  const [formAll, setFormAll] = useState(false)
  const [result, setResult] = useState('')

  useEffect(() => {
    setIdUser(localStorage.getItem('email'))
    const idUrl = usuario;
    fetchPost({ idUrl, url: '/user' })
      .then(res => {
        setInputs({
          ...res
        });
        setFormAll(res?.errors ? false : res?._id);
      })
    // eslint-disable-next-line
  }, [])


  const infoUserAdd = () => {
    const idUrl = usuario;
    fetchPost({
      email: idUser,
      name: inputs.name,
      lastname: inputs.lastname,
      nickname: inputs.nickname,
      dia: inputs.dia,
      mes: inputs.mes,
      year: inputs.year,
      estadoCivil: inputs.estadoCivil,
      nivelAcademico: inputs.nivelAcademico,
      ocupacion: inputs.ocupacion,
      peso: inputs.peso,
      colorCabello: inputs.colorCabello,
      complexion: inputs.complexion,
      nacionalidad: inputs.nacionalidad,
      colorOjos: inputs.colorOjos,
      altura: inputs.altura,
      pais: inputs.pais,
      codigoPostal: inputs.codigoPostal,
      religion: inputs.religion,
      hijos: inputs.hijos,
      comoNosConociste: inputs.comoNosConociste,
      usuario: usuario,
      _id: formAll, //id para update en el backend
      method: "PUT",
      url: '/user',
      idUrl
    })
      .then(res => {
        setInputs({})
        setResult(res?.msg)
      })

  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (inputs.hijos !== undefined) {
      if (formAll === false) {
        fetchPost({
          email: idUser,//para quitar las comillas extras
          name: inputs.name,
          lastname: inputs.lastname,
          nickname: inputs.nickname,
          dia: inputs.dia,
          mes: inputs.mes,
          year: inputs.year,
          estadoCivil: inputs.estadoCivil,
          nivelAcademico: inputs.nivelAcademico,
          ocupacion: inputs.ocupacion,
          peso: inputs.peso,
          colorCabello: inputs.colorCabello,
          complexion: inputs.complexion,
          nacionalidad: inputs.nacionalidad,
          colorOjos: inputs.colorOjos,
          altura: inputs.altura,
          pais: inputs.pais,
          codigoPostal: inputs.codigoPostal,
          religion: inputs.religion,
          hijos: inputs.hijos,
          comoNosConociste: inputs.comoNosConociste,
          usuario: usuario,
          url: '/user'
        })
          .then(res => {
            setInputs({})

            setRedirect(!redirect)
            setResult(res?.msg);
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        infoUserAdd()
      }
    } else {
      setResult('¿Tienes hijos? llena esa opción')
    }
  }

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value

    })
  };


  return (
    // <div className={open ? 'main' : 'd-none'}>
    <div className='main' >
      {/* {redirect ? (<Redirect to={`/web/yaestasadentro`} />) : false} */}
      {redirect
        ? (<Redirect to={`/web/profile2`} />

        )
        : false}
      <div className="container-fluid">
        <div className="row">
          <div className="col p-0">
            <img src={Banner} alt="banner" className="img-fluid" loading="lazy" />
          </div>
        </div>
        <div className="row justify-content-center bg-white pt-5">
          <div className="col-12 col-sm-8">
            <h3 className="text-center">
              {' '}
              Ayúdanos a llenar tu cuenta y perfil
            </h3>
            {/* <h3 className="text-center">{context.Langs.ayudanosALlenar[context.Lang]}</h3> */}
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="row justify-content-center bg-white pt-5 pb-5">
            <div className="col-12 col-sm-8">
              <div className="card border-danger">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10 bg-danger2 text-center text-white">
                    {/* {context.Langs.mesanje1[context.Lang]} */}
                    Las preguntas con un candado son solo para propósitos de la
                    cuenta y no estarán visibles en tu perfil
                  </div>
                </div>
                <div className="card-body">
                  <div className="row justify-content-center p-1">
                    * &nbsp;{' '}
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      size="1x"
                      icon={faLock}
                    />{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          onChange={onChange}
                          className="form-control form-control-border"
                          name="name"
                          placeholder="Nombres"
                          value={inputs.name}
                          required
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text text-white"
                            id="basic-addon1"
                          >
                            @
                          </span>
                        </div>
                      </div>
                    </div>
                    * &nbsp;{' '}
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      size="1x"
                      icon={faLock}
                    />{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          onChange={onChange}
                          className="form-control form-control-border"
                          name="lastname"
                          placeholder="Apellidos"
                          value={inputs.lastname}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            @
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center p-1">
                    <div className="col-8 col-sm-5 text-center">
                      <div className="input-group mb-3">
                        * &nbsp;{' '}
                        <input
                          type="text"
                          onChange={onChange}
                          className="form-control form-control-border"
                          name="nickname"
                          minLength="5"
                          maxLength="20"
                          placeholder="Nombre de usuario"
                          value={inputs.nickname}

                          required
                        />
                      </div>
                    </div>
                    * &nbsp;{' '}
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      size="1x"
                      icon={faLock}
                    />{' '}
                    <div className="col-8 col-sm-2 text-center text-10">
                      Fecha de nacimiento:
                    </div>
                    <div className="col-8 col-sm-3 text-center">
                      <div className="row">
                        <div className="col p-1">
                          <input
                            type="number"
                            className="form-control form-control-border"
                            // defaultValue={context.Store.get('user').dia}
                            name="dia"
                            onChange={onChange}
                            min="1"
                            max="31"
                            placeholder="Día"
                            value={inputs.dia}
                            required
                          />
                        </div>
                        <div className="col p-1">
                          <input
                            type="number"
                            className="form-control form-control-border"
                            onChange={onChange}
                            // defaultValue={context.Store.get('user').mes}
                            name="mes"
                            min="1"
                            max="12"
                            placeholder="mes"
                            value={inputs.mes}
                            required
                          />
                        </div>
                        <div className="col p-1">
                          <input
                            type="number"
                            className="form-control form-control-border"
                            name="year"
                            min="1900"
                            max="2002"
                            placeholder="Año"
                            onChange={onChange}
                            value={inputs.year}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center p-1">
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <input
                        type="text"
                        className="form-control form-control-border"
                        name="nacionalidad"
                        value={inputs.nacionalidad}
                        placeholder="Nacionalidad"
                        onChange={onChange}
                        required
                      />
                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <div className="row pb-2">
                        <select
                          value={inputs.estadoCivil}
                          onChange={onChange}
                          name="estadoCivil"
                          className="form-control form-control-border"
                          // placeholder="Estado civil"
                          required
                        >
                          <option selected value="">Estado Civil: </option>
                          <option value="Soltero/a">Soltero/a</option>
                          <option value="Casado/a">Casado/a</option>
                          <option value="Divorciado/a">Divorciado/a</option>
                          <option value="Viudo/a">Viudo/a</option>
                        </select>
                      </div>

                    </div>
                  </div>
                  <div className="row justify-content-center p-1">
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <div className="row pb-2">
                        <select
                          value={inputs.nivelAcademico}
                          onChange={onChange}
                          name="nivelAcademico"
                          className="form-control form-control-border"
                          required
                        >
                          <option selected value="">Nivel académico: </option>
                          <option value="Secundaria">Secundaria</option>
                          <option value="Preparatoria">Preparatoria</option>
                          <option value="Carrera_tecnica">Carrera técnica</option>
                          <option value="Universidad">Universidad</option>
                          <option value="Postgrado">Postgrado</option>
                          <option value="Magister">Magister</option>
                          <option value="Doctorado">Doctorado</option>
                        </select>
                      </div>

                    </div>
                    * &nbsp;
                    <div className="col-8 col-sm-5 text-center">
                      <input
                        type="text"
                        className="form-control form-control-border"
                        name="ocupacion"
                        placeholder="Ocupación"
                        onChange={onChange}
                        value={inputs.ocupacion}
                        required
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center p-1">
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <div className="row pb-2">
                        <select
                          name="complexion"
                          value={inputs.complexion}
                          className="form-control form-control-border"
                          onChange={onChange}
                          required
                        >
                          <option selected value="">Complexión: </option>
                          <option value="Delgado/a">Delgado/a</option>
                          <option value="Atletico/a">Atletico/a</option>
                          <option value="Robusto/a">Robusto/a</option>
                          <option value="Normal">Normal</option>
                        </select>
                      </div>

                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-5 text-center">
                      <div className="row pb-2">
                        <select
                          name="peso"
                          value={inputs.peso}
                          className="form-control form-control-border"
                          onChange={onChange}
                          required
                        >
                          <option selected value="">Peso:</option>

                          <option value="35kg - 45kg">35kg - 45kg</option>
                          <option value="46kg - 55kg">46kg - 55kg</option>
                          <option value="56kg - 65kg">56kg - 65kg</option>
                          <option value="66kg - 75kg">66kg - 75kg</option>
                          <option value="76kg - 85kg">76kg - 85kg</option>
                          <option value="86kg - 95kg">86kg - 95kg</option>
                          <option value="96kg - 105kg">96kg - 105kg</option>
                          <option value="106kg - 115kg">106kg - 115kg</option>
                          <option value="+ 116kg">+ 116kg</option>

                        </select>
                      </div>

                    </div>
                  </div>
                  <div className="row justify-content-center p-1">
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-4 text-center">
                      <div className="row pb-2">
                        <select
                          name="colorOjos"
                          value={inputs.colorOjos}
                          className="form-control form-control-border"
                          onChange={onChange}
                          required
                        >
                          <option selected value="">Color Ojos: </option>
                          <option value="Marron">Marron</option>
                          <option value="Negros">Negro</option>
                          <option value="Marron claro">Marrón Claro</option>
                          <option value="Ambar">Ambar</option>
                          <option value="Avellana">Avellana</option>
                          <option value="Azul">Azul</option>
                          <option value="Gris">Gris</option>
                          <option value="Verde">Verde</option>
                        </select>
                      </div>

                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-2 text-center">
                      <input
                        type="number"
                        min="150"
                        max="220"
                        value={inputs.altura}
                        className="form-control form-control-border"
                        name="altura"
                        placeholder="Altura"
                        onChange={onChange}
                        required
                      />
                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-4 text-center">
                      <div className="row pb-2">
                        <select
                          name="colorCabello"
                          value={inputs.colorCabello}
                          className="form-control form-control-border"
                          onChange={onChange}
                          required
                        >
                          <option selected value="">Color Cabello: </option>
                          <option value="Marron">Marrón</option>
                          <option value="Negros">Negro</option>
                          <option value="Cafe">Café</option>
                          <option value="Rubio">Rubio</option>
                          <option value="Pelirojo">Pelirojo</option>
                          <option value="Plateado">Plateado</option>
                        </select>
                      </div>

                    </div>
                  </div>
                  <div className="row justify-content-center p-2">
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-2 text-center text-14 pt-2">
                      Tiene hijos
                    </div>
                    <div className="col-8 col-sm-1 text-left p-0 pt-2" required>
                      <div style={{ float: 'left', marginRight: '10px' }}>
                        Si{'  '}
                        <input
                          type="radio"
                          name={'hijos'}
                          onChange={onChange}
                          checked={inputs.hijos === 'SI'}
                          value="SI"
                        />
                      </div>

                    </div>
                    <div className="col-8 col-sm-1 text-left p-0 pt-2">
                      <div style={{ float: 'left', marginRight: '10px' }}>
                        No {'  '}
                        <input
                          type="radio"
                          name={'hijos'}
                          onChange={onChange}
                          checked={inputs.hijos === 'NO'}
                          value="NO"
                          defaultValue

                        />
                      </div>

                    </div>
                  </div>
                  <div className="row justify-content-center p-1">
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-2 text-center">
                      <select
                        name="pais"
                        onChange={onChange}
                        className="form-control form-control-border "
                        value={inputs.pais}
                      >
                        <option defaultValue={true} value="">Pais:</option>
                        <option value="México">México</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Perú">Perú</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Chile">Chile</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Guayana">Guayana</option>
                        <option value="Guayana francesa">Guayana francesa</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Estados Unidos">Estados Unidos</option>
                        <option value="Canadá">Canadá</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Otro">Otro</option>
                      </select>
                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-2 text-center">

                      <select
                        name="codigoPostal"
                        onChange={onChange}
                        className="form-control form-control-border "
                        value={inputs.codigoPostal}
                        required
                      >
                        <option defaultValue={true} value="">Estado:</option>
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">Baja California Sur</option>
                        <option value="Campeche">Campeche</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="Ciudad de México">Ciudad de México</option>
                        <option value="Coahuila">Coahuila</option>
                        <option value="Colima">Colima</option>
                        <option value="Durango">Durango</option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="Estado de México">Estado de México</option>
                        <option value="Michoacán">Michoacán</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz">Veracruz</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                        <option value="No vivo en México">No vivo en México</option>
                      </select>
                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-2 text-center">
                      <div className="row pb-2">
                        <select
                          name="religion"
                          className="form-control form-control-border "
                          value={inputs.religion}
                          onChange={onChange}
                          required
                        // defaultValue={context.Store.get('user').religion}
                        >
                          <option selected value="">Religiones: </option>
                          <option value="Catolicismo">Catolicismo</option>
                          <option value="Scientology">Scientology</option>
                          <option value="Judaísmo">Judaísmo</option>
                          <option value="Hinduismo">Hinduismo</option>
                          <option value="Mormones">Mormones</option>
                          <option value="Protestante">Protestante</option>
                          <option value="Budismo">Budismo</option>
                          <option value="Iglesia Nativa Polaca">
                            Iglesia Nativa Polaca
                          </option>
                          <option value="Espiritual">Espiritual</option>
                          <option value="Ateo">Ateo</option>
                          <option value="Otros">Otros</option>
                        </select>
                      </div>

                    </div>
                    * &nbsp;{' '}
                    <div className="col-8 col-sm-4 text-center">
                      <input
                        type="text"
                        // onChange={onChange}
                        className="form-control form-control-border"
                        name="comoNosConociste"
                        placeholder="¿Cómo te enteraste de nosotros?"
                        onChange={onChange}
                        value={inputs.comoNosConociste}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  * Campos obligatorios
                </div>
                <div className="col text-right">

                  <input className="btn btn-primary2" type="submit" value="Guardar" />
                </div>
                <div className="col text-right">
                  {formAll
                    ? (<Link to="/web/profile2" className="btn btn-primary2">
                      Solo Continuar
                    </Link>
                    )
                    : (false)
                  }
                </div>
                <MessageResponse
                  result={result}
                  setResult={setResult} />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default Prolife;