import { useState } from 'react';

export default function useCheckboxPiensas (itemsPiensas, inputs, setInputs) {
  const dataPiensas = [];
  const [checkedPiensas, setCheckedPiensas] = useState(false)


  const limitCheckbox = (e) => {
    const limit = 3;
    var count = 0, i = 0;

    for (i = 0; i < itemsPiensas.length; i++) {
      // console.log(`${i} ${itemsPiensas[i].value} ${itemsPiensas[i].checked}`)

      if (itemsPiensas[i].checked) {
        count = count + 1;
      }
      //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsPiensas[i].checked = false;
      }
    }
  }


  const onChangePiensas = (e) => {
    limitCheckbox();
    setCheckedPiensas({
      [e.target.value]: checkedPiensas
    })
  }

  const handleClickPiensas = () => {
    // console.log('esta funcionando')

    for (var i = 0; i < itemsPiensas.length; i++) {
      // console.log(`${i} ${itemsPiensas[i].value} ${itemsPiensas[i].checked}`)

      if (itemsPiensas[i].checked) {
        dataPiensas.push(itemsPiensas[i].value)
      }
    }
    // console.log(dataPiensas)

    // setInputs({
    //   ...inputs,
    //   ["inputPiensas"]: dataPiensas
    // })
    return dataPiensas;
  }


  return {
    // dataPiensas,
    checkedPiensas,
    onChangePiensas,
    handleClickPiensas,
  }
}
