import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LogoPC from '../../assets/images/design/logoPC.png';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';

import '../styles/Navbar.css';


const Navbar = () => {
  const location = useLocation();
  const uid = localStorage.getItem('uid')
  const rol = localStorage.getItem('role')
  const token = localStorage.getItem('jwt');
  const { isLogged, logout } = useAuth();
  const [dropDown, setDropDown] = useState(false)
  const [navbarIcon, setNavbarIcon] = useState(false)
  const [admin, setAdmin] = useState('')

  useEffect(() => {
    setAdmin('')
    if (rol === 'Usuario Admin - privilegios') {
      setAdmin('Usuario Admin - privilegios de administrador')
    }
  }, [rol])

  const handleExit = () => {
    logout()
    handleCloseDropDown()
  }

  const handleDropDown = () => {
    setDropDown(!dropDown)
  }

  const handleCloseDropDown = () => {
    setDropDown(false)
    setNavbarIcon(false)

  }

  const handleIcon = () => {
    setNavbarIcon(!navbarIcon)

  }

  const handleCloseIcon = () => {
    setNavbarIcon(false)
  }

  return (
    <div className="Navbar">
      <i className="Navbar-icons">
        <FontAwesomeIcon
          size="2x"
          className="icons__menu"
          icon={faBars}
          onClick={handleIcon}
        />
      </i>
      <i className={`Navbar-img ${navbarIcon === false ? 'active' : ''}`}>
        <Link to="/">
          <img src={LogoPC} alt="Foto de navbar" />

        </Link>
      </i>
      <div className={`Navbar-container ${navbarIcon === true ? 'active' : ''}`}>
        <div className="Navbar-box__img">
          <Link to="/">
            <img src={LogoPC} alt="Logotipo" />
          </Link>
        </div>
        <div className="Navbar-box__menu">
          <nav>
            <ul>
              <Link
                className={`navBar_option ${location?.pathname === '/' ? 'active' : ''}`}
                to="/"
                onClick={handleCloseIcon}>
                <FontAwesomeIcon
                  className="cursor-pointer"
                  size="1x"
                  icon={faHome}
                />
              </Link>
            </ul>
            <ul>
              {token && uid
                ? (
                  <Link
                    onClick={handleCloseIcon}
                    to="/web/buscar"
                    className={`navBar_option ${location?.pathname === '/web/buscar' ? 'active' : ''}`}>
                    Amor y amistad
                  </Link>
                )
                : (
                  <Link
                    onClick={handleCloseIcon}
                    to="/web/buscar/free"
                    className={`navBar_option ${location?.pathname === '/web/buscar/free' ? 'active' : ''}`}>
                    Amor y amistad
                  </Link>
                )

              }

            </ul>
            <ul>
              <Link
                to="/ayuda"
                onClick={handleCloseIcon}
                className={`navBar_option ${location?.pathname === '/ayuda' ? 'active' : ''}`}
              >
                Da una mano
              </Link>
            </ul>

            <ul>
              <Link
                to="/proyectos"
                onClick={handleCloseIcon}
                className={`navBar_option ${location?.pathname === '/proyectos'  ? 'active' : ''}`}
              >
                Proyectos Comunitarios
              </Link>
            </ul>
            <ul >
              <Link
                onClick={handleCloseIcon}
                to="/web/contact"
                className={`${location?.pathname === '/web/contact' ? 'active' : ''}`}>
                Contacto
              </Link>
            </ul>
            <ul>
              <div onClick={handleDropDown}>
                Mi perfil
              </div>
            </ul>

          </nav>
          <div className={`nav-dropDown ${dropDown === true ? 'active' : ''}`}>
            {
              isLogged
                ? (
                  <div className="nav-dropDown__option">
                    <ul>
                      <Link to="/web/mensajes" onClick={handleCloseDropDown}>
                        Mensajes
                      </Link>
                    </ul>
                    <ul>
                      <Link to="/web/membresia" onClick={handleCloseDropDown}>
                        Ver planes
                      </Link>
                    </ul>
                    <ul>
                      <Link to={`/web/miperfil/${uid}`} onClick={handleCloseDropDown}>
                        Configurar
                      </Link>
                    </ul>
                    <ul>
                      <Link to={`/mis-publicaciones`} onClick={handleCloseDropDown}>
                        Publicaciones
                      </Link>
                    </ul>
                    {
                      admin === 'Usuario Admin - privilegios de administrador'
                        ? (
                          <ul>
                            <Link to={`/web/admin`} onClick={handleCloseDropDown}>
                              Administrador
                            </Link>
                          </ul>
                        )
                        : (false)
                    }
                    <ul onClick={() => {
                      // 
                      handleExit()
                    }
                    }>
                      Cerrar sesión
                    </ul>
                  </div>
                )
                : (
                  <div className="nav-dropDown__option logout">
                    <ul>
                      <Link to="/auth/signup" onClick={handleCloseDropDown}>
                      Regístrate
                      </Link>
                    </ul>
                    <ul>
                      <Link to="/auth/signin" onClick={handleCloseDropDown}>
                        Inicia sesión
                      </Link>
                    </ul>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;