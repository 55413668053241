import React, {useState} from 'react';
import { Redirect } from 'react-router-dom'
import Banner from '../../assets/images/design/img-final-proceso-realizado.jpg';
import ModalPremiere from '../components/ModalPremiere';
import '../styles/WebMiPerfil.css'


const YaEstasAdentro = () => {
  const [redirect , setRedirect] = useState(false)

  const handleClick = () => {
    setRedirect(!redirect)
  }

  return (
    <div className={'main'}>
      {redirect ? (<Redirect to={`/web/membresia`}/>) : false}
      <section className="Banners position-relative">
        <div className="position-absolute banner-text position-absolute-banner">
          <h3 className="text-center ">Bienvenido</h3>
          <div className="text-center">
            <p>
              Gracias por registrarte
              <br />
              has dado un gran paso para conocer a tu compañero ideal.
            </p>
            <div className="mt-2 mb-3">
              <b>¡Con suerte hasta tu pareja ideal!</b>
            </div>
            {/* <a className="btn btn-primary2 btn-lg" href="">
              
            </a> */}
            {/* <Link className="btn btn-primary2 btn-lg" to="/">
              CONTINUAR
            </Link> */}
            <input 
            className="btn btn-primary2 btn-lg"
            onClick={handleClick}
            value="CONTINUAR"/>
            
          </div>
        </div>
      </section>

      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-0 banner-perfil">
            <img src={Banner} alt="programandoweb" className="img-fluid" />
          </div>
        </div>
      </div>
      <ModalPremiere />
    </div>
  );
};

export default YaEstasAdentro;
