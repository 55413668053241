import React, { useEffect } from 'react'
import Projects from './Projects'


function Items({ currentItems }) {
  return (
    <>
      {currentItems !== null
        ? (currentItems?.map((data, key) => {
          return (
            <Projects
              key={key}
              title={data.title.toUpperCase()}
              image={data.image}
              id={data.idProject}
              amount={data.amount}
              accumulated={data.accumulated}
              raised={data.raised}
            />
          )
        })
        )
        : (<h2>Cargando...</h2>)
      }
    </>
  );
}

const PaginationPost = (
  { data,
    currentItems,
    setCurrentItems,
    pageCount,
    setPageCount,
    itemOffset,
    setItemOffset,
    itemsPerPage,
    handlePageClick,
  }) => {

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math?.ceil(data?.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, itemsPerPage, data]);



  return (
    <>
      <Items currentItems={currentItems} />
    </>
  )
}

export default PaginationPost;