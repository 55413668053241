import React, { useState, useEffect } from 'react'
import CarruselVertical from './CarruselVertical'
import { fetchPost } from '../../helpers/helpCore'
import vertical1 from '../../assets/images/design/vertical-1.jpg';
import vertical2 from '../../assets/images/design/vertical-2.jpg';
import vertical3 from '../../assets/images/design/vertical-3.jpg';

const Monoseccion2 = (props) => {
  const [data, setData] = useState('')

  useEffect(() => {
    fetchPost({ url: '/project/viewProject/landing', dataAny: 'n/a' })
      .then(res => {
        setData(res)
      })
  }, [])
  return (
    <section className={' Monoseccion-2'}>
      <div className={' container-fluid bg-white'}>
        <div className="row text-center">
          <div className="col">
            <div className="p-2 item position-relative ">
              <div className="row justify-content-center ">
                <div className="ico col-2 mt-10">
                  <img
                    src={props.ico}
                    className="img-fluid rounded-circle border bg-white sombra-2x"
                    alt="Programandoweb"
                    loading="lazy"
                  />
                </div>
                <div className="col-12 p-2">
                  <div className="content">
                    <h3 className="h4">{props.text}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className={props.className + ' p-5'}>
        <div className="container">
          {data &&
            <>
              <CarruselVertical
                image1={{
                  img: data[0]?.img ? data[0]?.img : vertical1,
                  name: data[0]?.name ? data[0]?.name : 'Proyecto 1',
                  id: data[0]?.id ? data[0]?.id : ''
                }}
                image2={{
                  img: data[1]?.img ? data[1]?.img : vertical2,
                  name: data[1]?.name ? data[1]?.name : 'Proyecto 2',
                  id: data[1]?.id ? data[1]?.id : ''
                }}
                image3={{
                  img: data[2]?.img ? data[2]?.img : vertical3,
                  name: data[2]?.name ? data[2]?.name : 'Proyecto 3',
                  id: data[2]?.id ? data[2]?.id : ''
                }} 
               />
            </>}

        </div>
      </section>
    </section>
  );
};
export default Monoseccion2;

