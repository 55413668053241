import React, { useEffect, useState } from 'react'
import '../styles/Admi.css'
import { fetchPost } from '../../helpers/helpCore'
import MessageResponse from '../components/MessageResponse';
import IconLoading from '../components/IconLoading';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom'

const AdminRol = () => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState(false)
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('');
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()

  useEffect(() => {
    const url = '/buscar/admin'
    setLoading(true)
    fetchPost({ token, url })
      .then(response => {
        setLoading(false)
        setData(response)
        if (status) {
          setStatus(false)
        }

      })
      // eslint-disable-next-line
  }, [status])

  useEffect(() => {
    const url = '/admin/validateAdmin'
    fetchPost({ token, url })
      .then(res => {
        if (res === false) {
          logout()
        } else {
          setLoad(res);
        }
      })
      // eslint-disable-next-line
  }, [])

  const changeRol = (id) => {
    const url = '/admin/change-rol'
    fetchPost({ token, id, url })
      .then(res => {
        setResult(res?.msg)
        setStatus(true)
      })
  }


  return (
    <div className="Admin-container">
      <div>
        <h3>Admistra los roles de los usuarios</h3>
      </div>
      {loading && <IconLoading />}
      <MessageResponse result={result} setResult={setResult} />
      {
        load &&
        <div className="Admin-Profile">
          {
            data.length > 0
              ? (
                data.map((data) => (
                  <div className="Admin-profiles" key={data.uid}>
                    <div className="Admin-profiles__title">
                    <h3>
                          <strong>Correo: </strong> {data?.email}
                        </h3>
                        <h3>
                          <strong>Nombres: </strong>
                          <Link target={"_blank"} to={{
                            pathname: `/web/perfil/${data.uid}`
                          }}>
                            {data?.name}
                          </Link>

                        </h3>
                      <h3>

                        {data.rol_user === 'USER_ROLE' ? (<p>-- Cuenta Normal -- </p>) : (<p>-- Cuenta Premiere -- </p>)}
                      </h3>
                    </div>
                    <div className="Admin-profiles__button">
                      <input
                        onClick={() => changeRol(data.uid)}
                        className="btn btn-outline-primary2"
                        type="button"
                        value={data.rol_user === 'USER_ROLE'
                          ? "Cambiar a cuenta Premiere"
                          : 'Cambiar a cuenta Normal'}
                      />
                      {/* <input onClick={() => viewProfile(data.uid)} className="btn btn-outline-primary2" type="button" value="Visualizar" /> */}
                    </div>
                  </div>
                ))
              )
              : (false)
          }
        </div>
      }
    </div>
  )
}

export default AdminRol;