import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchPost } from '../../helpers/helpCore'

const ButtonPremiere = () => {
  const token = localStorage.getItem('jwt')

  const [premiere, setPremiere] = useState(false)
  useEffect(() => {
    fetchPost({ token , url:'/premier/validatePremiere'})
      .then(response => {
        setPremiere(response)
      })
      // eslint-disable-next-line
  },[])


  return (
    <div>
      {
        premiere
          ? (
            <div className="Button-perfil">
              
              <Link className="btn btn-primary2" to="/web/premier/formulario">
                Formulario premiere
              </Link>
              <Link className="btn btn-primary2" to="/web/mensajes">
                Bandeja de mensajes
              </Link>
            </div>
          )
          : (
            <div className="Button-perfil">
              
              <Link className="btn btn-primary2" to="/web/membresia">Ver planes</Link>
              <Link className="btn btn-primary2" to="/web/mensajes">
                Bandeja de mensajes
              </Link>
            </div>
          )
      }
    </div>
  )
}

export default ButtonPremiere