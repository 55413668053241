import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import personajes from '../../assets/images/design/personajes-bg-secc-1.jpg';
import ico1 from '../../assets/images/design/ico-1.png';
import ico2 from '../../assets/images/design/ico-2.png';
import ico3 from '../../assets/images/design/ico-3.png';
import ayudanos from '../../assets/images/design/Ayudanos.jpeg';
import comoteayudamos from '../../assets/images/design/comoteayudamos.jpeg';
import encuentraelamor from '../../assets/images/design/encuentraelamor.jpeg';


import '../styles/HomePrincipal.css';



const CardImageOption = () => {
  const [redirect, setRedirect] = useState(false)

  const handleRedirect = (url) => {
    setRedirect(url)
  }

  const triseccion = [{
    ico: ico1,
    img: encuentraelamor,
    text: "Encuentra el amor",
    title: '',
    url : '/web/buscar'
  },
  {
    ico: ico2,
    img: comoteayudamos,
    text: "¿Cómo te ayudamos?",
    title: '',
    url:'/proyectos'
  },
  {
    ico: ico3,
    img: ayudanos,
    text: "Ayúdanos a ayudar",
    title: '',
    url:'/ayuda'
  },];
 
  return (
    <div className='CardImageOption'>
      {redirect === false ? (false) : (<Redirect to={redirect} />)}
      <div className="CardImageOption-image">
        <img src={personajes} alt="Personajes" />
      </div>
      <div className='CardImageOption-container'>  
       {
         triseccion.map((data, key) => {
           return(
            <div key={key} className="CardImageOption-box">
            <div className='CardImageOption-box__title'>
              <div className='CardImageOption-box__titleIcon'>
                <img src={data.ico} alt="Icono" className='sombra-2x'/>
              </div>
              <h3>
                {data.text}
              </h3>
            </div>
            <div className='CardImageOption-box__image'>
              <img 
              src={data.img} 
              alt="Tarjeta de imagenes"
              onClick={() => handleRedirect(data.url)} />

              </div>
          </div>
           )
         })
       }
      </div>

    </div>
  )
}

export default CardImageOption;