import { useState } from 'react';

export default function useCheckboxParejaIdeal (itemsParejaIdeal, inputs, setInputs) {
  const dataParejaIdeal = [];
  const [checkedParejaIdeal, setCheckedParejaIdeal] = useState(false)


  const limitCheckbox = (e) => {
    const limit = 1;
    var count = 0, i = 0;

    for (i = 0; i < itemsParejaIdeal.length; i++) {
      // console.log(`${i} ${itemsParejaIdeal[i].value} ${itemsParejaIdeal[i].checked}`)

      if (itemsParejaIdeal[i].checked) {
        count = count + 1;
      }
      //VALIDACION DE OPCIONES ELEGIBLES
      if (count > limit) {
        itemsParejaIdeal[i].checked = false;
      }
    }
  }


  const onChangeParejaIdeal = (e) => {
    limitCheckbox();
    setCheckedParejaIdeal({
      [e.target.value]: checkedParejaIdeal
    })
  }

  const handleClickParejaIdeal = () => {
    // console.log('esta funcionando')

    for (var i = 0; i < itemsParejaIdeal.length; i++) {
      // console.log(`${i} ${itemsParejaIdeal[i].value} ${itemsParejaIdeal[i].checked}`)

      if (itemsParejaIdeal[i].checked) {
        dataParejaIdeal.push(itemsParejaIdeal[i].value)
      }
    }
    // console.log(dataParejaIdeal)

    // setInputs({
    //   ...inputs,
    //   ["inputParejaIdeal"]: dataParejaIdeal
    // })
    return dataParejaIdeal;
  }


  return {
    // dataParejaIdeal,
    checkedParejaIdeal,
    onChangeParejaIdeal,
    handleClickParejaIdeal,
  }
}

