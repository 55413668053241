import React from 'react'
import dateFormat from '../../helpers/dateFormat';

const CardAdminViewActivity = ({email, createdAt, action}) => {
  return (
    <div className="AdminViewActivity">
      <p><strong>Correo del responsable:</strong> {email}</p>
      <p><strong>Accion realizada el:</strong> {dateFormat(createdAt)}</p>
      <p><strong>Accion realizada:</strong> {action}</p>
    </div>
  )
}

export default CardAdminViewActivity;