import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import '../styles/HelpPage.css'
import useAuth from '../../hooks/useAuth';
import { fetchPost } from '../../helpers/helpCore'

import ImgHeader from '../../assets/images/design/proyectos-comunitarios-fondo-translucido.jpg'
import ProjectPost from '../components/ProjectPost'
import ico3 from '../../assets/images/design/hand.png';
import SearchProject from '../components/SearchProject';


const ProjectPage = () => {
  const [project, setProject] = useState([])
  const token = localStorage.getItem('jwt')
  const { logout } = useAuth()
  const [loading, setLoading] = useState(false)
  const [staticInformation, setStaticInformation] = useState([]);

  useEffect(() => {
    setLoading(true)
    fetchPost({ url: '/project/viewProject', token })
      .then(res => {
        if (res === false) {
          setLoading(false)
          logout();
        } else {
          setLoading(false)
          setProject(res)
          setStaticInformation(res);
        }
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, []);


  return (
    <div className="HelpPage">
      <div className="HelpPage-container__project">
        <div className="HelpPage-header__title">
          <h6><strong>Dona aquí, dona hoy y ayuda a lograr sueños</strong></h6>
        </div>
        <header className="HelpPage-header">
          <img src={ImgHeader} alt="Imagen de fondo" loading="lazy"/>
          <div className="HelpPage-header__box">
            <img width={'100px'} height={'100px'} src={ico3} alt="Icono de proyectos"  loading="lazy"/>
            <SearchProject
              data={project}
              setProject={setProject}
              staticInformation={staticInformation} />
          </div>
        </header>

        <div className='ProjectDetail-page__button'>
          <div>
            <p>
              ¡25 proyectos completados al día de hoy!
            </p>
          </div>
          <Link to={`/proyectos/checkout/sin-elegir`}>
            DONA AQUÍ
          </Link>
        </div>

        <div className="HelpPage-hero">
          <article>
            <p>
              En este apartado encontrarás proyectos humanitarios, de ayuda comunitaria y de causas nobles que están 100% enfocados
              en el servicio y ayuda de unos a otros.
            </p>
            <p>
              En muchas ocasiones existen tremendas intenciones y magnificas oportunidades de hacer algo por ayudar, pero en muchos casos se ven frustradas por la falta de recursos. Y es ahí en donde tu aporte puede hacer la diferencia con nuestra gente y con nuestro mundo, que tanto lo necesita.
            </p>
            <p>
              Sabemos que tu corazón es enorme, pues estás aquí dando unos minutos a esta
               posibilidad de contribuir. Es por ello que te invitamos a participar sin pensar más, 
               apoyando al proyecto o proyectos que sientas que van más acordes con tus propósitos
                o simplemente aquellos a los que dicte tu corazón.
            </p>
            <p>
              Tu contribución será enormemente agradecida y bendecida.

            </p>
            <p>
              ¡<strong>DONA AQUÍ</strong>, dona hoy!

            </p>
          </article>
        </div>

        <div className="HelpPage-postContainer">
          <ProjectPost project={project} loading={loading} />
        </div>
      </div>
    </div>
  )
}

export default ProjectPage;