import React from 'react';

const AdminUserNotes = ({ name, idUser, countPost, setViewNotesForId }) => {
  const handleClick = (idUser) => {
    setViewNotesForId(idUser);
  }

  return (
    <div className="userPosts-view__userNotes">
      <span>{name}</span>
      <span
        className="userPosts-view__userNotesButton"
        onClick={() => handleClick(idUser)}>
        {`${countPost} Publicaciones`}
      </span>
    </div>
  )
}

export default AdminUserNotes;