import React , { useState } from 'react'
import CreatePost from '../components/CreatePost'
import HelpPost from '../components/HelpPost'
import '../styles/HelpPage.css'
import ImgHeader from '../../assets/images/design/fondoAyuda.jpeg'

const HelpPage = () => {
  const [ postNew, setPostNew ] = useState({})

  return ( 
    <div className="HelpPage">
      <div className="HelpPage-container">
        <header className="HelpPage-header">
          <img src={ImgHeader} alt="Imagen de fondo" />
          <div className="HelpPage-header__box">
            <h2>
              LA AYUDA NOS FORTALECE A TODOS
            </h2>
            <p>
              Si necesitas apoyo, haz una publicación , si deseas apoyar, hazlo. Muchas gracias.
            </p>
          </div>
        </header>

        <div className="HelpPage-hero">
          <article>
            <p>
              Me2You pone a tu disposición este espacio, donde puedes hacer tus preguntas y pedir la
              ayuda que necesitas. Nuestro objetivo es ofrecer un lugar donde cualquier persona tenga la
              posibilidad de obtener ayuda y asistencia de personas de buen corazón que quieran
              compartir con los demás su conocimiento, sabiduría y disposición para extender una mano.
            </p>
            <p>
              Así que si buscas algún consejo o te gusta ayudar a los demás, este es tu espacio. Usa este
              blog y siéntete con la libertad de ayudar y ser ayudado. Que sea de gran utilidad para ti.
            </p>
            <p>
              ¡Gracias por estar aquí!
            </p>
          </article>
        </div>

        {/* <h5>Crea una publicación</h5> */}
        <div className="HelpPage-createPost">
            <h5>
              Escribe una publicación
            </h5>
          <CreatePost setPostNew={setPostNew}/>
        </div>


        <div className="HelpPage-postContainer">
          <HelpPost postNew={postNew}/>
        </div>
      </div>
    </div>
  )
}

export default HelpPage;