import React from "react";

const TextJump = (textProject) => {
  let text = textProject ? textProject : '';
  if (!text.includes(`\n`)) {
    return (
      <div className="TextJump">
        <article>
          <p>
            {text}
          </p>
        </article> 
      </div>
    )
  } else {
    let textWithJump = text.split('\n');
    return (
      <div className="TextJump">
        <article>
          {
            textWithJump.map((data, key) => {
              return (
                <p key={key}>{data}</p>
              )
            })
          }
        </article>
      </div>
    )
  }
}

const TextPost = ({ text, post }) => {

  return (
    <div className={`TextPost ${post ? 'postText' : ''}`}>
      {TextJump(text)}
    </div>
  )

}

export default TextPost;