import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import { fetchPost, authenticate, isAuthenticated } from '../../helpers/helpCore';

import Logo from '../../assets/images/design/logo.png';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';
import MessageResponse from '../components/MessageResponse';

const initialForm = {
  email: '',
  password: '',
  error: '',
  loading: false,
  redirectToReferrer: false
}

const Login = () => {
  // const { login } = useContext(AuthProvider)
  const [inputs, setInputs] = useState(initialForm);
  const [result, setResult] = useState('')
  const { email, password, redirectToReferrer } = inputs;
  //const { user } = isAuthenticated();
  const { login } = useAuth()
  const url = '/signin'

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(props)
    fetchPost({ email, password, url })
      .then(data => {
        // console.log(data)
        try {
          if (data.msg) {
            setInputs({ ...inputs, error: data.msg, loading: false })
            setResult(data.msg)
          } else {
            login(data.token)
            authenticate(
              data.token,
              data.usuario.email,
              data.usuario.uid,
              data.usuario.role,
              () => {
                setInputs(initialForm)
              }
            )

          }
        } catch (err) {
          console.log(err)
        }

      })

    //   

  }


  const redirectUser = () => {
    if (redirectToReferrer) {
      console.log('hola')
      if (isAuthenticated()) {
        return <Redirect to="/web/profile" />
      }
    }
  }


  return (
    <div className="container auth">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 mt-3">
          <form onSubmit={onSubmit}>
            <div className="card-">
              <div className="card-body-">
                <div className="text-center mb-3">
                  <img src={Logo} className="img-fluid" alt="" />
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="input-group mb-3 input-group-lg ">
                      <div className="input-group-prepend ">
                        <span className="input-group-text" id="basic-addon1">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                      <input
                        className="form-control form-control-border"
                        name="email"
                        type="email"
                        onChange={onChange}
                        placeholder="USUARIO"
                        value={inputs.email}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="input-group mb-3 input-group-lg">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                      <input
                        className="form-control form-control-border"
                        name="password"
                        type="password"
                        onChange={onChange}
                        placeholder="CONTRASEÑA"
                        value={inputs.password}
                        required
                      />
                    </div>
                    <MessageResponse
                      result={result}
                      setResult={setResult}/>
                  </div>
                </div>
                <div className="row mb-3 text-center">
                  <div className="col">

                    <input className="btn btn-primary" type="submit" value="Ingresar" />

                    <Link to="/auth/signup" className="btn btn-secondary ml-1">
                      Registrarse
                    </Link>
                    <Link to="/web/recovery-pass"
                      className="btn btn-link">
                      Recuperar Contraseña
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {redirectUser()}
    </div>
  );
};

export default Login;