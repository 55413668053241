import React,{useState, useEffect} from 'react';

import {fetchPost} from '../../helpers/helpCore'

const ReactionPost = ({
    idPost, 
    reactionValue,
    reactionNameInPost
  }) => {

  const [click, setClick] = useState(false)
  const [reactionData , setReactionData] = useState(reactionValue)
  const [reactionName, setReactionName] = useState(reactionNameInPost)
  const id = localStorage.getItem('uid');
  const url = '/post/reactionPost'

  useEffect(() => {
    setReactionData(reactionValue)
    setReactionName(reactionNameInPost)
  },[reactionValue, reactionNameInPost])


  const handleClickReaction = () => {
    setClick(!click)
  }
 
  const handleReaction = (r) => {
    fetchPost({reaction:r, idPost:idPost, idUser:id, url})
      .then(res => {
        // console.log(res);
        setReactionData(reactionData + res?.value)
        setReactionName(res?.name)
        setClick(!click)
      })
  }

  return (
    <div className="buttonBox-Comment">
      <div className={`buttonBox-Reaction__opcion ${click === true ? 'active' : ''}`}>
        <input 
          type="button" 
          value="Me gusta"  
          className={`${reactionName === "Me gusta" ?'reaction-option' : ''}`}
          onClick={ () => handleReaction("Me gusta")}/>
        <input 
          type="button" 
          value="Que salga bien"
          className={`${reactionName === "Que salga bien" ? 'reaction-option' : ''}`}
          onClick={ () => handleReaction("Que salga bien")}/>
        <input 
          type="button" 
          value="Reportar"  
          className={`${reactionName === "Reportar" ? 'reaction-option' : ''}`}
          onClick={ () => handleReaction("Reportar")}/>
      </div>
      <input 
        type="button" 
        value={`Reaccionar ${reactionData === 0 ? '' : reactionData}`} 
        onClick={ () => handleClickReaction() }
        className={`${reactionName === '' ? '' : 'reaction-option'}`}/>
    </div>
  )
}

export default ReactionPost;