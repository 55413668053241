import React, { useState, useEffect } from 'react'
import {
  fetchPost
} from '../../helpers/helpCore.js'
import recortName from '../../helpers/recortName.js';
import useAuth from '../../hooks/useAuth';
import IconLoading from './IconLoading.jsx';
import MessageResponse from './MessageResponse.jsx';
import { convertBase64 } from '../../helpers/convertBase64';

import '../styles/Post.css'

const initialText = {
  text: '',
}

const CreatePost = ({
  setPostNew,
  imageEdit,
  textPost,
  urlEdit,
  idPost }) => {


  const [image, setImage] = useState(false)
  const [imageBase64, setImageBase64] = useState('')
  const [viewImage, setviewImage] = useState('')
  const [inputs, setInputs] = useState(textPost ? textPost : initialText)
  const [viewImageEdit, setViewImageEdit] = useState(imageEdit ? imageEdit : null)
  const token = localStorage.getItem('jwt')
  const [result, setResult] = useState('')
  const [loading, setLoading] = useState(false)
  const { logout } = useAuth()
  const url = '/post/createPost'


  useEffect(() => {
    if (image !== false) {
      try {
        setviewImage(URL.createObjectURL(image))
      } catch (err) {
        setviewImage('')
        setImage(false)
      }
    }
  }, [image])

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  }

  const handleImageChange = (e) => {
    setImage(e.target.files[0])
    convertBase64(e.target.files[0], setImageBase64)
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    if (image !== false) {
      console.log(image)
      const ext = image?.name?.substr(-4);
      console.log(ext)

      if (ext === '.png' || ext === 'jpeg' || ext === '.jpg') {
        console.log(' ');
      } else {
        return setResult('La imagen no es un de un formato permitido');
      }
    }

    if (inputs?.text.length !== 0) {
      setLoading(true)
      fetchPost({
        token,
        dataPost: inputs?.text,
        url: urlEdit ? urlEdit : url,
        img: imageBase64 !== ''
          ? imageBase64
          : viewImageEdit 
            ? ''
            : null,
        idPost
      })
        .then(res => {
          if (res === false) {
            setLoading(false)
            logout();
          } else {
            setInputs(initialText);
            setLoading(false)
            setResult(res.msg);
            setImage({})
            setPostNew(res?.msg);
            setViewImageEdit(null)

          }
        })
    } else {
      let res = 'Publicación vacía'
      setResult(res);
    }
  }

  const handleDeleteImage = () => {
    setviewImage("")
    setImage(false)
    setImageBase64("")
    setViewImageEdit(null)
  }

  return (
    <>
      <div className="HelpPage-createPost__box">
        <div className="createPost-img">
          <div className="createPost-img__container">
            {
              viewImageEdit !== null && image?.name === undefined
                ? (
                  <div className="imageSave-container">
                    <img src={viewImageEdit} alt="Foto de perfil" />
                    <p>Imagen publicada, puedes cambiarla </p>
                  </div>
                )
                : (
                  image?.name === undefined
                    ? (
                      <>
                        <p>Inserte la imagen aquí</p>
                        <p>(Recomendable)</p>
                      </>
                    )
                    : (
                      <div className="imageSave-container">
                        <img src={viewImage} alt="Foto de perfil" />
                        <p>{recortName(image?.name, 15)}</p>
                      </div>
                    )
                )
            }
            <div>
              {

                viewImageEdit !== null || image?.name !== undefined
                  ? (
                    <div
                      onClick={() => handleDeleteImage()}
                      className='createPost-img__containerButton'>
                      X
                    </div>
                  )
                  : (false)

              }
            </div>
          </div>
          <input
            type="file"
            name="img"
            accept="image/x-png,image/gif,image/jpeg"
            size="5120"
            onChange={(e) => handleImageChange(e)}
            className=""
          />
        </div>
        <div className="createPost-container">
          <div className="createPost-container__box">
            <form onSubmit={handleSubmit}>
              <textarea
                type="text"
                name="text"
                minLength={'98'}
                maxLength="500"
                placeholder="Ingrese la publicación..."
                value={inputs.text}
                onChange={handleChange}
              />
              <input
                type="submit"
                value="Enviar"
                className="btn btn-primary2" />
            </form>
          </div>
        </div>
      </div>
      <p>
        {loading && <IconLoading />}
        <MessageResponse
          result={result}
          setResult={setResult} />
      </p>
    </>
  )
}

export default CreatePost;