import React  from 'react'
import { Link } from 'react-router-dom'

import Banner from '../../assets/images/design/img-final-proceso-realizado.jpg';

import '../styles/ComprarMembresia.css'
const WebActualizarPlan = () => {
  const uid = localStorage.getItem('uid')

  return (
    <div className={'main'}>
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute-banner banner-text">
          <h2 className="text-center">Ya estas dentro</h2>
          <h6 className=' text-center'><b>de ME2YOU PREMIERE</b></h6>
          <div className="text-center">
            <p>
              Gracias por registrarte. Has dado un gran <br />
              paso para conocer a tu compañero ideal
            </p>
            <Link className="btn btn-primary2" to={`/web/miperfil/${uid}`} >
            Continuar
            </Link>
            {/* <a></a> */}
            <br />
          </div>
        </div>
      </section>

      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-0 banner-img">
            <img src={Banner} alt="programandoweb" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <div>
              {/* <img src={Mercadopago} alt="Mercadopago" className="img-fluid"/> */}
            </div>
            <div>
              <p></p>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebActualizarPlan;