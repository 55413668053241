import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './main.css'
import './index.css'


import Layout from './../views/containers/Layout';
import Landing  from './../views/containers/Landing';
import Login from './../views/containers/Login';
import Signup from './../views/containers/SignUp';
import NotFound from './../views/containers/NotFound';
import Prolife from './../views/containers/Prolife';
import YaEstasAdentro from '../views/containers/YaEstasAdentro';
import WebMiPerfil2 from '../views/containers/WebMiPerfil2';
import WebMiPerfil3 from '../views/containers/WebMiPerfil3';


import PrivateRouter from './../views/PrivateRouter'
import PublicRouter from './../views/PublicRouter';
import WebMiPerfil from '../views/containers/WebMiPerfil';
import WebMiPerfil4 from '../views/containers/WebMiPerfil4';
import WebMembresia from '../views/containers/WebMembresia';
import WebCompra from '../views/containers/WebCompra';
import HelpMe from '../views/containers/HelpMe';
import Support from '../views/containers/Support';
import Report from '../views/containers/Report';
import Contact from '../views/containers/Contact';
import WebBuscar from '../views/containers/WebBuscar';
import ProfileUser from '../views/containers/ProfileUser';
import Admi from '../views/containers/Admi';
import AdmiReport from '../views/containers/AdmiReport'
import AdminContact from '../views/containers/AdminContact';
import AdminProfile from '../views/containers/AdminProfile';
import Mensajes from '../views/containers/Mensajes';
import FormPremier from '../views/containers/FormPremier';
import Form from '../views/PaymentForm/Form';
import CheckoutDonate from '../views/PaymentForm/CheckoutDonate';
import RecoveryPass from '../views/containers/RecoveryPass'   
import ProfilePublic from '../views/containers/ProfilePublic';
import HelpPage from '../views/containers/HelpPage';
import HelpPagePost from '../views/containers/HelpPagePost';
import AdminRol from '../views/containers/AdminRol';
import AdminNoteData from '../views/containers/AdminNoteData';
import AdminViewActivity from '../views/containers/AdminViewActivity';
import ProjectPage from '../views/containers/ProjectPage';
import AdminPostProject from '../views/containers/AdminPostProject';
import AdminEditSlider from '../views/containers/AdminEditSlider';
import ProjectDetails from '../views/containers/ProjectDetails';
import AdminHiddenProject from '../views/containers/AdminHiddenProject';
import AdminCreateStory from '../views/components/AdminCreateStory';
import WebBuscarFree from '../views/containers/WebBuscarFree';
import PrivacyPolicies from '../views/containers/PrivacyPolicies';
import TermsConditions from '../views/containers/TremsConditions';
import AdminCreateNewAdmin from '../views/containers/AdminCreateNewAdmin';
import WebActualizarPlan from '../views/containers/WebActualizarPlan';
import ViewPostUser from '../views/containers/ViewPostUser';
import EditPostUser from '../views/containers/EditPostUser';


const App = () => { 
  return (
    <BrowserRouter >
      <Layout >
        <Switch>
          <Route exact path="/" component={Landing} />
          <PrivateRouter exact path="/comprar-plan" component={Form} />
          <PublicRouter exact path="/auth/signin" component={Login}/>
          <PublicRouter exact path="/auth/signup" component={Signup}/>
          <PrivateRouter exact path="/web/yaestasadentro" component={YaEstasAdentro}/>
          <PrivateRouter exact path="/web/perfil/verMas/:id" component={WebMiPerfil}/>
          <PrivateRouter exact path="/web/perfil/:id" component={ProfilePublic}/>
          <PrivateRouter exact path="/web/miperfil/:id" component={ProfileUser}/>
          <PrivateRouter exact path="/web/buscar" component={WebBuscar}/>
          <PublicRouter exact path="/web/buscar/free" component={WebBuscarFree}/>

          <PrivateRouter exact path="/web/mensajes" component={Mensajes}/>
          <PrivateRouter exact path="/web/premier/formulario" component={FormPremier}/>

          
          {/* ACTUALIZAR PERFIL */}
          <PrivateRouter exact path="/web/profile" component={Prolife}/>
          <PrivateRouter exact path="/web/profile2" component={WebMiPerfil2}/>
          <PrivateRouter exact path="/web/profile3" component={WebMiPerfil3} />
          <PrivateRouter exact path="/web/profile4" component={WebMiPerfil4}/>
          <PrivateRouter exact path="/web/profile5" component={YaEstasAdentro}/>
          <PrivateRouter exact path="/web/gracias-por-tu-compra" component={WebActualizarPlan}/>


          {/*  MENBRESIA PREMIUN */}
          <PrivateRouter exact path="/web/graciasporsucompra" component={WebCompra}/>
          <PrivateRouter exact path="/web/membresia" component={WebMembresia}/>
          
          {/* ADMIN VIEW */}
          <PrivateRouter exact path="/web/admin" component={Admi}/>
          <PrivateRouter exact path="/web/admin/report" component={AdmiReport}/>
          <PrivateRouter exact path="/web/admin/contact" component={AdminContact}/>
          <PrivateRouter exact path="/web/admin/profiles" component={AdminProfile}/>
          <PrivateRouter exact path="/web/admin/hidden-project" component={ AdminHiddenProject  }/>
          <PrivateRouter exact path="/web/admin/view-post" component={Admi}/>
          <PrivateRouter exact path="/web/admin/change-rol" component={AdminRol}/>
          <PrivateRouter exact path="/web/admin/Note-data" component={AdminNoteData}/> 
          <PrivateRouter exact path="/web/admin/viewActivity" component={AdminViewActivity}/> 
          <PrivateRouter exact path="/web/admin/create-project" component={AdminPostProject}/> 
          <PrivateRouter exact path="/web/admin/create-help-history" component={AdminCreateStory}/> 
          <PrivateRouter exact path="/web/admin/createAdmin" component={AdminCreateNewAdmin}/> 
          <PrivateRouter exact path="/web/admin/editSlider" component={AdminEditSlider}/> 

 
          {/* AYUDA */}
          <PrivateRouter exact path="/ayuda" component={HelpPage}/>
          <Route exact path="/politicas-privacidad" component={PrivacyPolicies}/>
          <Route exact path="/terminos-condiciones" component={TermsConditions}/>

          <PrivateRouter exact path="/ayuda/:id" component={HelpPagePost}/>
          <PrivateRouter exact path="/mis-publicaciones" component={ViewPostUser}/>
          <PrivateRouter exact path="/mis-publicaciones/editar/:id" component={EditPostUser}/>
          <PrivateRouter exact path="/proyectos" component={ProjectPage}/>
          <PrivateRouter exact path="/proyectos/checkout/:id" component={CheckoutDonate}/>
          <PrivateRouter exact path="/proyectos/ver-proyecto/:id" component={ProjectDetails}/>


          <Route exact path="/web/contact" component={Contact}/>
          <Route exact path="/web/report" component={Report}/>
          <Route exact path="/web/support" component={Support}/>
          <Route exact path="/web/helpme" component={HelpMe}/>
          <PublicRouter exact path="/web/recovery-pass" component={RecoveryPass}/>
          <Route component={NotFound}/>
        
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;