import React from 'react'
import { useLocation, Link } from 'react-router-dom';

// AUN EN ARREGLO
import '../styles/Premier.css'  

import Banner from '../../assets/images/design/banner-superior-perfil-usuario.jpg';
import AvatarDefault from '../../assets/images/design/avatar.png';
import star from '../../assets/images/design/star-paquetes-premiere.png';
import { fetchPost } from '../../helpers/helpCore'

import ciudad from '../../assets/images/design/ciudad.jpg';
import montanas from '../../assets/images/design/montanas.jpg';
import playa from '../../assets/images/design/playa.jpg';
import nieve from '../../assets/images/design/nieve.jpg';
import pueblo from '../../assets/images/design/pueblo.jpg';
import piramide from '../../assets/images/design/piramide.jpg';

import { useState, useEffect } from 'react';
import PremierOptions from '../components/PremierOptions';



const WebMiPerfil = ({ name }) => {
  const [data, setData] = useState({})
  const [membresia, setMembresia] = useState(false);
  const [dataPublic, setDataPublic] = useState(null)
  const token = localStorage.getItem('jwt')
  const notData = 'Sin definir'

  const location = useLocation()
  const info = location?.state;
  const { uid } = info?.data
  const userFind = () => {
    setData({})

    const { name, lastname, img } = info.data;

    setData({ name, lastname, img })
  }

  const DataPublic = (usuario) => {
    const url = '/buscar/perfilPublic'
    fetchPost({ usuario, url })
      .then(response => {
        // console.log(response)
        setDataPublic(response)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    setMembresia({})
    userFind() //return fullname and photo
    DataPublic(uid)//return data of details
    const url = '/buscar/perfil'
    // ProfileUser
    fetchPost({ token, usuario: uid, url })
    // eslint-disable-next-line
      .then(response => {
        setMembresia(response)
      })
      .catch(err => {
        console.log(err)
      })
      // eslint-disable-next-line
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = '/premier/wink'
    // wink
    fetchPost({token, otherUser:uid, url})
      .then( response => {
        // console.log(response)
        if(response?.msg){  
          alert(response?.msg)
        }
      })
  }

  return (
    <div className={'main'}>
      <section className="Banners position-relative">
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">{/* Bienvenido */}</h3>
          <div className="text-center">
          </div>
        </div>
      </section>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-0 foto-portada_vermas">
            <img src={dataPublic?.cover ? dataPublic?.cover : Banner} alt="programandoweb" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white pb-5">
        <div className="container bg-white">
          <div className="row">
            <div className="col-12 col-sm-6 p-0 border-primary-left">
              {/* FOTO Y NOMBRE */}
              <div className="container-dataHeader">
                {data ? (
                  <div className="container-dataHeader__photo">
                    <div className="dataHeader__photo">
                      <img
                        src={data.img ? data.img : AvatarDefault}
                        alt="programandoweb"
                        className="float-left border-radius"
                      />
                    </div>
                  </div>
                )
                  : (false)}
                <div className="container-dataHeader__fullname">
                  <b>
                    {' '}
                    {data.name != null
                      ? data.name
                      : 'Nombres sin definir'}{' '}
                    {data.lastname != null
                      ? data.lastname
                      : 'Apellidos sin definir'}
                  </b>
                </div>

                <div className="container-dataHeader__wink">
                  <form onSubmit={handleSubmit}> 
                    <input 
                      className="btn btn-primary2" 
                      type="submit" 
                      value="Guiño" />
                  </form>
                  
                </div>
              </div>

              {/* =============== */}
              <hr></hr>
              <div className="pl-5 pt-2">
                <div className="card-info__detalles">
                  <h2>ACERCA DE MI PAREJA IDEAL</h2>
                  <div className="info-detalles__box">
                    <h4>Mi pareja ideal:</h4>
                    {
                      dataPublic?.intereses?.map((data, key) => {
                        return (
                          <p key={`intereses_${data}`}>• {data}</p>
                        )
                      })
                    }
                  </div>

                  <div className="info-detalles__box">

                    <h4>Principales razones para querer una relación:</h4>
                    {
                      dataPublic?.quererRelacion?.map((data, key) => {
                        return (
                          <p key={`intereses_${key}_${data}`}>• {data ? data : 'Sin definir'}</p>
                        )
                      })
                    }
                  </div>

                  <div className="info-detalles__box">
                    <h4>Lo más importante en una relación:</h4>
                    {
                      dataPublic?.importanteRelacion?.map((data, key) => {
                        return (
                          <p key={`intereses_${key}_${data}`}>• {data}</p>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="card-info__detalles">
                  <h2>DÓNDE PREFIERO VIAJAR</h2>
                    <div className="card-info__viajes">
                      {
                        // eslint-disable-next-line
                        dataPublic?.vacaciones?.map((data, key) => {
                          // eslint-disable-next-line
                          if(data === 'ciudad'){return(<img key={`${data}_${key}`} src={ciudad}/>)}
                          // eslint-disable-next-line
                          if(data === 'montanas'){return(<img key={`${data}_${key}`} src={montanas}/>)}
                          // eslint-disable-next-line
                          if(data === 'playa'){return(<img key={`${data}_${key}`} src={playa}/>)}
                          // eslint-disable-next-line
                          if(data === 'nieve'){return(<img key={`${data}_${key}`} src={nieve}/>)}
                          // eslint-disable-next-line
                          if(data === 'pueblo'){return(<img key={`${data}_${key}`} src={pueblo}/>)}
                          // eslint-disable-next-line
                          if(data === 'piramides'){return(<img key={`${data}_${key}`} src={piramide}/>)}
                          // eslint-disable-next-line
                        })
                        // eslint-disable-next-line
                      }
                      
                    </div>
                </div>
              </div>

            </div>
            <div className="col-12 col-sm-6 p-0 pt-2">
              {
                membresia
                  ? (
                    <div className="card info-premier">
                      <div className="info-premier__header">
                        <img src={star} alt="Estrella de premier" />
                        <h2>AREA PREMIERE</h2>
                        <img src={star} alt="Estrella de premier" />
                      </div>
                      <div className="info-premier__contentpremier">
                        <div className="info-premier__boxpremier">
                          <h2><strong>MI PAREJA IDEAL</strong></h2>
                          {
                            membresia.length === 0
                              ? (
                                <div className="info-premier_noData">
                                  <strong>
                                    <p>
                                      No hay ningún dato en el perfil de este usuario, puede que aún no
                                      haya llenado este formulario o no sea Premiere.
                                      <br />
                                      <i>¡Es tu oportunidad, escríbele!</i>
                                    </p>
                                  </strong>
                                </div>
                              )
                              : (false)

                          }
                          <p>
                            • Rango de edad: {' '}
                            {membresia?.edad1 ? membresia?.edad1 : notData} a {' '}
                            {membresia?.edad2 ? membresia?.edad2 : notData}  años
                          </p>
                          <p>• Complexión: {membresia?.complexion ? membresia?.complexion : notData}</p>
                          <p>• Religión: {membresia?.religion ? membresia?.religion : notData}</p>
                          <p>• Hijos: {''}
                            {membresia?.hijos
                              ? membresia?.hijos === 'Si'
                                ? 'Bienvenidos hijos'
                                : 'No'
                              : notData}
                          </p>
                          <p>
                            {membresia?.aspectoImportante
                              ? membresia?.aspectoImportante
                              : notData}
                          </p>

                          <div className="lineBlue"></div>
                          <h3>Qué más pienso y cómo soy?</h3>
                          <div className="lineBlue"></div>
                          <p>
                            {membresia?.piensasFuturo
                              ? membresia?.piensasFuturo
                              : notData}
                          </p>
                          <PremierOptions
                            propuestas={membresia?.propuestas}
                            proyectos={membresia?.proyectos}
                            sueños={membresia?.sueños}
                            notData={notData}
                          />
                        </div>
                      </div>
                    </div>

                  )
                  : (
                    <div className="card info-premier">
                      <div className="info-premier__header">
                        <img src={star} alt="Estrella de premier" />
                        <h2>ÁREA PREMIERE</h2>
                        <img src={star} alt="Estrella de premier" />
                      </div>
                      <div className="info-premier__content">
                        <div className="info-premier__box">
                          <p>Para ver esta información necesitas</p>
                          <h2>
                            <Link to={'/web/membresia'}>
                              CUENTA PREMIERE
                            </Link>

                          </h2>
                          <p>Entra ahora y actualiza tu cuenta para que no te pierdas</p>
                          <p>ninguna de las caracteristicas premiere que te otorga</p>
                          <p>tú cuenta ME2YOU PREMIERE</p>

                        </div>
                      </div>
                    </div>

                  )
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebMiPerfil;