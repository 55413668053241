export const convertBase64 = async (file, setImageBase64) => {
  if (file && file.type.match('image.*')) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base = reader.result
      setImageBase64( base );
    }
    reader.onerror = ( err ) => {
      alert('Se genero un error')
    }
  }
}
