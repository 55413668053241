import React , {useState}from 'react'

import '../styles/PremierOption.css'

const PremierOptions = ({propuestas, proyectos, sueños, notData}) => {
  const [view, setView] = useState('goals');

  const click = (e) => {
    const p = document.querySelector(`#goals`);
    let s = document.querySelector('#purposes');
    let pr = document.querySelector('#project');
    
    if (e.target.id === 'goals') {
      setView(e.target.id);
      s.classList.remove('selected');
      pr.classList.remove('selected');
      p.classList.add('selected');

    } else if (e.target.id === 'purposes') {
      setView(e.target.id);
      s.classList.add('selected');
      p.classList.remove('selected');
      pr.classList.remove('selected');


    }else if (e.target.id === 'project') {
      setView(e.target.id);
      p.classList.remove('selected');
      s.classList.remove('selected');
      pr.classList.add('selected');

    }
    
  };

  return(
    <div className="PremierOptions">
      <div className="PremierOption-container">
        <nav className="PremierOption-nav">
          <li 
            className="PremierOption-nav__option selected"
            onClick={click}
            id="goals">
              MIS SUEÑOS
          </li>
          <li 
            className="PremierOption-nav__option"
            onClick={click}
            id="purposes">
              MIS PROPÓSITOS
          </li>
          <li 
            className="PremierOption-nav__option"
            onClick={click}
            id="project">
              MIS PROYECTOS
          </li>
        </nav>
        <div className="PremierOption-box">
        {
          sueños 
          ? (
            view === 'goals'
          ?(
            sueños?.map((data, key) => {
              return (
                <div 
                  className="box-option" 
                  key={`premierOption_${key}`}>
                  <div className="box-option__data">
                    <strong><p >{data[0].toUpperCase()} {'    '}</p></strong>
                    
                    <p> <span>Area:{' '}</span><i>{data[1]}</i></p>
                  </div>
                  <p>{data[2]}</p>
                  
                </div>
              )
            })
          )
          :(false)
          )
          : (<p>{notData}</p>)
        }
        {
          view === 'purposes'
          ?(
            propuestas?.map((data, key) => {
              return (
                <div 
                  className="box-option" 
                  key={`premierOption_${key}`}>
                  <div className="box-option__data">
                    <strong><p >{data[0].toUpperCase()} {'    '}</p></strong>
                    
                    <p> <span>Area:{' '}</span><i>{data[1]}</i></p>
                  </div>
                  <p>{data[2]}</p>
                  
                </div>
              )
            })
          )
          :(false)
        }
         {
          view === 'project'
          ?(
            proyectos?.map((data, key) => {
              return (
                <div 
                  className="box-option" 
                  key={`premierOption_${key}`}>
                  <div className="box-option__data">
                    <strong><p >{data[0].toUpperCase()} {'    '}</p></strong>
                    
                    <p> <span>Area:{' '}</span><i>{data[1]}</i></p>
                  </div>
                  <p>{data[2]}</p>
                  
                </div>
              )
            })
          )
          :(false)
        }
        </div>
      </div>
    </div>
  )
}



export default PremierOptions;