import React from 'react'
// import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import AvatarDefault from '../../assets/images/design/avatar.png';
import mujer from '../../assets/images/design/mujer.jpeg';


const ItemProfile = (props) => { 
  const uid = localStorage.getItem('uid')
  return (
    <div className=" fotoBuscar">
      <Link
        to={{
          pathname: 
          `${props.free 
            ? '/web/buscar/free' 
            : uid === props.data.uid
            ? `/web/miperfil/${props.data.uid}`
            : `/web/perfil/${props.data.uid}`
          
          }`
        }}
      >
        <div className="p-2 "> 
          {/* {console.log(props.data)} */}

          {props.data.img !== '' &&
            props.data.img !== 'null' &&
            props.data.img !== 'NULL' &&
            props.data.img !== null ? (
            <>
              <div className="fotoBuscar-img">
                <img
                  src={
                    props.data.img
                      ? props.data.img
                      : props.data.soy === 'Hombre'
                        ? AvatarDefault
                        : mujer}
                  alt="Foto usuario"
                  className="img-fluid scale"
                  loading="lazy"
                />
                {props.data.premiere === 'Premiere'
                    ? (
                      <div>
                        <p>Premiere</p>
                      </div>
                    )
                    : (false)
                }
              </div>
            </>
          ) : (
            <div className="fotoBuscar-img">
              <img
                src={
                  props.data.img
                    ? AvatarDefault
                    : props.data.soy === 'Hombre' 
                      ? AvatarDefault
                      : mujer
                }
                alt="Foto usuario FOTO"
                className="img-fluid scale"
                loading="lazy"
              />
              {
                props.data.premiere === 'Premiere'
                  ? (
                    <div>
                      <p>Premiere</p>
                    </div>
                  )
                  : (false)
              }
            </div>
          )}

          <div
            className={
              props.data.soy === 'Hombre'
                ? 'bg-primary p-2 font-size-12 text-white fotoBuscar-text'
                : 'bg-primary2 p-2 font-size-12 text-white fotoBuscar-text'
            }
          >
            <b>{props.data.name} {' '} {props.data.lastname}</b>
            <p><strong>{props.data.yearAgo} {' '} años</strong></p>
          </div>

        </div>
      </Link>
    </div>
  );
};

export default ItemProfile;