import React from 'react';
import Banner from '../../assets/images/design/banner-inscripcion-premiere-precios.jpg';
import Planes from '../components/Planes';

import '../styles/ComprarMembresia.css'

const WebMembresia = () => {

  return (
    <div className={'main'}>
      <section className="Banners position-relative webMembresia-box">
        <div className="position-absolute position-absolute-banner text-white">
          <h3 className="text-center">Bienvenido</h3>
          <div className="text-center">
            <p>
              a <strong>Me2You</strong>, tu perfil se ha creado con éxito.
              <br />
              Estamos seguros que te divertirás y<br />
              encontrarás personas afines a ti.
              <br />
            </p>
            <div className="mt-2">
              <b>¡Con suerte hasta tu pareja ideal!</b>
            </div>
            {/* <a></a> */}
          </div>
        </div>
      </section>

      <div className="container-fluid bg-white webMembresia">
        <div className="row">
          <div className="col p-0">
            <img src={Banner} alt="programandoweb" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col p-4 bg-primary2 text-center h4">
            INSCRIPCIÓN PREMIERE
          </div>
        </div>
      </div>

      <div className="container">
        
        <div className="row">
          <div className="col text-center p-2">
            <div>
              <b>
                No hay nada mejor que conocer bien a quien va a ser tu pareja
              </b>
            </div>
            <div>
              Además del 50% DE DESCUENTO al inscribirte HOY, tienes la opción
              de ganar un descuento adicional con <b>Premiere 3 </b> y{' '}
              <b>Premiere 6 ¡Aprovecha ya!</b>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-5 text-center col'>
        <div>
          <h4>
          BENEFICIOS DE SER PREMIERE
          </h4>
          <div>
            <ul>
              <ol>+ Manda mensajes a otros usuarios de forma ilimitada.</ol>
              <ol>+ Complementar perfil con metas, propósitos y otros intereses.</ol>
              <ol>+ Comunícate con más usuarios sin límite.</ol>
            </ul>
          </div>
        </div>
      </div>

      {/* <Planes /> */}
      <Planes />

      
      
    </div>
  );
}

export default WebMembresia;